import React from 'react';
import { Translator, Translate } from 'react-auto-translate';
import './DossierModal.css';

import ModalsActions from '../../../actions/ModalsActions';
import settings from '../../../settings';

const splitParagraphs = msg => msg.match(/[^\r\n]+/g).map((l, i) => (<p key={i}>{l}</p>));
const stopPropagation = e => e.stopPropagation();
const closeModal = () => ModalsActions.hideModal();

const DossierModal = props => {
  const { title, message, language } = props;
  const articleSplit = splitParagraphs(message)

  return (
    <Translator
      to={language}
      from="Ro"
      googleApiKey={settings.GOOGLE_API_KEY.TRANSLATION}>

      <div className="modal-dialog dossier-modal" onClick={stopPropagation}>
        <div className="modal-content modals-modal">
          <div className="row">
            <button type="button" className="modals-close-button close" onClick={closeModal}>&times;</button>
          </div>
          <div className="row">
            <div className="col-xs-10 col-xs-offset-1">
              <h3 className="modals-title"><Translate>{title}</Translate></h3>
              {articleSplit.map((para) => { return <p><Translate>{para.props.children}</Translate></p> })}
            </div>
          </div>
          <div className="clearfix" />
          <br />
        </div>
      </div>
    </Translator>
  );
}

export default DossierModal;