import React from 'react';
import Reflux from 'reflux';
import { Helmet } from "react-helmet";
import Search from './Search/Search';
import Controls from './Controls/Controls';
import AccountState from './AccountState/AccountState';
import About from './About/About';
import New from './New/New';
import RecentlyViewed from './RecentlyViewed/RecentlyViewed';
// import Clients from './Clients/Clients';
import UserStore from '../../../stores/UserStore';
import { clearHash } from '../../../utils/Location';

import './Home.css';
// import OldSite from './Search/SearchBar/OldSite';

/**
 * The main homepage of the application.
 */
class Home extends Reflux.Component {
    constructor() {
        super();
        this.store = UserStore;
    }

    componentDidUpdate() {
        const { authenticated } = this.state;
        const { location } = window;
        if (authenticated && location.hash) {
            clearHash();
        }
    }

    componentDidMount() {
        var language = localStorage.getItem("Language")
        if (language === "Ro" || language === null) {
            import('../../Languages/language_ro').then(lang => {
                this.setState({ language: lang.default.Language })
            })
        }
        if (language === "En") {
            import('../../Languages/language_en').then(lang => {
                this.setState({ language: lang.default.Language })
            })
        }
    }

    render() {
        const { history } = this.props;
        const { authenticated, user, language } = this.state;

        return (
            <div className="home row">
                <Helmet>
                    <link rel="canonical" href="https://portal.datefirme.ro/" />
                </Helmet>
                {/* <OldSite /> */}
                <Search history={history} user={user} />
                {authenticated
                    ? [
                        <Controls key="controls" />,
                        <AccountState key="account-state" />,
                        <RecentlyViewed key="recently-viewed" />
                    ]
                    : [
                        <About key="about" text={language && language.Home.About} />,
                        <New key="new" text={language && language.Home.New} />
                    ]
                }
            </div>
        );
    }
}

export default Home;