import React from 'react'
import { Link } from 'react-router-dom';

const AdminButton = () => {

    return (
        <Link to='/admin'>
            <button className="btn btn-sm bg-cadet-grey pull-right">
                <span className="pull-left" />Administrare
            </button>
        </Link>
    )
}

export default AdminButton