/**
 * Disables a button. Returns a function that re-enables the button.
 * @param {HTMLButtonElement} button The button
 * @param {String} cursorType Cursor type while disabled ("progress" by default)
 * @return {Function} Enable callback, without any parameters
 */
export const disableButton = (button, cursorType = 'progress') => {
    const { cursor } = button.style;

    button.disabled = true;
    button.style.cursor = cursorType;

    return () => {
        button.disabled = false;
		button.style.cursor = cursor;
    };
}

/**
 * Adds a class ("disabled" by default) to an element. Returns a function that removes it.
 * @param {HTMLElement} element The element
 * @param {String} className The class to be added ("disabled" by default)
 * @return {Function} Remove class callback, without parameters
 */
export const addClass = (element, className = 'disabled') => {
    element.classList.add(className);
    return () => {
        element.classList.remove(className);
    };
}

export default { disableButton, addClass };
