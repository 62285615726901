import { createActions } from 'reflux';
import settings from '../settings';
import axios from 'axios';

const CartActions = createActions({
    add: {},
    remove: {},
    clear: {},
    clearPayment: {},
    clearMessage: {},
    performPayment: {},
    endTransaction: {},
    commit: { asyncResult: true },
    readAquisition: { asyncResult: true },
    clearAquisition: {},
    plataCustom: {},
});


CartActions.commit.listen((data, successCallback) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.COMMIT_CART
    // app.post(
    //     settings.INTERNAL_URLS.COMMIT_CART,
    //     data
    axios.post(
        url, data,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        const parsedData = JSON.parse(data)
        CartActions.commit.completed(parsedData, successCallback);
    }).catch((error) => {
        CartActions.commit.failed(error);
    });
});

CartActions.plataCustom.listen((datePlata) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.PLATA_CUSTOM
    datePlata.Suma = parseFloat(datePlata.Suma)
    datePlata.Telefon = parseInt(datePlata.Telefon,10)
    datePlata.NrFactura = parseInt(datePlata.NrFactura,10)
    axios.post(
        url, datePlata,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        const parsedData = JSON.parse(data)
        CartActions.commit.completed(parsedData, null);
    }).catch((error) => {
    });
})

CartActions.readAquisition.listen((id) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.READ_ACQUISITION + '/' + id
    // app.get(
    //     settings.INTERNAL_URLS.READ_ACQUISITION + '/' + id
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        CartActions.readAquisition.completed(data);
    }).catch((error) => {
        CartActions.readAquisition.failed(error);
    });
});

export default CartActions;
