import React, { useEffect, useState } from 'react';
import settings from '../../settings';

import './Footer.css';

/**
 * Application footer region.
 */
const Footer = () => {
    const [language, setLanguage] = useState(null)

    useEffect(() => {
        var language = localStorage.getItem("Language")
        if (language === "Ro" || language === null) {
            import('../Languages/language_ro').then(lang => {
                setLanguage(lang.default.Language)
            })
        }
        if (language === "En") {
            import('../Languages/language_en').then(lang => {
                setLanguage(lang.default.Language)
            })
        }
    }, [])

    return (
        <div className="footer" id="footer">
            <div className="clearfix" />
            <div className="col-lg-3 col-md-4 col-sm-10 col-xs-10 col-md-offset-1 col-sm-offset-1 col-xs-offset-1">
                <h6>DATE FIRME&nbsp;&nbsp;v{settings.VERSION}</h6>
                <p>Copyright | <a href='/gdpr'>GDPR</a> &#x24B8; Date Firme. {language && language.Footer.Rights}</p>
            </div>
            {/*
        <div className="col-lg-2 col-md-2 col-sm-3 col-xs-10 col-lg-offset-1 col-md-offset-0 col-sm-offset-1 col-xs-offset-1">
            <h6>PRIMUL MENIU</h6>
            <p>Harta Site</p>
            <p>Monitorizare</p>
            <p>Catalog Companii</p>
            <p>Buton de Meniu</p>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-3 col-xs-10 col-md-offset-0 col-xs-offset-1">
            <h6>AL DOILEA MENIU</h6>
            <p>Harta Site</p>
            <p>Buton de Meniu</p>
            <p>Informatii Suplimentare</p>
            <p>FAQ general</p>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-3 col-xs-10 col-md-offset-0 col-xs-offset-1">
            <h6>AL TREILEA MENIU</h6>
            <p>Harta Site</p>
            <p>Monitorizare</p>
            <p>Catalog Companii</p>
            <p>Buton de Meniu</p>>
        </div>
        --*/}
            <div className="clearfix" />
        </div>
    )
};

export default Footer;