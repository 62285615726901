import Reflux from 'reflux';

import settings from '../settings';

import UserActions from '../actions/UserActions';
import ModalActions from '../actions/ModalsActions';
import UtilActions from '../actions/UtilActions';

import Cookies from 'js-cookie';

class UserStore extends Reflux.Store {
    constructor(props) {
        super(props);
        this.listenables = [UserActions, UtilActions];
        const authenticated = localStorage.getItem(settings.LOCAL_STORAGE.USER_KEY) !== null;

        this.state = {
            authenticated: authenticated,
            user: {
                loading: true,
                data: null,
                passwordMessage: '',
            },
            status: {
                loading: true,
                data: null,
            },
            invoicingAddresses: {
                loading: true,
                data: null,
            },
            invoices: {
                loading: true,
                data: [],
            },
            reportsMarketing: {
                loading: true,
                data: [],
            },
            reportsCompanies: {
                loading: true,
                data: [],
            },
            reportsCompaniesOld: {
                loading: true,
                data: [],
            },
            contractCreation: {
                loading: true,
                data: false
            },
            fiscalCodeData: {
                companyName: null,
                recomType: null,
                recomCounty: null,
                recomNumber: null,
                recomYear: null,
            },
            accountCreation: {
                success: false,
                registerError: null,
            },

            AcquisitionsCount: {
                loading: true,
                achizitii: 0
            },

            userType: 1,
            hasDRCAccess: false,
            drcMessage: "",
            drcLoading: false,
            associateDiagram: null,
            userOptions: null,
        };
    }

    onUnauthenticate = () => {
        UtilActions.reset();
        const userKey = localStorage.getItem(settings.LOCAL_STORAGE.USER_KEY);

        if (userKey) {
            localStorage.removeItem(userKey);
        }
        localStorage.removeItem(settings.LOCAL_STORAGE.USER_KEY);
    }

    onReset() {
        this.setState({
            authenticated: false,
            user: {
                loading: true,
                data: null,
            },
            status: {
                loading: true,
                data: null,
            },
            invoicingAddresses: {
                loading: true,
                data: null,
            },
            invoices: {
                loading: true,
                data: [],
            },
            reportsMarketing: {
                loading: true,
                data: [],
            },
            reportsCompany: {
                loading: true,
                data: [],
            },
            contractCreation: {
                loading: true,
                data: false,
            },
            fiscalCodeData: {
                companyName: null,
                recomType: null,
                recomCounty: null,
                recomNumber: null,
                recomYear: null,
            },
            accountCreation: {
                success: false,
                registerError: null,
            }
        });
    }

    onLogin(username, password, callback) {
    }

    onLoginCompleted = (data, callback) => {
        localStorage.setItem(settings.LOCAL_STORAGE.USER_KEY, data);
        this.setState({
            authenticated: true,
        });
        UserActions.loadDetails();
        if (callback) {
            callback();
        }
    }

    onLoginFailed = (error) => {
        // switch (error.status) {
        // case 400:
        //     ModalActions.showModal('login', 'Email sau parola incorecte.');
        //     break;
        // case 403:
        //     ModalActions.showModal('login', 'Contul nu a fost confirmat; Va rugam sa verificati adresa de e-mail.');
        //     break;
        // default:
        //     ModalActions.showModal('login', 'A avut loc o eroare; Va rugam sa reincercati.');
        //     break;
        // }
        ModalActions.showModal('login', error.data);
    }

    onExternalLogin(callback) {
    }

    onExternalLoginCompleted = (data, callback) => {
        if (callback) {
            callback();
        }
    }

    onExternalLoginFailed = (error) => {
        setTimeout(function () {
            window.location.href = settings.EXTERNAL_REDIRECT;
        }, 3000);
    }

    onLogout() {
    }

    onLogoutCompleted = () => {
        UserActions.unauthenticate();
    }

    onLogoutFailed = (error) => {
        UserActions.unauthenticate();
    }

    onCreateDetails(name, email, password) { }

    onCreateDetailsCompleted(username, password) {
        //UserActions.login(username, password);
        Cookies.remove('referrerId')
        this.setState({
            accountCreation: {
                success: true,
                registerError: null,
            }
        });
        ModalActions.showModal('email');
    }

    onCreateDetailsFailed(error) {
        switch (error.status) {
            case 400:
                this.setState({
                    accountCreation: {
                        success: false,
                        registerError: 'Datele introduse sunt incorecte',
                    }
                });
                break;
            case 409:
                this.setState({
                    accountCreation: {
                        success: false,
                        registerError: error.data,
                    }
                });
                break;
            default:
                this.setState({
                    accountCreation: {
                        success: false,
                        registerError: 'A avut loc o eroare; Va rugam sa reincercati',
                    }
                });
                break;
        }
    }

    onLoadDetails() { }

    onLoadDetailsCompleted(data) {
        this.setState({
            user: {
                loading: false,
                data: data,
            },
        });
        // UtilActions.set(); 
    }

    onLoadDetailsFailed(error) {
        //console.log(error);
        UserActions.logout();
    }

    onUpdateDetails(data) { }

    onUpdateDetailsCompleted() {
        UserActions.loadDetails();
    }

    onUpdateDetailsFailed(error) {
        //console.log(error);
        const { user } = this.state;
        user.loading = false;
        this.setState({
            user: user,
        });
    }

    // GET INVOICING ADDRESSES

    onLoadInvoicingAddresses() { }

    onLoadInvoicingAddressesCompleted(data) {
        this.setState({
            invoicingAddresses: {
                loading: false,
                data: data,
            },
        });
    }

    onLoadInvoicingAddressesFailed(error) {
        //console.log(error);
        const { invoicingAddresses } = this.state;
        invoicingAddresses.loading = false;
        this.setState({
            invoicingAddresses: invoicingAddresses,
        });
    }

    // POST INVOICING ADDRESS

    onCreateInvoicingAddress(data) { }

    onCreateInvoicingAddressCompleted() {
        const { invoicingAddresses } = this.state;
        invoicingAddresses.loading = true;
        this.setState({
            invoicingAddresses: invoicingAddresses,
        });
        UserActions.loadDetails();
        UserActions.loadInvoicingAddresses();
    }

    onCreateInvoicingAddressFailed(error) {
        //console.log(error);
        const { invoicingAddresses } = this.state;
        invoicingAddresses.loading = false;
        this.setState({
            invoicingAddresses: invoicingAddresses,
        });
    }

    // PUT INVOICING ADDRESS

    onUpdateInvoicingAddress(addressId, data) { }

    onUpdateInvoicingAddressCompleted() {
        const { user } = this.state;
        const { invoicingAddresses } = this.state;
        user.loading = true;
        invoicingAddresses.loading = true;
        this.setState({
            user: user,
            invoicingAddresses: invoicingAddresses,
        });
        UserActions.loadDetails();
        UserActions.loadInvoicingAddresses();
    }

    onUpdateInvoicingAddressFailed(error) {
        //console.log(error);
        const { invoicingAddresses } = this.state;
        invoicingAddresses.loading = false;
        this.setState({
            invoicingAddresses: invoicingAddresses,
        });
    }

    // PUT SET ACTIVE INVOICING ADDRESS

    onSetActiveInvoicingAddress(addressId, data) { }

    onSetActiveInvoicingAddressCompleted() {
        const { user, invoicingAddresses } = this.state;
        user.loading = true;
        invoicingAddresses.loading = true;
        this.setState({
            user: user,
            invoicingAddresses: invoicingAddresses,
        });
        UserActions.loadDetails();
        UserActions.loadInvoicingAddresses();
    }

    onSetActiveInvoicingAddressFailed(error) {
        //console.log(error);
        const { invoicingAddresses } = this.state;
        invoicingAddresses.loading = false;
        this.setState({
            invoicingAddresses: invoicingAddresses,
        });
    }

    // DELETE INVOICING ADDRESS

    onDeleteInvoicingAddress(addressId) { }

    onDeleteInvoicingAddressCompleted() {
        const { invoicingAddresses } = this.state;
        invoicingAddresses.loading = true;
        this.setState({
            invoicingAddresses: invoicingAddresses,
        });
        UserActions.loadInvoicingAddresses();
    }

    onDeleteInvoicingAddressFailed(error) {
        //console.log(error);
        const { invoicingAddresses } = this.state;
        invoicingAddresses.loading = false;
        this.setState({
            invoicingAddresses: invoicingAddresses,
        });
    }

    // GET INVOICES

    onLoadInvoices(startDate, endDate) {
        const { invoices } = this.state;
        invoices.loading = true;
        this.setState({
            invoices: invoices,
        });
    }

    onLoadInvoicesCompleted(data) {
        this.setState({
            invoices: {
                loading: false,
                data: data,
            }
        });
    }

    onLoadInvoicesFailed(error) {
        //console.log(error);
        const { invoices } = this.state;
        invoices.loading = false;
        this.setState({
            invoices: invoices,
        });
    }

    // GET REPORTS

    onLoadReportsMarketing(startDate, endDate) {
        const { reportsMarketing } = this.state;
        reportsMarketing.loading = true;
        this.setState({
            reportsMarketing: reportsMarketing
        });
    }

    onLoadReportsMarketingCompleted(data) {
        this.setState({
            reportsMarketing: {
                loading: false,
                data: data
            }
        });
    }

    onLoadReportsMarketingFailed(error) {
        //console.log(error);
        const { reportsMarketing } = this.state;
        reportsMarketing.loading = false;

        this.setState({
            reportsMarketing: reportsMarketing
        });
    }

    onLoadReportsCompanies(startDate, endDate) {
        const { reportsCompanies } = this.state;
        reportsCompanies.loading = true;

        this.setState({
            reportsCompanies: reportsCompanies
        });
    }

    onLoadReportsCompaniesCompleted(data) {
        this.setState({
            reportsCompanies: {
                loading: false,
                data: data
            }
        });
    }

    onLoadReportsCompaniesOldCompleted(data) {
        this.setState({
            reportsCompaniesOld: {
                loading: false,
                data: data
            }
        });
    }

    onLoadReportsCompaniesFailed(error) {
        //console.log(error);
        const { reportsCompanies } = this.state;
        reportsCompanies.loading = false;

        this.setState({
            reportsCompanies: reportsCompanies
        });
    }

    // PUT CHANGE PASSWORD

    onPasswordMessage(message) {
        const { user } = this.state;
        user.passwordMessage = message;
        this.setState({
            user: user,
        });
    }

    onChangePassword(data) { }

    onChangePasswordCompleted() {
        const { user } = this.state;
        user.passwordMessage = '';
        this.setState({
            user: user,
        });
        UserActions.unauthenticate();
        ModalActions.showModal('login');
    }

    onChangePasswordFailed(error) {
        //console.log(error);
        const { user } = this.state;
        // switch(error.status) {
        // case 400:
        //     user.passwordMessage = 'Parola veche nu este corecta.';
        //     break;
        // default:
        //     user.passwordMessage = 'A aparut o problema la schimbarea parolei.';
        //     break;
        // }
        user.passwordMessage = error.data
        this.setState({
            user: user,
        });
    }

    onCreateContract() {
        this.setState({
            contractCreation: {
                loading: true,
                data: false
            }
        });
    }

    onCreateContractCompleted() {
        this.setState({
            contractCreation: {
                loading: false,
                data: true
            }
        });
    }

    onCreateContractFailed() {
        this.setState({
            contractCreation: {
                loading: false,
                data: false
            }
        });
    }

    // GET COMPANY DATA FOR RECOM (USED IN CONTRACT MODAL)

    onGetFiscalCodeData() {
    }

    onGetFiscalCodeDataCompleted(data) {
        this.setState({
            fiscalCodeData: {
                companyName: data.Denumire,
                recomType: data.CodRecomTip,
                recomCounty: data.CodRecomJudet,
                recomNumber: data.CodRecomNumar,
                recomYear: data.CodRecomAn,
                address: data.Adresa,
            }
        });
    }

    onGetFiscalCodeDataFailed() {
    }

    onGetAcquisitionsCount() {
    }

    onGetAcquisitionsCountCompleted(data) {
        this.setState({
            AcquisitionsCount: {
                loading: false,
                achizitii: data
            }
        });
    }

    onGetAcquisitionsCountFailed() {
    }

    onGetUserType() {

    }

    onGetUserTypeCompleted(data) {
        this.setState({
            userType: data.userType
        })
    }

    onHasDRCAccessCompleted(data) {
        this.setState({ hasDRCAccess: data.hasAccess })
    }

    onAddDRCCommand() {
        this.setState({ drcLoading: true })
    }

    onAddDRCCommandCompleted(data) {
        this.setState({ drcMessage: data.message, drcLoading: false })
    }

    onClearDRCMessage() {
        this.setState({ drcMessage: "" })
    }

    onSaveAssociateDiagram(associateDiagram) {
        this.setState({ associateDiagram: associateDiagram })
    }

    onGetUserOptionsCompleted(data) {
        this.setState({ userOptions: data.userOptiuni })
    }

    onChangeUserCompleted(data) {
        localStorage.setItem(settings.LOCAL_STORAGE.USER_KEY, data);
        UserActions.loadDetails()
        window.location.reload(false)
    }

    onChangeUserFailed(error) {
        ModalActions.showModal('changeUser', error)
    }

    onLogNewUserCompleted(data) {
        localStorage.setItem(settings.LOCAL_STORAGE.USER_KEY, data);
        UserActions.loadDetails()
        // window.location.reload(false)
        ModalActions.showModal("firstLogin")
    }

    onUpdateReferrerCompleted() {
        const sanitizedUrl = encodeURIComponent(window.location.origin)
        window.location.href = sanitizedUrl
    }

    onSessionLoginCompleted(data) {
        localStorage.setItem(settings.LOCAL_STORAGE.USER_KEY, data);
        UserActions.loadDetails()
        const sanitizedUrl = encodeURIComponent(window.location.origin)
        window.location.href = sanitizedUrl
    }
}

export default UserStore;
