import Reflux from 'reflux';
import settings from '../settings';

import CartActions from '../actions/CartActions';
import UtilActions from '../actions/UtilActions'; // This does not seem to be used

class CartStore extends Reflux.Store {
    constructor() {
        super();

        this.listenables = [CartActions, UtilActions];
        let contents = [];
        let sum = 0;
        const userKey = localStorage.getItem(settings.LOCAL_STORAGE.USER_KEY);

        if (userKey) {
            const data = localStorage.getItem(userKey);
            if (data) {
                const parsedData = JSON.parse(data);
                contents = parsedData.contents;
                sum = parseFloat(parsedData.sum, 10);
            }
        }

        // for legacy
        this.paymentType = Object.freeze({
            1: "pachet",
            2: "firma",
            3: "marketing"
        });

        this.state = {
            contents: contents,
            sum: sum,
            message: '',
            status: null,
            paymentLoading: false,
            paymentMethod: 2, // card
            mobilpay: null,
            acquisition: null, // thankyou page
        };
    }

    onReset() {
        CartActions.clear();
    }

    onClearMessage() {
        this.setState({
            message: '',
        });
    }

    onAdd(item) {
        const { price, type } = item;
        let { sum } = this.state;
        let contents = this.state.contents.slice(0);

        // Filter out all elements of type "pachet" & subtract their price from the final sum
        if (type === 1) {
            contents = contents.filter(el => {
                if (el.type !== 1)
                    return true;

                sum -= el.price;
                return false;
            });
        }

        // Check if a copy already exists within the contents
        const index = contents.findIndex(el => {
            return el.type === item.type
                && el.id === item.id
                && el.companyId === item.companyId
                && (!item.id && item.selectii ? (el.selectii[0] === item.selectii[0]) : true);
        });

        // Add it if it does not exist already
        if (index === -1) {
            contents.push(item);
            const newSum = sum + price;
            const userKey = localStorage.getItem(settings.LOCAL_STORAGE.USER_KEY);
            if (userKey) {
                localStorage.setItem(userKey, JSON.stringify({
                    contents: contents,
                    sum: newSum,
                }));
            }
            this.setState({
                contents: contents,
                sum: newSum,
            });
        }
    }

    onRemove(item) {
        const { sum } = this.state;
        let newSum = sum;
        let contents = this.state.contents.slice(0);
        // Filter out all the matching items
        contents = contents.filter((element) => {
            if (element.type === item.type
                && element.id === item.id
                && element.companyId === item.companyId
                && (!item.id && item.selectii ? (element.selectii[0] === item.selectii[0]) : true)) {
                newSum -= element.price;
                return false;
            }
            return true;
        });

        const userKey = localStorage.getItem(settings.LOCAL_STORAGE.USER_KEY);
        if (userKey) {
            localStorage.setItem(userKey, JSON.stringify({
                contents: contents,
                sum: newSum,
            }));
        }

        this.setState({
            contents: contents,
            sum: newSum,
        });
    }

    onClear() {
        this.setState({
            contents: [],
            sum: 0,
        });
        const userKey = localStorage.getItem(settings.LOCAL_STORAGE.USER_KEY);
        if (userKey) {
            localStorage.setItem(userKey, JSON.stringify({
                contents: [],
                sum: 0,
            }));
        }
    }

    onPerformPayment(paymentMethod, successCallback) {
        const { contents } = this.state;
        if (!contents.length) {
            return;
        }

        const data = {
            MetodaDePlataId: paymentMethod,
            Pachete: [],
            RapoarteFirma: [],
            RapoarteMarketing: []
        };

        for (let i = 0; i < contents.length; i++) {
            const content = contents[i];
            switch (content.type) {
                case 1: // PACHET
                    // for (let name in content.items) {
                    //     if (content.items.hasOwnProperty(name)) {
                    //         data.Pachete[name] = content.items[name].quantity;
                    //     }
                    // }
                    Object.values(content.items).filter(item => item.Count !== 0).forEach(item =>
                        data.Pachete.push({Id: item.Id, Count: item.Count})
                    )
                    break;

                case 2: // DATE FIRMA
                    content.selectii.forEach(sel => data.RapoarteFirma.push({
                        TipArticolId: sel,
                        FirmaId: content.companyId
                    }));
                    break;

                case 3: // DATE MARKETING
                    data.RapoarteMarketing.push({
                        FiltreMarketing: content.filters,
                        AchizitiiMarketing: content.selection.map(sel => {
                            return {
                                CampMarketingId: sel.id,
                                Cantitate: sel.count
                            };
                        })
                    });

                    break;

                default:
                    break;
            }
        }

        this.setState({
            paymentLoading: true,
            paymentMethod: paymentMethod,
        });

        CartActions.commit(data, successCallback);
    }

    onClearPayment() {
        this.setState({
            mobilpay: null,
            paymentLoading: false,
        });
    }

    onCommit() { }

    onCommitCompleted(data, successCallback = () => undefined) {
        this.setState({
            message: 'Comanda a fost trimisa cu succes.',
            success: true,
            paymentLoading: false,
            mobilpay: data,
        });

        CartActions.clear();
        successCallback();
    }

    onCommitFailed() {
        this.setState({
            message: 'A aparut o problema la trimiterea comenzii.',
            success: false,
            paymentLoading: false,
        });
    }

    onReadAquisition() { }

    onReadAquisitionCompleted(data) {
        this.setState({ acquisition: data });
    }

    onReadAquisitionFailed() { }

    onClearAquisition() {
        this.setState({ acquisition: null });
    }
}

export default CartStore;
