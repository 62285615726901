import Reflux from 'reflux';
import AccountPackageActions from '../actions/AccountPackageActions';
import UtilActions from '../actions/UtilActions';

class AccountPackageStore extends Reflux.Store {
    constructor() {
        super();
        this.listenables = [ AccountPackageActions, UtilActions];
        this.state = {
            loadingPackage: true,
            pachet: null,
            marketing: null,
        };
    }

    onLoad() {
        this.setState({
            loadingPackage: true,
        }); 
    }

    onLoadCompleted(pachet) {
        this.setState({
            loadingPackage: false,
            pachet: pachet.pachete,
            marketing: pachet.marketing,
        });
    }

    onLoadFailed(error) {
        this.setState({
            loadingPackage: false,
        });
    }

    onSet = () => {
        AccountPackageActions.load();
    }

    onReset = () => {
        this.setState({
            loadingPackage: true,
            pachet: null,
            marketing: null,
        });
    }
}

export default AccountPackageStore;
