import React from 'react';
import Reflux from 'reflux';

import Title from './Title/Title';
import Navigation from './Navigation/Navigation';

import UserStore from '../../stores/UserStore';
import NotificationsStore from '../../stores/NotificationsStore';

import UserActions from '../../actions/UserActions';
import NotificationsActions from '../../actions/NotificationsActions';

import './Header.css';

/**
 * Application header poriton.
 */
class Header extends Reflux.Component {
    constructor() {
        super();
        this.stores = [ UserStore, NotificationsStore ];
    }

	componentDidMount = () => {
	    const { authenticated } = this.state;
	    const { loading } = this.state.user;
	    if (authenticated && loading) {
	        UserActions.loadDetails();
	    }
	}

	componentDidUpdate = (oldProps, oldState) => {
	    const { data } = this.state.user;
	    const oldData = oldState.user ? oldState.user.data : null;
	    if (data && !oldData) {
	        NotificationsActions.load();
	    }
	}

	render() {
	    const { authenticated, user, messaging, monitoring } = this.state;
	    return (
	        <div className="header">
	            <Title />
	            <Navigation authenticated={authenticated} user={user} messaging={messaging} monitoring={monitoring} />
	        </div>
	    );
	}
}

export default Header;
