import React, {useRef} from 'react'
import './ChangeUserModal.css'
import ModalsActions from '../../../actions/ModalsActions';
import UserActions from '../../../actions/UserActions';


const ChangeUserModal = (props) => {
    const email = useRef("")

    const handleCloseClick = () => {
        ModalsActions.hideModal();
    }

    const handleLoginSubmit = (event) => {
        event.preventDefault();
        UserActions.changeUser(email.current.value)
    }

    const {text, message} = props
    return (
        <div className="login-modal modal-dialog" >
            <form className="modal-content modals-modal" onSubmit={handleLoginSubmit}>
                <div className="row">
                    <button type="button" className="modals-close-button close" onClick={handleCloseClick}>&times;</button>
                </div>
                <div className="row">
                    <div className="col-xs-10 col-xs-offset-1">
                        <h3 className="modals-title">{text && text.Title}</h3>
                        {message ? <p className="modals-message">{message}</p> : null}
                        <input type="email" className="modals-input form-control" ref={email} placeholder="E-mail" required />
                        <button type="submit" className="btn btn-block modals-btn bg-la-salle-green">{"Autentificare"}</button>
                        <div className="clearfix" />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default ChangeUserModal