import { createActions } from 'reflux';
import settings from '../settings';
import axios from 'axios';

const AccountPackageActions = createActions({
    load: { asyncResult: true, }
});

/**
 * Load the account status.
 */
AccountPackageActions.load.listen(() => {
    const headers = Object.assign({user_key: localStorage.getItem('DATE_FIRME_USER_KEY')}, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.READ_USER_ACCOUNT_PACKAGE
    // app.get( 
    //     settings.INTERNAL_URLS.READ_USER_ACCOUNT_PACKAGE
    axios.get(
        url, 
        {
            headers: headers, 
        }
    ).then((response) => {
        const { data } = response;
        AccountPackageActions.load.completed(data);
    }).catch((error) => {
        AccountPackageActions.load.failed(error);
    });
});

export default AccountPackageActions;
