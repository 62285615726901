import React from 'react';
import { Link } from 'react-router-dom';

import './AccountControl.css';

const AccountControl = (props) => (
    <div className="account-control control col-xs-12 col-sm-4 col-lg-2">
        <Link to="data/details/account"><div className="control-inner">
            <div className="control-content">
                <div className="control-graphic settings-control-graphic" />
                <div className="control-text">
                    <h4>{props.text}</h4>
                    <p>{props.subtitle}</p>
                </div>
            </div>
        </div></Link>
    </div>
);

export default AccountControl;