import React from 'react';
import { Link } from 'react-router-dom';

import './TutorialControl.css';

const TutorialControl = (props) => (
    <div className="tutorial-control control col-xs-12 col-sm-4 col-lg-2 col-xs-offset-0 col-sm-offset-4 col-lg-offset-0">
        <Link to="/data/tutorial">
            <div className="control-inner">
                <div className="control-content">
                    <div className="control-graphic tutorial-control-graphic" />
                    <div className="control-text">
                        <h4>{props.text}</h4>
                        <p>{props.subtitle}</p>
                    </div>
                </div>
            </div>
        </Link>
    </div>
);

export default TutorialControl;