import React from 'react';
import { Link } from 'react-router-dom';

import UserActions from '../../../../../actions/UserActions';

import './LogoutButton.css';

class LogoutButton extends React.Component {

	handleLogoutClick = (event) => {
	    UserActions.logout();
	}

	render() {
	    return (
	        <Link to='/'>
			<button className="btn btn-sm bg-orange pull-right" onClick={this.handleLogoutClick}>
				{this.props.text}
	        </button></Link>
	    );
	}
}

export default LogoutButton;