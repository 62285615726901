import React from 'react';
import Reflux from 'reflux';

import CompanyCard from '../../Data/Content/List/ListContent/ListContentBody/CompanyCards/CompanyCard/CompanyCard';

import LastViewedActions from '../../../../actions/LastViewedActions';
import LastViewedStore from '../../../../stores/LastViewedStore';

import './RecentlyViewed.css';

class RecentlyViewed extends Reflux.Component {
    constructor() {
        super();
        this.store = LastViewedStore;
    }

	componentDidMount = () => {
		var language = localStorage.getItem("Language")
        if(language === "Ro" || language === null){
			import('../../../Languages/language_ro').then(lang => {
				this.setState({language: lang.default.Language})
			})
		}
		if(language === "En"){
			import('../../../Languages/language_en').then(lang => {
				this.setState({language: lang.default.Language})
			})
		}
	    LastViewedActions.load();
	}

	render() {
	    const { loading, companies, language } = this.state;
	    const lastViewed = [];
	    for (let i = 0; i < companies.length; i++) {
	        const company = companies[i];
	        lastViewed.push(
	            <div key={i} className="recently-viewed-card col-xs-12 col-lg-8 col-lg-offset-2">
	                <CompanyCard company={company} actions={LastViewedActions} home={true} text={language && language.CompanyCard}/> 
	                <div className="spacer" />
	            </div> 
	        );
	    }
	    return (
	        <div className="recently-viewed row">
	            <div className="recently-viewed-head col-xs-12">
	                <h2>{language && language.RecentlyViewed.Title}</h2>
	            </div>
	            {lastViewed}
	            {!companies.length ? <p className="recently-viewed-message">{language && language.RecentlyViewed.NoInfo}</p> : null}
	            {loading ? <div className="loading" /> : null}
	        </div>
	    );
	}
}

export default RecentlyViewed;