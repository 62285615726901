import React, { Component } from 'react';
import ModalsActions from '../../../actions/ModalsActions';

class ErrorModal extends Component {

	handleModalClick = (event) => {
	    event.stopPropagation();
	}

	handleCloseClick = (event) => {
	    ModalsActions.hideModal();
	}

	handleAcceptClick = (event) => {
	    ModalsActions.hideModal();
	}

	handleSubmit = (event) => {
	    event.preventDefault();
	}

	render() {
    	const { title, message } = this.props;
	    return (
	        <div className="monitor-modal modal-dialog" onClick={this.handleModalClick}>
	            <form className="modal-content modals-modal" onSubmit={this.handleSubmit}>
	                <div className="row">
	                    <button type="button" className="modals-close-button close" onClick={this.handleCloseClick}>&times;</button>
	                </div>
	                <div className="row">
	                    <div className="col-xs-10 col-xs-offset-1">
	                        <h3 className="modals-title">{title}</h3>
	                        <p className="margin-bottom-25 text-center">{message}</p>
	                        <button type="button" className="btn btn-block modals-btn bg-cadet-grey" onClick={this.handleAcceptClick}>Ok</button>
	                    </div>
	                    <div className="clearfix modals-row-bottom" />
	                </div>
	            </form>
	        </div>
	    );
	}
}

export default ErrorModal;