import React from 'react';
import { Link } from 'react-router-dom';

import './AssociatePortfolioControl.css';

const AssociatePortfolioControl = (props) => (
    <div className="associate-portfolio-control control col-xs-12 col-sm-4 col-lg-2">
        <Link to="data/list/associates/portfolio?page=1">
            <div className="control-inner">
                <div className="control-content">
                    <div className="control-graphic associate-control-graphic" />
                    <div className="control-text">
                        <h4>{props.text}</h4>
                        <p>{props.portfolio !== null ? props.portfolio + (props.portfolio === 1 ? ` ${props.asociatiSg}` : ` ${props.asociatiPl}`) : ''}</p>
                    </div>
                </div>
            </div>
        </Link>
    </div>
);

export default AssociatePortfolioControl;