import React from 'react';
import { Component } from 'reflux';

import NavigationButtons from './NavigationButtons/NavigationButtons';

import LeftMenuActions from '../../../actions/LeftMenuActions';
import LeftMenuStore from '../../../stores/LeftMenuStore';

import './Navigation.css';

/**
 * The navigation menu section of the header.
 */
class Navigation extends Component {
    constructor() {
        super();
        this.store = LeftMenuStore;
    }

	handleClick = () => {
	    LeftMenuActions.hide();
	}

	render() {
	    const { open } = this.state;
	    return (
	        <div className={'navigation ' + (open ? ' open' : '')} onClick={this.handleClick}>
	            <NavigationButtons open={open} {...this.props} />
	        </div>
	    );
	}
}

export default Navigation;
