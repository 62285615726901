import React, { useEffect, useState } from 'react';

import AccountPackages from '../../Data/Content/Details/DetailsContent/Account/AccountStatus/AccountPackages/AccountPackages'

import './AccountState.css';

const AccountState = () => {
    const [language, setLanguage] = useState(null)

    useEffect(() => {
        var language = localStorage.getItem("Language")
        if (language === "Ro" || language === null) {
            import('../../../Languages/language_ro').then(lang => {
                setLanguage(lang.default.Language)
            })
        }
        if (language === "En") {
            import('../../../Languages/language_en').then(lang => {
                setLanguage(lang.default.Language)
            })
        }
    }, [])
    return (
        <div className="account-state row">
            <div className="new-head col-xs-12">
                <h2>{language && language.AccountStatus.Title}</h2>
            </div>
            <div className="col-xs-12">
                <div className="account-state-inner row">
                    <AccountPackages className="account-state-status col-sm-12 col-sm-offset-0 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2" 
                        packNames={language && language.AccountStatus.AccountPackages}
                    />
                </div>
            </div>
        </div >
    )
};

export default AccountState;