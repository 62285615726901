import React from 'react';

import ErrorCenter from './ErrorCenter/ErrorCenter';

import './Error.css';

const Error = (props) => {
    const { errorCode } = props.match.params;
    var errorMessage = '';
    switch (parseInt(errorCode, 10)) {
    case 404:
        errorMessage = 'NOT FOUND';
        break;
    case 500:
        errorMessage = 'INTERNAL SERVER ERROR';
        break;
    default:
        errorMessage = 'UNKNOWN ERROR';
        break;
    }
    return (
        <div className="error">
            <ErrorCenter errorCode={errorCode} errorMessage={errorMessage} />
            <div className="clearfix" />
        </div>
    );
};

export default Error;