// Common string transforms

/**
 * @param {string} str Has to be a string.
 * @param {string} sep The separator, non-breaking space by default.
 * @returns Separates lower-case characters or numbers immediately followed by an upper-case character.
 * @throws If input is not a string.
 * @example
 * splitCamelCase("splitCamelCase");       // "split Camel Case"
 * splitCamelCase("splitCamelCase", "--"); // "split--Camel--Case"
 * splitCamelCase("1split2Camel3Case4");   // "1split2 Camel3 Case4"
 * splitCamelCase("1Split2Camel3Case4");   // "1 Split2 Camel3 Case4"
 */
export const splitCamelCase = (str, sep = '\u00A0') => str.replace(/([a-z]|\d)([A-Z])/g, `$1${sep}$2`);

export default { splitCamelCase };