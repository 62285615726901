import React from 'react';
import Reflux from 'reflux';

import ModalsActions from '../../../actions/ModalsActions';
import UserActions from '../../../actions/UserActions';

import UserStore from '../../../stores/UserStore';

import './ContractModal.css';

const isNullOrWhitespace = (str) => str == null || str.trim() === '';

// NOTE! Some stuff will be hardcoded, since they don't really change.

class ContractModal extends Reflux.Component {
    constructor() {
        super();
        this.state = {
            enableRecomCode: true,
            errorMessage: '',

            fiscalCode: '',
        };

        this.store = UserStore;

        const { user } = this.store.state;
        this.state.fiscalCode = user && user.data && user.data.cif ? user.data.cif : '';
    }

    componentDidMount() {
        UserActions.getFiscalCodeData(this.state.fiscalCode);
    }

    componentDidUpdate(_prevProps, prevState) {
        const data = this.state.fiscalCodeData;
        if (prevState.fiscalCodeData !== data) {
            this.refs.companyName.value = data.companyName;
            this.refs.recomType.value = data.recomType;
            this.refs.recomCounty.value = data.recomCounty;
            this.refs.recomNumber.value = data.recomNumber;
            this.refs.recomYear.value = data.recomYear;
            this.refs.address.value = data.address;
        }

        if (this.state.contractCreation.data) {
            ModalsActions.hideModal();
        }
    }

    stopModalClose = event => event.stopPropagation();
    handleModalClose = () => ModalsActions.hideModal();

    handleModalSubmit = event => {
        event.preventDefault();

        const data = {
            adresa: this.refs.address.value,
            denumire: this.refs.companyName.value,
            codFiscal: parseInt(this.state.fiscalCode, 10),
            contBanca: this.refs.bankAccount.value,
            banca: this.refs.bankName.value,
            reprezentantLegal: this.refs.legalRepresentative.value,
            persoanaDeContact: this.refs.contactPerson.value,
            adresaCorespondenta: this.refs.correspondenceAddress.value,
            telefon: this.refs.landlinePhone.value,
            mobil: this.refs.mobilePhone.value,
            fax: this.refs.fax.value
        };

        if (this.state.enableRecomCode) {
            data.codRecomTip = this.refs.recomType.value;
            data.codRecomJudet = parseInt(this.refs.recomCounty.value, 10);
            data.codRecomNumar = this.refs.recomNumber.valueAsNumber;
            data.codRecomAn = parseInt(this.refs.recomYear.value, 10);
        } else {
            data.codRecomTip = null;
            data.codRecomJudet = 0;
            data.codRecomNumar = 0;
            data.codRecomAn = 0;
        }

        if (isNullOrWhitespace(data.telefon) && isNullOrWhitespace(data.mobil) && isNullOrWhitespace(data.fax)) {
            this.setState({
                errorMessage: `${this.props.text && this.props.text.Completati}`
            });
            return;
        }

        UserActions.createContract(data);
    }

    handleEnableRecomCode = event => {
        this.setState({
            enableRecomCode: event.target.checked
        });
    }

    handleFiscalCodeChange = event => {
        this.setState({
            fiscalCode: event.target.value
        }, () => {
            UserActions.getFiscalCodeData(this.state.fiscalCode);
        });
    }

    render() {
        const { user, contractCreation, errorMessage, enableRecomCode } = this.state;
        const {text} = this.props

        const message = contractCreation && !contractCreation.loading && !contractCreation.data
            ? `${text && text.Existent}`
            : '';

        const recomYears = [];
        for (let y = new Date().getFullYear(); y >= 1990; --y) {
            recomYears.push(<option value={y} key={y}>{y}</option>);
        }

        const recomTypes = ['C', 'F', 'J'].map(t => <option value={t} key={t}>{t}</option>);
        
        return (
            <div className="modal-dialog" onClick={this.stopModalClose}>
                <form className="modal-content modals-modal" onSubmit={this.handleModalSubmit}>
                    <div className="row">
                        <button type="button" className="modals-close-button close" onClick={this.handleModalClose}>&times;</button>
                    </div>
                    <div className="row modals-row-bottom">
                        <div className="col-xs-10 col-xs-offset-1">
                            <h3 className="modals-title">{text && text.Title}</h3>
                            {message ? <p className="modals-message">{message}</p> : null}
                            {errorMessage ? <p className="modals-message">{errorMessage}</p> : null}
                            <input type="text" className="modals-input form-control" ref="companyName" placeholder={text && text.Denumire} required
                                defaultValue={user && user.data && user.data.firma ? user.data.firma : ''}
                            />
                            <input type="number" className="modals-input form-control" value={this.state.fiscalCode} onChange={this.handleFiscalCodeChange}
                                placeholder={text && text.Fiscal} required />
                            <p>{text && text.CodRecom}<input type="checkbox" value={enableRecomCode} onChange={this.handleEnableRecomCode} checked={enableRecomCode}/></p>
                            {enableRecomCode
                                ? <div>
                                    <select className="modals-select form-control cod-recom" ref="recomType" required>
                                        {recomTypes}
                                    </select>
                                    <select className="modals-select form-control cod-recom" ref="recomCounty" required>
                                        <option value="1" key="1">01 Alba</option>
                                        <option value="2" key="2">02 Arad</option>
                                        <option value="3" key="3">03 Arges</option>
                                        <option value="4" key="4">04 Bacau</option>
                                        <option value="5" key="5">05 Bihor</option>
                                        <option value="6" key="6">06 Bistrita-Nasaud</option>
                                        <option value="7" key="7">07 Botosani</option>
                                        <option value="8" key="8">08 Brasov</option>
                                        <option value="9" key="9">09 Braila</option>
                                        <option value="10" key="10">10 Buzau</option>
                                        <option value="11" key="11">11 Caras-Severin</option>
                                        <option value="12" key="12">12 Cluj</option>
                                        <option value="13" key="13">13 Constanta</option>
                                        <option value="14" key="14">14 Covasna</option>
                                        <option value="15" key="15">15 Dambovita</option>
                                        <option value="16" key="16">16 Dolj</option>
                                        <option value="17" key="17">17 Galati</option>
                                        <option value="18" key="18">18 Gorj</option>
                                        <option value="19" key="19">19 Harghita</option>
                                        <option value="20" key="20">20 Hunedoara</option>
                                        <option value="21" key="21">21 Ialomita</option>
                                        <option value="22" key="22">22 Iasi</option>
                                        <option value="23" key="23">23 Ilfov</option>
                                        <option value="24" key="24">24 Maramures</option>
                                        <option value="25" key="25">25 Mehedinti</option>
                                        <option value="26" key="26">26 Mures</option>
                                        <option value="27" key="27">27 Neamt</option>
                                        <option value="28" key="28">28 Olt</option>
                                        <option value="29" key="29">29 Prahova</option>
                                        <option value="30" key="30">30 Satu Mare</option>
                                        <option value="31" key="31">31 Salaj</option>
                                        <option value="32" key="32">32 Sibiu</option>
                                        <option value="33" key="33">33 Suceava</option>
                                        <option value="34" key="34">34 Teleorman</option>
                                        <option value="35" key="35">35 Timis</option>
                                        <option value="36" key="36">36 Tulcea</option>
                                        <option value="37" key="37">37 Vaslui</option>
                                        <option value="38" key="38">38 Valcea</option>
                                        <option value="39" key="39">39 Vrancea</option>
                                        <option value="40" key="40">40 Bucuresti</option>
                                        <option value="51" key="51">51 Calarasi</option>
                                        <option value="52" key="52">52 Giurgiu</option>
                                    </select>
                                    <input type="number" className="modals-input form-control cod-recom" ref="recomNumber" placeholder={text && text.NumarRecom} required />
                                    <select className="modals-select form-control cod-recom" ref="recomYear" required>
                                        {recomYears}
                                    </select> </div>
                                : null
                            }
                            <input type="text" className="modals-input form-control" ref="bankAccount" placeholder={text && text.ContBanca} required />
                            <input type="text" className="modals-input form-control" ref="bankName" placeholder={text && text.Banca} required />
                            <input type="text" className="modals-input form-control" ref="address" placeholder={text && text.Adresa} required />
                            <input type="text" className="modals-input form-control" ref="correspondenceAddress" placeholder={text && text.AdresaCorespondenta} required />
                            <input type="tel" className="modals-input form-control" ref="landlinePhone" placeholder={text && text.Fix}
                                defaultValue={user && user.data && user.data.telefon ? user.data.telefon : ''}
                            />
                            <input type="tel" className="modals-input form-control" ref="fax" placeholder={text && text.Fax} />
                            <input type="tel" className="modals-input form-control" ref="mobilePhone" placeholder={text && text.Mobil} />
                            <input type="text" className="modals-input form-control" ref="legalRepresentative" placeholder={text && text.Reprezentant} required />
                            <input type="text" className="modals-input form-control" ref="contactPerson" placeholder={text && text.Persoana} required />
                            <br />
                            <button type="submit" className="btn btn-block modals-btn bg-la-salle-green">{text && text.Salveaza}</button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default ContractModal;
