import React, { useEffect, useState } from 'react';

import './Search.css';
import SearchBar from './SearchBar/SearchBar';

const Search = (props) => {
	const [language, setLanguage] = useState(null)

    useEffect(() => {
        var language = localStorage.getItem("Language")
        if (language === "Ro" || language === null) {
            import('../../../Languages/language_ro').then(lang => {
                setLanguage(lang.default.Language)
            })
        }
        if (language === "En") {
            import('../../../Languages/language_en').then(lang => {
                setLanguage(lang.default.Language)
            })
        }
    }, [])

	const { authenticated, history } = props;
	const { loading, data } = props.user;

	return (
		<div id="search" className="row text-center">
			{authenticated
				? [
					<h1 key={0}>{language && language.Search.Title}, {loading ? '...' : data.nume}!</h1>,
					<div key={1} className="search-spacer" />
				] : [
					<h1 key={0}>Date Firme</h1>,
					<h2 key={1} className="home-subheading">{language && language.Search.Subtitle}</h2>
				]
			}
			<SearchBar history={history} placeholder={language && language.Search.Placeholder} buttonTxt = {language && language.Search.Button}/> 
		</div>
	);
}

export default Search;