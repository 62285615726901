import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React from 'react';
// import ShowMenuButton from './ShowMenuButton/ShowMenuButton';
import imgsrc from '../../Icons/logo_df.svg'
import icapImg from '../../Icons/icap-logo.png'
import LanguageSelector from './LanguageSelector/LanguageSelector';
import './Title.css';

const Title = () => (
    <div className="title">
        <Link to="/" className="logo-link"><Image src={imgsrc} alt="Date firme" className="title-logo" /></Link>
        {/* <ShowMenuButton /> */}
        <LanguageSelector />
        <a href='https://www.icapcrif.com/' target='_blank'><Image src={icapImg} className='icap-img' /></a>
    </div>
);

export default Title;
