import { Store } from 'reflux';

import LeftMenuActions from '../actions/LeftMenuActions';

class LeftMenuStore extends Store {
    constructor() {
        super();
        this.listenables = LeftMenuActions;
        this.state = {
            open: false,
        };
    }
    
    onShow() {
        this.setState({
            open: true,
        });
    }

    onHide() {
        this.setState({
            open: false,
        });
    }
}

export default LeftMenuStore;
