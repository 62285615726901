// Algorithms pertaining to "Registrul Comertului"

// Used in isValidRecomCode()
const recomTestDigits = [ 2, 3, 5, 7, 1, 2, 3, 5, 7 ];

/**
 * https://ro.wikipedia.org/wiki/Cod_de_Identificare_Fiscal%C4%83
 * @param {Number} code The code to be checked; must be an integer.
 * @returns {Boolean} Whether a Recome Code is correct according to the govt.'s algorithm.
 */
export const isValidRecomCode = code => {
    if (!Number.isInteger(code)) {
        return false;
    }

    code = code.toFixed();
    if (code.length > 10 || code.length < 2) {
        return false;
    }

    const reversedDigits = code.split('').map((_, index, arr) => Number(arr[arr.length - index - 1]));
    const control = reversedDigits[0];

    let sum = 0;
    for (let i = 1; i < reversedDigits.length; i++) {
        sum += recomTestDigits[i - 1] * reversedDigits[i];
    }

    let verify = (sum * 10) % 11;
    if (verify === 10) {
        verify = 0;
    }

    return verify === control;
};

export default { isValidRecomCode };