import React from 'react'
import ModalsActions from '../../../actions/ModalsActions';
import './RaportImage.css'

const RaportImage = (props) => {

    const handleCloseClick = (event) => {
        ModalsActions.hideModal();
    }

    return (
        <div className={`${props.rap === null ? "raportImage" : "raportImageGRM"}`}>
            <button className="modals-close-button close" onClick={handleCloseClick}>&times;</button>
            <img src={props.src} alt="raport"/>
        </div>
    )
}

export default RaportImage