import React from 'react'
import Reflux from 'reflux'
import FloatingLableInput from '../../Reusable/FloatingLabelInput/FloatingLableInput'
import ModalsActions from '../../../actions/ModalsActions';
import './VerifyApiModal.css'
import AdminStore from '../../../stores/AdminStore';
import SimpleButton from '../../Reusable/SimpleButton/SimpleButton';
import AdminActions from '../../../actions/AdminActions';

class VerifyApiModal extends Reflux.Component {
  constructor(props) {
    super(props)
    this.store = AdminStore
    this.state = {
      codFiscal: 33591353,
      dataInterogare: this.getCurrentDate(),
    }
  }

  componentDidMount() {

  }

  componentWillUnmount() {
    this.handleReset()
  }

  stopPropagation(e) {
    e.stopPropagation()
  }

  closeModal() {
    ModalsActions.hideModal()
  }

  handleSendRequestClick() {
    AdminActions.verifyApi({
      tip: this.props.tip,
      cfiscal: this.state.codFiscal,
      data: this.state.dataInterogare.toString()
    })
  }

  handleReset() {
    this.setState({ codFiscal: 33591353, dataInterogare: this.getCurrentDate() })
    AdminActions.resetApi()
  }

  getCurrentDate() {
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const year = today.getFullYear();
    const day = today.getDate();
    return `${year}-${month}-${day}`;
  }

  getContentByType() {
    switch (this.props.tip) {
      case 1: //Anaf
      case 2:
        return (
          <div className='apiModalSearch'>
            <FloatingLableInput type='number' label='Cod Fiscal' value={this.state.codFiscal} placeholder='Cod Fiscal' onChange={(e) => this.setState({ codFiscal: parseInt(e.target.value, 10) })} />
            <FloatingLableInput type='input' label='Data' value={this.state.dataInterogare} placeholder='Data' onChange={(e) => this.setState({ dataInterogare: e.target.value })} />
            <SimpleButton text='Send' onClick={() => this.handleSendRequestClick()} styleCss='apiSearchButton' />
          </div>
        )
      case 5: //Metro
      case 6: //BERC
      case 7: //RNPM
        return (
          <div className='apiModalSearch'>
            <FloatingLableInput type='number' label='Cod Fiscal' value={this.state.codFiscal} placeholder='Cod Fiscal' onChange={(e) => this.setState({ codFiscal: parseInt(e.target.value, 10) })} />
            <SimpleButton text='Send' onClick={() => this.handleSendRequestClick()} styleCss='apiSearchButton' />
          </div>
        )
      default:
        return null
    }
  }

  getJson(apiResponse) {
    try {
      return JSON.stringify(JSON.parse(apiResponse), null, 2)
    } catch (e) {
      return apiResponse;
    }
  }

  render() {
    const { apiResponse, apiLoading } = this.state
    return (
      <div className="modal-dialog api-modal" onClick={this.stopPropagation}>
        <div className="modal-content modals-modal">
          <div className="row">
            <button type="button" className="modals-close-button close" onClick={this.closeModal}>&times;</button>
            {this.getContentByType()}
            {apiLoading && <p>Loading...</p>}
            {apiResponse &&
              <div>
                <p><b>Status:</b> {apiResponse.status} - {apiResponse.statusMessage}</p>
                <pre>{this.getJson(apiResponse.result)}</pre>
              </div>}
          </div>
        </div>
      </div>
    )
  }

}

export default VerifyApiModal