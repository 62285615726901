import React from 'react';

import './ErrorMessage.css';

const ErrorMessage = (props) => (
    <div className="error-message">
        {props.errorCode} - {props.errorMessage}
    </div>
);

export default ErrorMessage;