import { createActions } from 'reflux';
import settings from '../settings';
import axios from 'axios';

const StatisticsActions = createActions({
    load: { asyncResult: true },
});

/**
 * Load the orders.
 */
StatisticsActions.load.listen((type) => {
    const headers = Object.assign({user_key: localStorage.getItem('DATE_FIRME_USER_KEY')}, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.READ_STATISTICS
    // app.get(
    //     settings.INTERNAL_URLS.READ_STATISTICS,
    axios.get(
        url, 
        {
            headers: headers, 
        }
    ).then((response) => {
        const { data } = response;
        StatisticsActions.load.completed(data);
    }).catch((error) => {
        StatisticsActions.load.failed(error);
    });
});

export default StatisticsActions;