import React from 'react'
import '../LoginButton/LoginButton.css'
import ModalsActions from '../../../../../actions/ModalsActions';

const ChangeUserButton = () => {

    const onClickHandler = () => {
        ModalsActions.showModal('changeUser', null); 
    }

    return (
        <button className="btn btn-sm bg-cadet-grey pull-right" onClick={onClickHandler}>
            <span className="pull-left" />Change User
        </button>
    )
}

export default ChangeUserButton