import React from 'react';
import { Link } from 'react-router-dom';

const BPIPersoane = (props) => {
    const {monitoring, text} = props

  return (
    <div className="messaging-control control col-xs-12 col-sm-4 col-lg-2">
            <div className="control-inner">
                <Link to="data/bpiPersoane">
                    <div className="control-content">
                        <div className="control-graphic associate-control-graphic"></div>
                        <div className="control-text">
                            <h4>{text && text.PersoaneBPI}</h4>
                            <p>{monitoring && (monitoring.persoane === '1' ? `1 ${text && text.MonitPersSg}` : `${monitoring.persoane} ${text && text.MonitPersPl}`)}</p>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
  )
}

export default BPIPersoane