import React from 'react'
import './FirstLoginInfoModal.css'
import ModalsActions from '../../../actions/ModalsActions'

import img from '../../Icons/logo_df.svg'

const FirstLoginInfoModal = props => {
    const handleCloseClick = (event) => {
        ModalsActions.hideModal();
    }

    const handleGoClick = () => {
        window.location.reload(false)
    }

    return (
        <div className='modal-dialog topFirstLogin'> 
            <div className="modal-content firstLogin">
                <div className="row closeBtn">
                    <button type="button" className="modals-close-button close" onClick={(event) => handleCloseClick(event)}>&times;</button>
                </div>
                <div className='imageDiv'>
                    <img className='image' src={img} alt="accountInfo"/> 
                </div>
                <p className='header'>Bine ati venit la DateFirme!</p>
                <div className='body'>
                    <p>Locul in care verificati si monitorizati companii.</p>
                    <p>Veti putea sa obtineti radiografia completa a 5 companii. Fara cost si fara expirarea contului.</p>
                    <p>Veti putea sa monitorizati 5 companii. Fara cost si fara expirarea contului.</p>
                    <p>Optiunea Pachete de Date permite achizitia de pachete de rapoarte. Fara abonament si doar pachetele de informatii de care aveti nevoie.</p>
                </div>
                <div className='footerBtn'>
                    <button onClick={handleGoClick} className='btn bg-la-salle-green'>Let's Go</button>
                </div>
            </div>
        </div>
    )
}

export default FirstLoginInfoModal