import React, { useState } from 'react';
import NumericInput from 'react-numeric-input';
import './Categories.css';
import InfoTooltip from '../../../../../Tooltips/InfoTooltip/InfoTooltip';

const Category = props => {
    let nameEn = ""
    if (props.lang === "En") {
        switch (props.data.name) {
            case "Raport Administrativ":
                nameEn = "Administrative Report"
                break
            case "Raport Financiar":
                nameEn = "Financial Report"
                break
            case "Raport Legal":
                nameEn = "Legal Report"
                break
            case "Raport Buletinul Electronic al Registrului Comertului":
                nameEn = "Raport Buletinul Electronic al Registrului Comertului"
                break
            case "Raport Incidente de Plata":
                nameEn = "Payment Incidents Central Report"
                break
            case "Raport Registrul Naţional de Publicitate Mobiliară":
                nameEn = "National Registry of Movable Property Report"
                break
            case "Raport Licitatii":
                nameEn = "Auction Report"
                break
            case "Raport Stare":
                nameEn = "Status Report"
                break
            default:
                nameEn = "All Options"
                break
        }
    }

    return (
        <span className="picker-checkbox">
            <input
                type="checkbox"
                onChange={props.onChange}
                checked={props.data.value}
                name={props.id}
                disabled={props.disabled}
            /> {(props.lang === "Ro" || props.lang === null) ? props.data.name : nameEn}
        </span>
    )
};


const Categories = props => {
    const [level, setLevel] = useState(2)

    const changeLevel = () => {
        props.levelHartaChange(level)
    }


    const { categories, checkboxHandler, disabled, lang, text, hideCip, hideBerc } = props;
    return (
        <div className="row">
            <div className="administrativ">
                {<Category id="administrativ" className="category" data={categories.administrativ} onChange={checkboxHandler} disabled={disabled} lang={lang} />}
                <div onClick={changeLevel} className={categories.administrativ.value ? "" : "hidden"}>
                    <NumericInput className="item" onChange={e => setLevel(e)} value={level} min={1} max={5} />
                    <label>{text && text.LvlHarta}</label>
                    <InfoTooltip html={
                        <ul>
                            <li>{text && text.LvlHartaMsg}</li>
                        </ul>
                    } />
                </div>
            </div>
            <br />
            {<Category id="financiar" data={categories.financiar} onChange={checkboxHandler} disabled={disabled} lang={lang}/>}
            <br />
            {<Category id="legal" data={categories.legal} onChange={checkboxHandler} disabled={disabled} lang={lang}/>}
            <br />
            {!hideBerc && <Category id="berc" data={categories.berc} onChange={checkboxHandler} disabled={disabled} lang={lang}/>}
            {!hideBerc && <br />}
            {!hideCip && <Category id="incidente" data={categories.incidente} onChange={checkboxHandler} disabled={disabled} lang={lang}/>}
            {!hideCip && <br />}
            {<Category id="publicitate" data={categories.publicitate} onChange={checkboxHandler} disabled={disabled} lang={lang}/>}
            <br />
            {<Category id="licitatii" data={categories.licitatii} onChange={checkboxHandler} disabled={disabled} lang={lang}/>}
            <br />
            <br />
            {<Category id="toate" data={categories.toate} onChange={checkboxHandler} disabled={disabled} lang={lang}/>}
            <br />
            {<Category id="stare" data={categories.stare} onChange={checkboxHandler} disabled={disabled} lang={lang}/>}
        </div>
    );
};

export default Categories;
