import React from 'react';
import { Link } from 'react-router-dom';

import './DetailsReports.css';

const DetailsReports = (props) => {
    const {text} = props

    return (
        <div className="details-reports">
            <div>
                <p>{text && text.Integrare}</p>
                <p>{text && text.Avantaje}</p>
                <ul>
                    <li>{text && text.A1}</li>
                    <li>{text && text.A2}</li>
                    <li>{text && text.A3}</li>
                    <li>{text && text.A4}</li>
                    <li>{text && text.A5}</li>
                </ul>
                <Link to="/data/contact">{text && text.Contact}</Link>
            </div>
        </div>
    )
};

export default DetailsReports;