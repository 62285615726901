import React from 'react';
import { Link } from 'react-router-dom';

import './ComplexInterrogationControl.css';

const ComplexInterrogationControl = props => (
    <div className="messaging-control control col-xs-12 col-sm-4 col-lg-2">
        <div className="control-inner">
            <Link to="data/complexinterrogations">
                <div className="control-content">
                    {/* need to change the graphic below */}
                    <div className="control-graphic interrogation-control-graphic"></div>
                    <div className="control-text">
                        <h4>{props.text}</h4>
                        <p>{props.subtitle}</p>
                    </div>
                </div>
            </Link>
        </div>
    </div>
);

export default ComplexInterrogationControl;