import React from 'react';
import Reflux from 'reflux';

import ModalsActions from '../../../actions/ModalsActions';
import UserActions from '../../../actions/UserActions';

import { isValidRecomCode } from '../../../utils/Recom';

import UserStore from '../../../stores/UserStore';
import Cookies from 'js-cookie';

import './RegisterModal.css';

class RegisterModal extends Reflux.Component {
    constructor() {
        super();
        this.state = {
            errorMessages: [],
            passwordErrorMessage: null,
            passwordConfirmMessage: null,
            passwordConfirmClass: 'modals-message',
            companyCodeConfirmMessage: null,
            companyCodeConfirmClass: 'modals-message',
            phoneErrorMessage: null,
            phoneErrorClass: '',
        };

        this.store = UserStore;
    }

    componentDidUpdate() {
        if (this.state.accountCreation.registerError === 'Adresa de e-mail este deja folosita') {
            this.refs.email.value = '';
        }
    }

    handleModalClick = (event) => {
        event.stopPropagation();
    }

    handleCloseClick = () => {
        ModalsActions.hideModal();
    }

    getPasswordError = () => {
        const password = this.refs.password.value;

        if (password.length < 6) {
            return this.props.text && this.props.text.Min6;
        }

        let counter = 0;
        if (/\d/.test(password)) ++counter;
        if (/[a-z]/.test(password)) ++counter;
        if (/[A-Z]/.test(password)) ++counter;
        if (/[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)) ++counter;

        if (counter < 2) {
            return this.props.text && this.props.text.TooSimple
        }

        return null;
    }

    handleRegisterSubmit = (event) => {
        event.preventDefault();
 
        const name = this.refs.name.value;
        const email = this.refs.email.value;
        const phone = this.refs.phone.value;
        const password = this.refs.password.value;
        const passwordConfirm = this.refs.passwordConfirm.value;
        const company = this.refs.company.value;
        const companyCode = this.refs.companyCode.valueAsNumber;

        const errorMessages = [];
        if (!isValidRecomCode(companyCode)) {
            errorMessages.push(this.props.text && this.props.text.NCIF);
            this.refs.companyCode.value = '';
        }

        // reversed and take the last 10 numbers then reverse the 10 numbers again to obtain the phone number ->
        // -> to avoid DB inconsistencies + same numbers but with different format
        // related to bug #6991
        const computedPhone = phone.split('').reverse().slice(0, 10).reverse().join('');

        if (isNaN(computedPhone) || computedPhone.length !== 10) {
            errorMessages.push(this.props.text && this.props.text.NPhone)
        }

        const pwerr = this.getPasswordError();
        if (pwerr !== null) {
            errorMessages.push(pwerr);
        }

        if (password !== passwordConfirm) {
            errorMessages.push(this.props.text && this.props.text.NPass);
        }

        this.setState({ errorMessages });

        let referrerId = Cookies.get('referrerId')
        console.log(referrerId)
        if(typeof referrerId === 'undefined'){
            referrerId = 1
        }else{
            referrerId = parseInt(referrerId, 10)
        }

        if (errorMessages.length === 0) {
            UserActions.createDetails(name, company, companyCode, email, computedPhone, password, referrerId);
        }
    }

    handleShowLogin = () => {
        ModalsActions.showModal('login');
    }

    handlePasswordConfirm = () => {
        const password = this.refs.password.value;
        const confirm = this.refs.passwordConfirm.value;

        if (password === confirm && this.getPasswordError() === null) {
            this.setState({
                passwordConfirmClass: 'modals-message-green',
                passwordConfirmMessage: this.props.text && this.props.text.GPass
            });
        } else {
            this.setState({
                passwordConfirmClass: 'modals-message',
                passwordConfirmMessage: this.props.text && this.props.text.NPass
            });
        }
    }

    handlePasswordChanged = (event) => {
        this.setState({
            passwordConfirmClass: 'modals-message',
            passwordErrorMessage: this.getPasswordError()
        });

        if (this.state.passwordConfirmMessage) {
            this.handlePasswordConfirm(event);
        }
    }

    handlePhoneChanged = () => {
        const phone =  this.refs.phone.value;
        const PHONE_REG_EXP = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

        if (PHONE_REG_EXP.test(phone)) {
            this.setState({
                phoneErrorMessage: null,
                phoneErrorClass: '',
            });
        } else {
            this.setState({
                phoneErrorMessage: this.props.text && this.props.text.NPhone,
                phoneErrorClass: 'modals-message',
            });
        }
    }

    checkCompanyCode = () => {
        const cif = this.refs.companyCode.valueAsNumber;
        if (isValidRecomCode(cif)) {
            this.setState({
                companyCodeConfirmClass: 'modals-message-green',
                companyCodeConfirmMessage: 'CIF valid.'
            });
        } else {
            this.setState({
                companyCodeConfirmClass: 'modals-message',
                companyCodeConfirmMessage: 'CIF invalid.'
            });
        }
    }

    render() {
        const {
            errorMessages,
            passwordErrorMessage,
            passwordConfirmMessage,
            passwordConfirmClass,
            companyCodeConfirmMessage,
            companyCodeConfirmClass,
            phoneErrorMessage,
            phoneErrorClass,
        } = this.state;
        const { message, text } = this.props;
        const passError = passwordErrorMessage || passwordConfirmMessage;

        const serverErrorMessage = this.state.accountCreation.registerError;

        return (
            <div className="register-modal modal-dialog" onClick={this.handleModalClick}>
                <form className="modal-content modals-modal" onSubmit={this.handleRegisterSubmit}>
                    <div className="row">
                        <button type="button" className="modals-close-button close" onClick={this.handleCloseClick}>&times;</button>
                    </div>
                    <div className="row">
                        <div className="col-xs-10 col-xs-offset-1">
                            <h3 className="modals-title">{text && text.Inregistrare}</h3>
                            {message ? <p className="modals-message">{message}</p> : null}
                            {errorMessages.map((message, idx) => <p key={idx} className="modals-message">{message}</p>)}
                            {serverErrorMessage ? <p className="modals-message">{serverErrorMessage}</p> : null}
                            <input type="text" className="modals-input form-control" ref="name" placeholder={text && text.Nume} required />
                            <input type="text" className="modals-input form-control" ref="company" placeholder={text && text.Firma} required />
                            <input type="number" className="modals-input form-control" ref="companyCode" placeholder={text && text.CIF}
                                onChange={this.checkCompanyCode}
                                required
                            />
                            {companyCodeConfirmMessage ? <p className={companyCodeConfirmClass}>{companyCodeConfirmMessage}</p> : null}
                            <input type="email" className="modals-input form-control" ref="email" placeholder={text && text.Email} required />
                            <input
                              type="tel"
                              className="modals-input form-control"
                              ref="phone"
                              placeholder={text && text.Telefon}
                              onChange={this.handlePhoneChanged}
                            />
                            {phoneErrorMessage && <p className={phoneErrorClass}>{phoneErrorMessage}</p>}
                            <input type="password" className="modals-input form-control" ref="password" placeholder={text && text.Parola}
                                onBlur={this.hidePasswordRequirements}
                                onKeyUp={this.handlePasswordChanged}
                                required
                            />
                            <input type="password" className="modals-input form-control" ref="passwordConfirm" placeholder={text && text.Confirma}
                                onKeyUp={this.handlePasswordConfirm}
                                required
                            />
                            {passError ? <p className={passwordConfirmClass}>{passError}</p> : null}
                            <div className="row modals-row">
                                <input className="modals-check" type="checkbox" />&nbsp;
                                {text && text.Remember}
                            </div>
                            <div className="row modals-row">
                                <input className="modals-check" type="checkbox" required />&nbsp;
                                {text && text.Conditii}&nbsp;
                                <a href="https://www.datefirme.ro/Termeni.aspx" target="_blank" rel="noopener noreferrer">{window.location.host}</a>.
                            </div>
                            <div className="row modals-row">
                                <input className="modals-check" type="checkbox" required />&nbsp;
                                {text && text.Informari}
                            </div>
                            <button type="submit" className="btn btn-block modals-btn bg-la-salle-green">{text && text.Creeaza}</button>
                            <div className="clearfix" />
                            {/* <div className="modals-or">
                                <div className="modals-or-center center-block">
                                    <span className="modals-or">sau</span>
                                </div>
                            </div>
                            <button type="button" className="btn btn-block modals-btn bg-facebook">Creeaza Cont cu Facebook</button> */}
                            <div className="row modals-row-bottom">
                                {text && text.Cont}<a className="modals-link" onClick={this.handleShowLogin}>{text && text.Click}</a>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default RegisterModal;
