import Reflux from 'reflux';
import settings from '../settings';
import axios from 'axios';

const InterrogationActions = Reflux.createActions({
    issueMultipleRaport: { asyncResult: true },
    loadInterrogationHistory: {asyncResult: true},
    load: {asyncResult: true},
})

InterrogationActions.issueMultipleRaport.listen( (codesInfo, levelHartaActionari) => {
    const headers = Object.assign({user_key: localStorage.getItem('DATE_FIRME_USER_KEY')}, settings.INTERNAL_BACKEND_CONFIG.headers);
    const {codes, codesNb} = codesInfo
    // const {dateStart, dateEnd} = dateInterval
    const url = settings.URL_BASE + settings.URL_PATHS_API.ISSUE_MULTIPLE_REPORTS
    + '?termeni=' + (codes ? btoa(codes) : '')
    + '&raportNb=' + (codesNb ? codesNb : '')
    // + '&dateStart=' + (dateStart ? dateStart : '')
    // + '&dateEnd=' + (dateEnd ? dateEnd : '')
    + '&levelHarta=' + (levelHartaActionari ? levelHartaActionari : "")
    
    axios.get(
        url,
        {
            headers: headers, 
        }
    ).then(response => {
        // let status = response.status;
        InterrogationActions.issueMultipleRaport.completed();
    }).catch(error => {
        InterrogationActions.issueMultipleRaport.failed(error);
    })

    // fetch(
    //     url,
    //     {
    //         headers: headers, 
    //     }
    // ).then(response => {
    //     let status = response.status;
    //     InterrogationActions.issueMultipleRaport.completed();
    // }).catch(error => {
    //     InterrogationActions.issueMultipleRaport.failed(error);
    // })
})

InterrogationActions.loadInterrogationHistory.listen( () => {
    const headers = Object.assign({user_key: localStorage.getItem('DATE_FIRME_USER_KEY')}, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.LOAD_REPORT_HISTORY 

    axios.get(
        url,
        {
            headers: headers, 
        }
    ).then(response => { 
        //refresh()
        InterrogationActions.loadInterrogationHistory.completed(response)
    }).catch(error => {
        InterrogationActions.loadInterrogationHistory.failed(error)
    })
})

// InterrogationActions.load.listen((companiesIds) => {
//     const headers = Object.assign({user_key: localStorage.getItem('DATE_FIRME_USER_KEY')}, settings.INTERNAL_BACKEND_CONFIG.headers);
//     const url = settings.URL_BASE + settings.URL_PATHS_API.READ_COMPANIES_WITH_ID + '/' + companiesIds
//     // app.get(
//     //     settings.INTERNAL_URLS.READ_COMPANIES_WITH_ID + '/' + companiesIds
//     axios.get(
//         url,
//         {
//             headers: headers, 
//         }
//     ).then((response) => {
//         const { data } = response;
//         InterrogationActions.load.completed(data);
//     }).catch((error) => {
//         InterrogationActions.load.failed(error);
//     });
// });

export default InterrogationActions