import React from 'react';
// import { Link } from 'react-router-dom';

import './DetailsFirms.css';

/** Toggle the next sibling between display (block/none) states. */
const toggleNextSiblingVisibility = ({ target }) => {
    const sibling = target.nextSibling;
    sibling.style.display = sibling.style.display !== "block" ? "block" : "none";
};

// <p> <ul> <li> <Link>
const DetailsFirms = (props) => {
    const { text } = props

    return (
        <div className="row details-firms">
            <p>{text && text.Title}</p>

            <h3>{text && text.Oferite} {window.location.host}:</h3>

            <div className="col-md-6 col-sm-12">
                <h4>{text && text.Adm}</h4>
                <ul className="main-list">
                    <li className="expandable" onClick={toggleNextSiblingVisibility}>{text && text.RegCom}</li>
                    <ul>
                        <li>{text && text.RegCom1}</li>
                        <li>{text && text.RegCom2}</li>
                        <li>{text && text.RegCom3}</li>
                        <li>{text && text.RegCom4}</li>
                    </ul>
                    <li className="expandable" onClick={toggleNextSiblingVisibility}>{text && text.Structura}</li>
                    <ul>
                        <li>{text && text.Structura1}</li>
                        <li>{text && text.Structura2}</li>
                        <li>{text && text.Structura3}</li>
                    </ul>
                    <li className="expandable" onClick={toggleNextSiblingVisibility}>{text && text.Harta}</li>
                    <ul>
                        <li>{text && text.Harta1}</li>
                    </ul>
                </ul>
            </div>

            <div className="col-md-6 col-sm-12">
                <h4>{text && text.Legal}</h4>
                <ul className="main-list">
                    <li>{text && text.MOF}</li>
                    <li className="expandable" onClick={toggleNextSiblingVisibility}>{text && text.BPI}</li>
                    <ul>
                        <li>
                        {text && text.BPI1}
                        </li>
                    </ul>
                    <li className="expandable" onClick={toggleNextSiblingVisibility}>{text && text.Insolventa}</li>
                    <ul>
                        <li>{text && text.Insolventa1}</li>
                    </ul>
                    <li className="expandable" onClick={toggleNextSiblingVisibility}>{text && text.Justitie}</li>
                    <ul>
                        <li>{text && text.Justitie1}</li>
                    </ul>
                </ul>
            </div>

            <div className="clearfix" />

            <div className="col-md-6 col-sm-12">
                <h4>{text && text.Financiar}</h4>
                <ul className="main-list">
                    <li className="expandable" onClick={toggleNextSiblingVisibility}>{text && text.Evolutie}</li>
                    <ul>
                        <li>{text && text.Evolutie1}</li>
                    </ul>
                    <li className="expandable" onClick={toggleNextSiblingVisibility}>{text && text.ANAF}</li>
                    <ul>
                        <li>{text && text.ANAF1}</li>
                        <li>{text && text.ANAF2}</li>
                        <li>{text && text.ANAF3}</li>
                        <li>{text && text.ANAF4}</li>
                    </ul>
                    <li className="expandable" onClick={toggleNextSiblingVisibility}>{text && text.Indicatori}</li>
                    <ul>
                        <li>{text && text.Indicatori1}</li>
                        <li>{text && text.Indicatori2}</li>
                        <li>{text && text.Indicatori3}</li>
                    </ul>
                    <li className="expandable" onClick={toggleNextSiblingVisibility}>{text && text.Datorii}</li>
                    <ul>
                        <li>{text && text.Datorii1}</li>
                    </ul>
                    <li className="expandable" onClick={toggleNextSiblingVisibility}>{text && text.Garantii}</li>
                    <ul>
                        <li>{text && text.Garantii1}</li>
                    </ul>
                </ul>
            </div>

            <div className="col-md-6 col-sm-12">
                <h4>{text && text.CIP}</h4>
                <ul className="main-list">
                    <li>{text && text.CIP1}</li>
                </ul>
            </div>

            <div className="clearfix" />

            <p>{text && text.End}</p>
        </div>
    )
};

export default DetailsFirms;