import React from 'react';
import { Component } from 'reflux';

import StatisticsActions from '../../../../actions/StatisticsActions';
import StatisticsStore from '../../../../stores/StatisticsStore';

import './New.css';

class New extends Component {
    constructor() {
        super();
        this.store = StatisticsStore;
    }

	componentDidMount = () => {
	    StatisticsActions.load();
	}

	render() {
	    const { data } = this.state;
		const {text} = this.props
	    return (
	        <div className="new row">
	            <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-lg-offset-1 col-md-offset-1">
	                <div className="new-head col-xs-12">
	                    <h2>{text && text.Noutati}</h2>
	                </div>
	                <div className="new-box col-lg-4 col-md-4 col-sm-4 col-xs-12">
	                    <div className="new-box-content row">
	                        <div className="left-content new-companies" />
	                        <div className="right-content row">
	                            <div className="right-count row">{data ? data.firmeNoi : 0}</div>
	                            <div className="right-description row">{text && text.Firme}</div>
	                        </div>
	                    </div>
	                </div>
	                <div className="new-box col-lg-4 col-md-4 col-sm-4 col-xs-12">
	                    <div className="new-box-content row">
	                        <div className="left-content new-dossiers" />
	                        <div className="right-content row">
	                            <div className="right-count row">{data ? data.dosareNoi : 0}</div>
	                            <div className="right-description row">{text && text.Dosare}</div>
	                        </div>
	                    </div>
	                </div>
	                <div className="new-box col-lg-4 col-md-4 col-sm-4 col-xs-12">
	                    <div className="new-box-content row">
	                        <div className="left-content insolvency" />
	                        <div className="right-content row">
	                            <div className="right-count row">{data ? data.firmeIntrateInInsolventa : 0}</div>
	                            <div className="right-description row">{text && text.Insolventa}</div>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </div>
	    );
	}
}

export default New;