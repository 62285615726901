import React from 'react';
import Reflux from 'reflux';

import CrumbActions from '../../../../../../actions/CrumbActions';

import './DemoDetails.css';

class DemoDetails extends Reflux.Component {

	componentDidMount = () => {
		if (this.props.location) {
			let { title } = this.props;
			const { pathname } = this.props.location;
			CrumbActions.addStandaloneDetail(title, pathname);
		}
	}

	render() {
	    let { title, body } = this.props;

	    return (
	        <div className="demoDetails container">
	            <div className="new-head">
	                <h2>{title}</h2>
	            </div>
	            <hr />
	            {body}
	        </div>
	    );
	}
}

export default DemoDetails;