import React from 'react';
import './History.css';
import HistoryTable from './HistoryTable/HistoryTable';

const History = (props) => {

    let component = props.isLoading
                    ? <div className='loadingHistory' />
                    : props.istoric === null
                        ? <p>{props.text.FirstCommand}</p>
                        : <HistoryTable istoric={props.istoric} text={props.text} language={props.language} hideCIP={props.hideCIP} hideBERC={props.hideBERC}/>


    return (
        <React.Fragment>
            <div className="col-xs-12 details-category-title">
                <h3>{props.text.History}</h3> 
            </div>
            <div className="tableCss">
                {component}
            </div>
        </React.Fragment>
    )
}

export default History;
