import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './Home/Home';
import Data from './Data/Data';
import Error from './Error/Error';
import Reflux from 'reflux';
import './Body.css';

// import HartaActionariFree from './Data/HartaActionariFree';
// import EvolutiaSocietatiiFree from './Data/EvolutiaSocietatiiFree';
// import GraficeProceseJustitieFree from './Data/GraficeProceseJustitieFree';
// import FinancialGraphsFree from './Data/FinancialGraphsFree';
// import FirmaSEO from './InfoGratuite/FirmaSEO/FirmaSEO';
// import ListaFirmeJudete from './InfoGratuite/ListaFirmeJudete';
// import ListaFirmeLocalitati from './InfoGratuite/ListaFirmeLocalitati';
// import ListaFirmeLocalitate from './InfoGratuite/ListaFirmeLocalitate';

const HartaActionariFree = lazy(() => import('./Data/Free/HartaActionari/HartaActionariFree'))
const EvolutiaSocietatiiFree = lazy(() => import('./Data/Free/EvolutiaSocietatii/EvolutiaSocietatiiFree'))
const GraficeProceseJustitieFree = lazy(() => import('./Data/Free/GraficeProceseJustitie/GraficeProceseJustitieFree'))
const FinancialGraphsFree = lazy(() => import('./Data/Free/FinancialGraphs/FinancialGraphsFree'))
const LimitaCreditFree = lazy(() => import('./Data/Free/LimitaCredite/LimitaCrediteFree'))
const RiskRatingFree = lazy(() => import('./Data/Free/RiskRating/RiskRatingFree'))
const FirmaSEO = lazy(() => import('./InfoGratuite/FirmaSEO/FirmaSEO'))
const ListaFirmeJudete = lazy(() => import('./InfoGratuite/ListaFirmeJudete'))
const ListaFirmeLocalitati = lazy(() => import('./InfoGratuite/ListaFirmeLocalitati'))
const ListaFirmeLocalitate = lazy(() => import('./InfoGratuite/ListaFirmeLocalitate'))
const EmailOff = lazy(() => import('./InfoGratuite/EmailOff'))
const GDPR = lazy(() => import('./InfoGratuite/GDPR'))
const Admin = lazy(() => import('./Admin/Admin'))

/**
 * The content of the application.
 */

class Body extends Reflux.Component {

    constructor() {
        super();
        this.networkRef = React.createRef();
    }

    render() {
        return (
            <div className="body row">
                <Suspense fallback={<div></div>}>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/data" component={Data} />
                        <Route path="/hartaActionari/:companyId/:lvl/:userId" component={HartaActionariFree} />
                        <Route path="/lista-firmelor-pe-judete" component={ListaFirmeJudete} />
                        <Route path="/lista-firme-localitate-:judet/:localitate" component={ListaFirmeLocalitate} />
                        <Route path="/lista-firme-:judet" component={ListaFirmeLocalitati} />
                        <Route path="/firma/:cui/:denumire" component={FirmaSEO} />
                        <Route path="/evolutiaSocietatii/:companyId" component={EvolutiaSocietatiiFree} />
                        <Route path="/graficeProceseJustitie/:companyId" component={GraficeProceseJustitieFree} />
                        <Route path="/graficeIndicatori/:companyId/:language" component={FinancialGraphsFree} />
                        <Route path="/limitaCredit/:companyId/:userId" component={LimitaCreditFree} />
                        <Route path="/riskRating/:companyId/:userId" component={RiskRatingFree} />
                        <Route path="/dezabonareEmail/:email" component={EmailOff} />
                        <Route path="/gdpr" component={GDPR} />
                        <Route path="/admin" component={Admin} />
                        <Route path="/error/:errorCode" component={Error} />
                        <Redirect from="/*" to="/error/404" />
                    </Switch>
                </Suspense>
            </div>
        );
    }
}
export default Body;