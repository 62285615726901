import React from 'react';

import Crumb from './Crumb/Crumb';
import Content from './Content/Content';

import './Data.css';

const Data = (props) => (
    <div className="data row">
        <Crumb history={props.history}/>
        <Content />
    </div>
);

export default Data;