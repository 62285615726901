import React from 'react';
import { Route, BrowserRouter, withRouter } from 'react-router-dom';
import Notifications from 'react-notify-toast';

import Modals from './Modals/Modals';
import Header from './Header/Header';
import Body from './Body/Body';
import Footer from './Footer/Footer';

import Reflux from 'reflux'

import './App.css';
import AppStore from '../stores/AppStore';

// const App = props => {
//     const bgImage = () => <div className="app-bg-image" />;

//     const path = props.location.pathname.toLowerCase();
//     const doNotRender = path.includes('/hartaactionari') || path.includes('/evolutiasocietatii') || path.includes('/graficeprocesejustitie') 
//     || path.includes('/graficeindicatori')
//     const renderFooter = path.includes('/admin')

//     return (
//         <BrowserRouter>
//             <div className="app container-fluid">
//                 <Modals />
//                 <Notifications />
//                 <Route exact path="/" component={bgImage} />

//                 {doNotRender ? null : <Header />}
//                 <Body />
//                 {(doNotRender || renderFooter) ? null : <Footer />}
//             </div>
//         </BrowserRouter>
//     );
// };

class App extends Reflux.Component {
    constructor(){
        super()
        this.store = AppStore
    }

    render() {
        const {showFooter} = this.state
        const bgImage = () => <div className="app-bg-image" />;
        const path = this.props.location.pathname.toLowerCase();
        const doNotRender = path.includes('/hartaactionari') || path.includes('/evolutiasocietatii') || path.includes('/graficeprocesejustitie')
            || path.includes('/graficeindicatori') || path.includes('/limitacredit') || path.includes('/riskrating')

        return (
            <BrowserRouter>
                <div className="app container-fluid">
                    <Modals />
                    <Notifications />
                    <Route exact path="/" component={bgImage} />

                    {doNotRender ? null : <Header />}
                    <Body />
                    {(doNotRender || !showFooter) ? null : <Footer />}
                </div>
            </BrowserRouter>
        );
    }
};

export default withRouter(App);
