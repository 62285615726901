import { createActions } from 'reflux';
import settings from '../settings';
import axios from 'axios';

const LastViewedActions = createActions({
    load: { asyncResult: true },
    refreshCompanies: {},
});

/**
 * Load the last viewed companies.
 */
LastViewedActions.load.listen(() => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.READ_LAST_VIEWED_COMPANIES_BY_USER + '?limit=' + 4
    // app.get(
    // settings.INTERNAL_URLS.READ_LAST_VIEWED_COMPANIES_BY_USER
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        LastViewedActions.load.completed(data);
    }).catch((error) => {
        LastViewedActions.load.failed(error);
    });
});

export default LastViewedActions;
