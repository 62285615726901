import { createActions } from 'reflux';
import settings from '../settings';
import axios from 'axios';

const AccountStatusActions = createActions({
    load: { asyncResult: true, }
});

/**
 * Load the account status.
 */
AccountStatusActions.load.listen(() => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.READ_USER_ACCOUNT_STATUS
    // app.get(
    // settings.INTERNAL_URLS.READ_USER_ACCOUNT_STATUS
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        AccountStatusActions.load.completed(data);
    }).catch((error) => {
        AccountStatusActions.load.failed(error);
    });
});

export default AccountStatusActions;
