import { Store } from "reflux";
import AppActions from "../actions/AppActions";

class AppStore extends Store {
    constructor() {
        super()
        this.listenables = AppActions
        this.state = {
            showFooter: true,
        }
    }

    onShowFooter(){
        this.setState({showFooter: true})
    }

    onHideFooter(){
        this.setState({showFooter: false})
    }
}

export default AppStore