import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import './InfoTooltip.css';

const getUniqueId = (() => {
    let id = 0;
    return () => {
        id = id <= Number.MAX_SAFE_INTEGER ? id + 1 : 0;
        return `df-info-tooltip-${id}`;
    };
})();

/**
 * React-bootstrap tooltip. Override .info-tooltip-trigger in CSS to make style changes.
 * @example
 * let props = {
 *  html: <div>Example tooltip.</div>, // default: undefined
 *  trigger: ["hover", "focus"],       // default -- Passed to react-bootstrap/OverlayTrigger
 *  placement: "bottom"                // default -- Passed to react-bootstrap/OverlayTrigger
 * };
 */
const InfoTooltip = props => {
    const {
        html,
        trigger = ["hover", "focus"],
        placement = "bottom"
    } = props;

    return (
        <OverlayTrigger trigger={trigger} placement={placement} overlay={<Popover id={getUniqueId()}>{html}</Popover>}>
            <span className="info-tooltip-trigger glyphicon glyphicon-info-sign"/>
        </OverlayTrigger>
    );
};

export default InfoTooltip;