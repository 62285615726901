import React from 'react';
import InfoTooltip from '../../../../../../Tooltips/InfoTooltip/InfoTooltip';
import './DetailsMonitoring.css';

/*
<InfoTooltip html={
	<ul>
		<li>Structura Administrativa</li>
		<li>Date Registrul Comertului</li>
		<li>Harta Actionariat</li>
	</ul>
} />
*/

const DetailsMonitoring = (props) => {
	const { text } = props

	return (
		<div className="details-monitoring">
			<div>
				<p>{text && text.Title}</p>
				<h3>{text && text.Riscuri}</h3>
				<ul>
					<li>{text && text.R1}</li>
					<li>{text && text.R2}</li>
					<li>{text && text.R3}</li>
					<li>{text && text.R4}</li>
					<li>{text && text.R5}</li>
					<li>{text && text.R6}</li>
					<li>{text && text.R7}</li>
				</ul>
				<p>{text && text.Informare}</p>
			</div>
			<div>
				<h3>{text && text.Functionare}</h3>
				<ul className="demo-no-padding">
					<li>{text && text.F1}<strong>{text && text.F2}</strong>{text && text.F3}</li>
				</ul>
				<table className="demo-table">
					<tbody>
						<tr>
							<td>{text && text.Sursa}</td>
							<td><strong>{text && text.Zilnic}</strong></td>
							<td><strong>{text && text.Saptamanal}</strong></td>
							<td><strong>{text && text.Bilunar}</strong></td>
							<td><strong>{text && text.Trimestrial}</strong></td>
						</tr>
						<tr>
							<td><strong>{text && text.Solicitarile}</strong></td>
							<td className="demo-center"><strong>&#10003;</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>{text && text.Procese}</strong></td>
							<td className="demo-center"><strong>&#10003;</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>{text && text.Buletin}</strong></td>
							<td className="demo-center"><strong>&#10003;</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>{text && text.MOF}</strong></td>
							<td className="demo-center"><strong>&#10003;</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>{text && text.Datorii}</strong></td>
							<td></td>
							<td></td>
							<td></td>
							<td className="demo-center"><strong>&#10003;</strong></td>
						</tr>
						<tr>
							<td><strong>
								{text && text.ANAF}
								<InfoTooltip html={
									<ul>
										<li>{text && text.Tool1}</li>
										<li>{text && text.Tool2}</li>
										<li>{text && text.Tool3}</li>
										<li>{text && text.Tool4}</li>
									</ul>
								} />
							</strong></td>
							<td></td>
							<td className="demo-center"><strong>&#10003;</strong></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>{text && text.GRM}</strong></td>
							<td className="demo-center"><strong>&#10003;</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>{text && text.Stare}</strong></td>
							<td></td>
							<td></td>
							<td className="demo-center"><strong>&#10003;</strong></td>
							<td></td>

						</tr>
						<tr>
							<td>
								<strong>{text && text.Centrala}</strong>
								<InfoTooltip html={<div>{text && text.Frecventa}</div>} />
							</td>
							<td></td>
							<td className="demo-center"><strong>&#10003;</strong></td>
							<td></td>
							<td></td>
						</tr>
					</tbody>
				</table>
				<ul className="demo-no-padding">
					<li>{text && text.Txt1}<strong>{text && text.Txt2}</strong>{text && text.Txt3}</li>
					<li>{text && text.Txt4}</li>
				</ul>
			</div>
		</div>
	);
}

export default DetailsMonitoring;