import React from 'react';

import ModalsActions from '../../../../../actions/ModalsActions';

import './LoginButton.css';

/**
 * The button that initiates the login modal.
 */
class LoginButton extends React.Component {

	onLoginClick = (event) => {
	    ModalsActions.showModal('login'); 
	}

	render() {
	    return (
	        <button className="btn btn-sm bg-cadet-grey pull-right" onClick={this.onLoginClick}>
	            <span className="user-login pull-left" />{this.props.text}
	        </button>
	    );
	}
}

export default LoginButton;