import { Store } from 'reflux';
import InterrogationActions from '../actions/InterrogationActions';

class InterrogationStore extends Store{
    constructor(){
        super()
        this.listenables = InterrogationActions
        this.state = {
            raportsJson: null,
            istoricInterrogations: [],
            loadingHistory: false,
            errorIstoric: null,
            loadingIssue: false
        }
    }

    onLoadInterrogationHistory(){
        this.setState({loadingHistory: true})
    }

    onLoadInterrogationHistoryCompleted(response){
        // let istoricResults = []
        // for(let i=0; i<response.data.length; i++){
        //     istoricResults.push({
        //         ID: response.data[i].InterogareId,
        //         CUI: response.data[i].ListaCui,
        //         firmeID: response.data[i].ListaFirmeId,
        //         optiuni: response.data[i].ListOptiuni,
        //         status: response.data[i].StatusId,
        //         dataInterogare: response.data[i].DataInterogare,
        //         nivelHarta: response.data[i].NivelHartaActionari,
        //     })
        // }   
        this.setState({
            istoricInterrogations: response.data,
            loadingHistory: false
        })
    }

    onLoadInterrogationHistoryFailed(error){
        this.setState({
            istoricInterrogations: null,
            errorIstoric: error
        })
    }

    onIssueMultipleRaport(){
        this.setState({
            loadingIssue: true
        })
    }

    onIssueMultipleRaportCompleted(){
        this.setState({
            loadingIssue: false
        })
    }

}

export default InterrogationStore