import React from 'react';

import ErrorMessage from './ErrorMessage/ErrorMessage';
import ErrorHomeButton from './ErrorHomeButton/ErrorHomeButton';

import './ErrorCenter.css';

const ErrorCenter = (props) => (
    <div className="error-center">
        <ErrorMessage errorCode={props.errorCode} errorMessage={props.errorMessage} />
        <ErrorHomeButton />
    </div>
);

export default ErrorCenter;