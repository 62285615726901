// Contains functions that change the location (url)

/** Clears the hash parameter from location. */

export const clearHash = (() => {
    return window.history && window.history.pushState
        ? () => { window.history.pushState(null, null, window.location.href.split('#')[0]); }
        : () => { window.location.hash = ''; };
})();

export default { clearHash };