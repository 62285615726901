import React from 'react';
import { clearHash } from '../../../../../../utils/Location';

import './Sidebar.css';

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            affix: false
        }
    }

    handleScroll = () => {
        const { container } = this.refs;
        const { top } = container.getBoundingClientRect();

        this.setState({ affix: top < 0 });
    }

    componentDidMount() {
        const keepHash = this.props.currentInterrogationHandler();
        if (!keepHash && window.location.hash === this.currentInterrogationId) {
            clearHash();
        }
        const { container } = this.refs;
        const { top } = container.getBoundingClientRect();
        this.setState({ affix: top < 0 });
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        const { affix } = this.state
        const{text} = this.props
        return (
            <div ref="container" className='details-sidebar'>
                <div className={affix ? 'floated' : ''}>
                    <div id="sidebar-details-header" className="row">
                        <div id="sidebar-details-title">
                            <a href="#complex-interrogarions">{text && text.Title}</a>
                        </div>
                        <div id="sidebar-details-header-arrow" className="hidden-xs" />
                    </div>
                    <div className="sidebar-details-account row">
                        <div className="sidebar-details-account-category col-xs-12">
                            <a href='#complex-interrogarions-current'>{text && text.Current}</a>
                        </div>
                        <div className="sidebar-details-account-category col-xs-12">
                            <a href="#complex-interrogarions-history">{text && text.History}</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Sidebar;
