import './CompanyCard.css';

import { Link } from 'react-router-dom';
import React from 'react';
import Reflux from 'reflux';
import AccountPackageActions from '../../../../../../../../../actions/AccountPackageActions';
import AccountPackageStore from '../../../../../../../../../stores/AccountPackageStore';
import ModalsActions from '../../../../../../../../../actions/ModalsActions';
import MonitorActions from '../../../../../../../../../actions/MonitorActions';
import UserStore from '../../../../../../../../../stores/UserStore';

class CompanyCard extends Reflux.Component {
    constructor(props) {
        super(props);
        this.stores = [UserStore, AccountPackageStore];

        this.state = {
            isMonitored: false, 
            isSet: false
        };
    }

    refresh = () => {
        const { actions } = this.props;
        AccountPackageActions.load();

        (actions.refreshCompanies || actions.reloadLatest)();
    }

    handleMonitor = () => {
        const { id } = this.props.company;
        const { data } = this.state.user;
        const { pachet } = this.state;

        if (data) {
            if (pachet && pachet.monitorizare.crediteDisponibile && pachet.monitorizare.crediteDisponibile[0] && pachet.monitorizare.crediteDisponibile[0].disponibile) {
                MonitorActions.monitor(1, id, this.refresh);
                this.setState({
                    isMonitored: true,
                    isSet: true
                });
            } else {
                ModalsActions.showModal('monitor');
            }
        } else {
            ModalsActions.showModal('login', `${this.props.text.MonitorMsg}`);
        }
    }

    handleUnmonitor = () => {
        const { id } = this.props.company;
        MonitorActions.unmonitor(1, id, this.refresh);
        this.setState({
            isMonitored: false,
            isSet: true
        });
    }

    handleRedirect = () => {
        const { route, company } = this.props;
        route.history.push('/data/details/company/' + company.id); 
    }

    handleCheckUser = (event) => {
        const { data } = this.state.user;
        if (!data) {
            ModalsActions.showModal('login', `${this.props.text.DetailMsg}`, this.handleRedirect);
            event.preventDefault();
            event.stopPropagation();
        }
    }

    loggedStatusPath = (codFiscal, denumire, id) => {
        const { data } = this.state.user
        if(!data) {
            return ('/firma/' + codFiscal + '/' + denumire.replaceAll(" ", "-")) 
        }else{
            return ('/data/details/company/' + id)
        }
    }

    componentWillUnmount() {
        this.setState({
            isMonitored: false,
            isSet: false
        });
        super.componentWillUnmount();
    }



    render() {
        const { id, denumire, codFiscal, codRecom, locatie, monitorizata, inactiveActionar, inactiveAdministrator } = this.props.company;
        const { actionar, text} = this.props

        if(typeof(text) === 'undefined'){
            return null
        }
        return (
            <div className="company-card row">
                <div className="card-graphic">
                    {/* <Link to={'/data/details/company/' + id} className="company-graphic" onClick={this.handleCheckUser} /> */}
                    <Link to={this.loggedStatusPath(codFiscal, denumire, id)} className="company-graphic"/>
                </div>
                <div className="card-content">
                    <div className="row">
                        {/* <Link to={'/data/details/company/' + id} onClick={this.handleCheckUser}> */}
                        <Link to={this.loggedStatusPath(codFiscal, denumire, id)} className="company-graphic">
                            <h3 className="card-title pull-left col-xs-12">{denumire ? denumire : `${this.props.text.Indisponibil}`}</h3>
                        </Link>
                        <div  className="cardHeader">
                            <h4>{actionar && inactiveActionar !== "" ? `${this.props.text.FirmaOut}`+ inactiveActionar : ""}</h4>
                            <h4>{actionar && inactiveAdministrator !== "" ? `${this.props.text.AdminOut}` + inactiveAdministrator : ""}</h4>
                        </div>
                    </div>
                    <div className="card-content-wrapper">
                        <div className="card-row row">
                            <div className="pull-left card-space">
                                <span className="category">CUI: </span>
                                <span className="value">{codFiscal ? codFiscal : `${this.props.text.Indisponibil}`}</span>
                            </div>
                            {codRecom && codRecom.length && codRecom[0] === "X"
                                ? null
                                : <div className="pull-left card-space">
                                    <span className="card-separator">&#8226;</span>
                                    <span className="category">{text && text.RegCom}</span>
                                    <span className="value">{codRecom ? codRecom : `${this.props.text.Indisponibil}`}</span>
                                </div>
                            }
                            <div className="pull-left card-space">
                                <span className="card-separator">&#8226;</span>
                                <span className="category">{text && text.Localitate}</span>
                                <span className="value">{locatie ? locatie : `${this.props.text.Indisponibil}`} </span>
                            </div>
                        </div>

                        {this.props.company != null && this.props.company.radiata ?
                            <div className="card-row row">
                                <div className="radiata">*</div></div> : null}
                    </div>
                </div>
                <div className="card-controls">
                    <div className="card-controls-center-2">
                        {this.state.isSet
                            ? this.state.isMonitored
                                ? <button className="btn bg-cadet-grey btn-block" onClick={this.handleUnmonitor}>{text && text.Stop}</button>
                                : <button className="btn bg-orange btn-block" onClick={this.handleMonitor}>{text && text.Start}</button>
                            : id
                                ? monitorizata
                                    ? <button className="btn bg-cadet-grey btn-block" onClick={this.handleUnmonitor}>{text && text.Stop}</button>
                                    : <button className="btn bg-orange btn-block" onClick={this.handleMonitor}>{text && text.Start}</button>
                                : null
                        }
                        {<div className="spacer" />}
                        <Link to={'/data/details/company/' + id} onClick={this.handleCheckUser}><button className="btn btn-details btn-block">{text && text.Avansat}</button></Link>

                    </div>
                </div>
            </div>
        );
    }
}

export default CompanyCard;
