import Reflux from 'reflux';

import CompanyActions from '../actions/CompanyActions';
import UtilActions from '../actions/UtilActions';

class CompanyStore extends Reflux.Store {
    constructor() {
        super();
        this.listenables = [ CompanyActions, UtilActions ];
        this.state = {
            loading: false,
            data: {},
            freeDetails: {}, 
            waitGraph: false
        };
    }

    onReset() {  
        this.setState({
            loading: false,
            data: {}
        });
    }

    onLoad() {
        this.setState({
            loading: true,
        });
    }

    onLoadCompleted(data) {
        this.setState({
            loading: false, 
            data: data,
        }); 
    }

    onLoadFailed() {
        this.setState({
            loading: false,
        });
    }

    onLoadFreeDetailsCompleted(data) {
        this.setState({
            freeDetails: data
        })
    }

    onWaitGraph() {
        this.setState({
            waitGraph: true,
        });
    }

    onNotWaitGraph() {
        this.setState({
            waitGraph: false,
        });
    }

    // onGetDocumentRadiereCompleted(data){
    //     console.log(data);
    // }

    // onGetDocumentRadiereFailed(data){
    //     console.log(data)
    // }
}

export default CompanyStore;
