import React, { Suspense, lazy } from 'react';
import Reflux from 'reflux';
import { Switch, Route, Redirect } from 'react-router-dom';

// import Messages from './Messages/Messages';

// import List from './List/List';
// import Details from './Details/Details';
// import Basket from './Basket/Basket';
// import ConfirmAccount from './ConfirmAccount/ConfirmAccount';
// import Resend from './Resend/Resend';
// import Recover from './Recover/Recover';
// import Contact from './Contact/Contact';
// import Tutorial from './Tutorial/Tutorial';
// import Packs from './Packs/Packs';
// import Payment from './Payment/Payment';
// import Thankyou from './Thankyou/Thankyou';
// import ThankyouOrder from './Thankyou/ThankyouOrder';
import ComplexInterrogations from './ComplexInterrogations/ComplexInterrogations';

import './Content.css';

const List = lazy(() => import('./List/List'))
const Details = lazy(() => import('./Details/Details'))
const Basket = lazy(() => import('./Basket/Basket'))
const ConfirmAccount = lazy(() => import('./ConfirmAccount/ConfirmAccount'))
const Resend = lazy(() => import('./Resend/Resend'))
const Recover = lazy(() => import('./Recover/Recover'))
const Contact = lazy(() => import('./Contact/Contact'))
const Tutorial = lazy(() => import('./Tutorial/Tutorial'))
const Packs = lazy(() => import('./Packs/Packs'))
const Payment = lazy(() => import('./Payment/Payment'))
const Thankyou = lazy(() => import('./Thankyou/Thankyou'))
const ThankyouOrder = lazy(() => import('./Thankyou/ThankyouOrder'))
const DosareInstanta = lazy(() => import('./DosareInstanta/DosareInstanta'))
const PersonMonitoring = lazy(() => import('./PersonMonitoring/PersonMonitoring'))
const Referrer = lazy(() => import('./Referrer/Referrer'))
const RedirectComp = lazy(() => import('./RedirectComp/RedirectComp'))
// const ComplexInterrogations = lazy(() => import('./ComplexInterrogations/ComplexInterrogations'))

class Content extends Reflux.Component {
    render() {
        return (
            <div className="content">
                <Suspense fallback={<div></div>}>
                    <Switch>
                        <Route exact path="/data/list/*" component={List} />
                        <Route exact path="/data/details/*" component={Details} />
                        <Route path="/data/cart" component={Basket} />
                        <Route path="/data/tutorial" component={Tutorial} />
                        <Route path="/data/confirm" component={ConfirmAccount} />
                        <Route path="/data/resend/*" component={Resend} />
                        <Route path="/data/recover" component={Recover} />
                        <Route path="/data/contact" component={Contact} />
                        <Route path="/data/packages" component={Packs} />
                        <Route path="/data/referrer" component={Referrer}/>
                        <Route path="/data/redirect" component={RedirectComp}/>
                        <Route exact path="/data/payment" component={Payment} />
                        <Route exact path="/data/thankyou" component={Thankyou} />
                        <Route exact path="/data/thankyouorder" component={ThankyouOrder} />
                        <Route exact path="/data/complexinterrogations" component={ComplexInterrogations} />
                        <Route exact path="/data/dosareInstanta" component={DosareInstanta} />
                        <Route exace path="/data/bpiPersoane" component={PersonMonitoring} />
                        <Route exact path="/data/test" component={Payment} />
                        <Redirect to="/" />
                    </Switch>
                </Suspense>
            </div>
        );
    }
}

export default Content;