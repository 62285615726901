import React, { Component } from 'react';

import ModalsActions from '../../../actions/ModalsActions';

class EmailModal extends Component {

	handleModalClick = (event) => {
		event.stopPropagation();
	}

	handleCloseClick = (event) => {
		ModalsActions.hideModal();
	}

	handleAcceptClick = (event) => {
		ModalsActions.hideModal();
	}

	handleSubmit = (event) => {
		event.preventDefault();
	}

	render() {
		const { text } = this.props

		return (
			<div className="email-modal modal-dialog" onClick={this.handleModalClick}>
				<form className="modal-content modals-modal" onSubmit={this.handleSubmit}>
					<div className="row">
						<button type="button" className="modals-close-button close" onClick={this.handleCloseClick}>&times;</button>
					</div>
					<div className="row">
						<div className="col-xs-10 col-xs-offset-1">
							<h3 className="modals-title">{text && text.Inregistrare}</h3>
						</div>
						<div className="col-xs-10 col-xs-offset-1 basket-message basket-success">
							{text && text.Email}
						</div>
						<div className="col-xs-10 col-xs-offset-1">
							<button type="button" className="btn btn-block modals-btn bg-la-salle-green" onClick={this.handleAcceptClick}>{text && text.Inteles}</button>
						</div>
						<div className="clearfix" />
					</div>
				</form>
			</div>
		);
	}
}

export default EmailModal;