import React from 'react';

import ModalsActions from '../../../../../actions/ModalsActions';

import './RegisterButton.css';

/**
 * THe button that initiates the registration modal.
 */
class RegisterButton extends React.Component {

	handleRegisterClick = (event) => {
	    ModalsActions.showModal('register');
	}

	render() {
	    return (
	        <button className="btn btn-sm bg-orange pull-right" onClick={this.handleRegisterClick}>
				{this.props.text}
	        </button>
	    );
	}
}

export default RegisterButton;