import React from 'react'
import './VideoModal.css'
import ModalsActions from '../../../actions/ModalsActions'
// import YoutubeEmbed from '../../Utils/YoutubeEmbed'

export default function VideoModal({ url }) {
  const handleCloseClick = (event) => {
    ModalsActions.hideModal();
  }

  return (
    <div className='modal-dialog topVideoModal'>
        <div className="modal-content videoModal">
            <div className="row closeBtn videoClose">
                <button type="button" className="modals-close-button close videoClose" onClick={(event) => handleCloseClick(event)}>&times;</button>
            </div>
            {/* <YoutubeEmbed embedId = {url}/>    */}
            <video className='htmlVideo' controls autoPlay>
                <source src={url} type="video/mp4" />
            </video>
        </div>
    </div>
  )
}
