import React from 'react';
import { Link } from 'react-router-dom';
import Reflux from 'reflux'
import ModalsActions from '../../../actions/ModalsActions';
import UserActions from '../../../actions/UserActions';
import CompanyStore from '../../../stores/CompanyStore';

import './LoginModal.css';

class LoginModal extends Reflux.Component {
    constructor() {
        super();
        this.store = CompanyStore;
    }

    handleModalClick = (event) => {
        event.stopPropagation();
    }

    handleCloseClick = (event) => {
        ModalsActions.hideModal();
    }

    handleLoginSubmit = (event) => {
        const { callback, url } = this.props;
        event.preventDefault();
        const email = this.refs.email.value, password = this.refs.password.value;
        UserActions.login(email, password, callback);
        ModalsActions.hideModal();    
        this.changeUrlAfterLogin(url)
    }

    changeUrlAfterLogin = (url) => {
        if(window.location.pathname.includes("firma")){
            setTimeout(() => {
                const id = this.state.freeDetails.detalii.id
                const newUrl = "/data/details/company/" + id
                document.location.href = newUrl
            },500)
        }
        if(url != null){
            setTimeout(() => {
                document.location.href = url
            },100)
        }  
    }

    handleShowRegister = (event) => {
        ModalsActions.showModal('register');
    }

    handleClose = (event) => {
        ModalsActions.hideModal();
    }

    render() {
        const { message, text } = this.props;
        return (
            <div className="login-modal modal-dialog" onClick={this.handleModalClick}>
                <form className="modal-content modals-modal" onSubmit={this.handleLoginSubmit}>
                    <div className="row">
                        <button type="button" className="modals-close-button close" onClick={this.handleCloseClick}>&times;</button>
                    </div>
                    <div className="row">
                        <div className="col-xs-10 col-xs-offset-1">
                            <h3 className="modals-title">{text && text.Autentificare}</h3>
                            {message ? <p className="modals-message">{message}</p> : null}
                            <input type="email" className="modals-input form-control" ref="email" placeholder="E-mail" required />
                            <input type="password" className="modals-input form-control" ref="password" placeholder="Password" required />
                            <div className="row modals-row">
                                <input className="modals-check" type="checkbox" /> {text && text.Remind}
                                <Link to="/data/resend/password" className="modals-link pull-right" onClick={this.handleClose}>{text && text.Parola}</Link>
                            </div>
                            {message && message.startsWith('Contul nu a fost confirmat;')
                                ? <Link to="/data/resend/account"><button type="button" className="btn btn-block modals-btn bg-orange" onClick={this.handleClose}>{text && text.Email}</button></Link>
                                : null}
                            <button type="submit" className="btn btn-block modals-btn bg-la-salle-green">{text && text.Autentificare}</button>
                            <div className="clearfix" />
                            <div className="row modals-row-bottom">
                                {text && text.Cont}<a className="modals-link" onClick={this.handleShowRegister}>{text && text.Click}</a>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default LoginModal;
