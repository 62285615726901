import Reflux from 'reflux';

import ModalsActions from '../actions/ModalsActions';

class ModalsStore extends Reflux.Store {
    constructor() {
        super();
        this.listenables = ModalsActions;
        this.state = {
            visible: false,
            modal: null,
            title: '',
            message: '',
            callback: null,
            url: null
        };
    }
    
    onShowModal(modal, message, callback, title, url) {  
        document.body.classList.add('modal-open');
        this.setState({
            visible: true,
            modal: modal,
            message: message ? message : null,
            title: title ? title : null,
            callback: callback,
            url: url ? url : null
        });
    }

    onHideModal() {
        document.body.classList.remove('modal-open');
        this.setState({
            visible: false,
            modal: null,
            message: '',
            title: '',
            callback: null,
        });
    }
}

export default ModalsStore;
