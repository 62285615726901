import { createActions } from 'reflux';
import settings from '../settings';
import axios from 'axios';
import DossiersActions from './DossiersActions';

const MonitorActions = createActions({
    monitor: { asyncResult: true, },
    unmonitor: { asyncResult: true, },
    monitorDosar: { asyncResult: true, },
    unmonitorDosar: {asyncResult: true, },
    monitorPerson: {asyncResult: true, },
    unmonitorPerson: {asyncResult: true, },
    getMonitoredPersons: {asyncResult: true, },
    getMonitoredPersonsWithBPI: {asyncResult: true, },
    searchMonitoredPerson: {asyncResult: true, },
    monitorPeopleFromFile: {asyncResult: true, },
});

MonitorActions.monitor.listen((type, id, callback) => {
    const headers = Object.assign({user_key: localStorage.getItem('DATE_FIRME_USER_KEY')}, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.MONITOR + '/' + type + '/' + id
    // app.post(
    //     settings.INTERNAL_URLS.MONITOR + '/' + type + '/' + id
    axios.get(
        url,
        {
            headers: headers, 
        }
    ).then(() => {
        if (callback) {callback();}
    }).catch((error) => {
        console.log(error);
    });
});

MonitorActions.unmonitor.listen((type, id, callback) => {
    const headers = Object.assign({user_key: localStorage.getItem('DATE_FIRME_USER_KEY')}, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.UNMONITOR + '/' + type + '/' + id
    // app.delete(
    //     settings.INTERNAL_URLS.UNMONITOR + '/' + type + '/' + id
    axios.delete(
        url,
        {
            headers: headers, 
        }
    ).then(() => {
        if (callback) {callback();}
    }).catch((error) => {
        console.log(error);
    });
});

MonitorActions.monitorDosar.listen((dosar, callback) => {
    const headers = Object.assign({user_key: localStorage.getItem('DATE_FIRME_USER_KEY')}, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.MONITOR_DOSAR
    axios.post(
        url, dosar,
        {
            headers: headers,
        }
    ).then(() => {
        DossiersActions.getMonitoredDossiers()
        if (callback) {callback();}
    }).catch((error) => {
        console.log(error);
    });
})

MonitorActions.unmonitorDosar.listen((dosar, callback) => {
    const headers = Object.assign({user_key: localStorage.getItem('DATE_FIRME_USER_KEY')}, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.UNMONITOR_DOSAR
    axios.delete(
        url,
        {
            headers: headers,
            data: dosar
        }
    ).then(() => {
        DossiersActions.getMonitoredDossiers()
        if (callback) {callback();}
    }).catch((error) => {
        console.log(error);
    });
})

MonitorActions.monitorPerson.listen((persoana) => {
    const headers = Object.assign({user_key: localStorage.getItem('DATE_FIRME_USER_KEY')}, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.MONITOR_PERSON
    axios.post(
        url, persoana,
        {
            headers: headers,
        }
    ).then(() => {
        MonitorActions.getMonitoredPersons()
        MonitorActions.getMonitoredPersonsWithBPI()
    }).catch((error) => {
        console.log(error);
    });
})

MonitorActions.unmonitorPerson.listen((persoana) => {
    const headers = Object.assign({user_key: localStorage.getItem('DATE_FIRME_USER_KEY')}, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.UNMONITOR_PERSON
    axios.delete(
        url,
        {
            headers: headers,
            data: persoana
        }
    ).then(() => {
        MonitorActions.getMonitoredPersons()
        MonitorActions.getMonitoredPersonsWithBPI()
    }).catch((error) => {
        console.log(error);
    });
})

MonitorActions.getMonitoredPersons.listen(() => {
    const headers = Object.assign({user_key: localStorage.getItem('DATE_FIRME_USER_KEY')}, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_MONITORED_PERSONS
    axios.get(
        url,
        {
            headers: headers, 
        }
    ).then((response) => {
        MonitorActions.getMonitoredPersons.completed(response.data)
    }).catch((error) => {
        console.log(error);
    });
})

MonitorActions.getMonitoredPersonsWithBPI.listen(() => {
    const headers = Object.assign({user_key: localStorage.getItem('DATE_FIRME_USER_KEY')}, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_MONITORED_PERSONS_WITH_BPI
    axios.get(
        url,
        {
            headers: headers, 
        }
    ).then((response) => {
        MonitorActions.getMonitoredPersonsWithBPI.completed(response.data)
    }).catch((error) => {
        console.log(error);
    });
})

MonitorActions.searchMonitoredPerson.listen((person) => {
    const headers = Object.assign({user_key: localStorage.getItem('DATE_FIRME_USER_KEY')}, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.SHEARCH_MONITORED_PERSON
    axios.post(
        url, person,
        {
            headers: headers,
        }
    ).then((response) => {
        MonitorActions.searchMonitoredPerson.completed(response.data)
    }).catch((error) => {
        console.log(error);
    });
})

MonitorActions.monitorPeopleFromFile.listen(file => {
    const headers = Object.assign({user_key: localStorage.getItem('DATE_FIRME_USER_KEY')}, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.MONITOR_PEOPLE_FROM_FILE
    let base64Str = {'file': file}
    axios.post(
        url, base64Str,
        {
            headers: headers,
        }
    ).then((response) => {
        MonitorActions.getMonitoredPersons()
        MonitorActions.getMonitoredPersonsWithBPI()
    }).catch((error) => {
        console.log(error);
    });
})

export default MonitorActions;
