// import 'babel-polyfill';
import React from 'react';
// import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './components/App';
import './index.css';
// import { createRoot } from 'react-dom';
import { createRoot } from 'react-dom/client';
// require('dotenv').config();

//import registerServiceWorker from './registerServiceWorker'; 
//import { unregister } from './registerServiceWorker';
//unregister();

function hashLinkScroll() {
    const { hash } = window.location;
    if (hash !== '') {
        // Push onto callback queue so it runs after the DOM is updated,
        // this is required when navigating from a different page so that
        // the element is rendered on the page before trying to getElementById.
        setTimeout(() => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element && id) {
                element.scrollIntoView();
            } else {
                window.scrollTo(0, 0);
            }
        }, 0);
    }
}

// ReactDOM.render(
//     <Router>
//         <App />
//     </Router>
//     , document.getElementById('root'))   //used with old react version 16.14.0

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(<Router>
    <App />
</Router>);


// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//     ReactDOM.hydrate(
//         <Router>
//             <App />
//         </Router>,
//         rootElement);
// } else {
//     ReactDOM.render(
//         <Router>
//             <App />
//         </Router>,
//         rootElement);
// }


//registerServiceWorker();
hashLinkScroll();
