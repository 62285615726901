import React from 'react';
import Reflux from 'reflux';
import { Link } from 'react-router-dom';
import AccountPackageStore from '../../../../../../../../../stores/AccountPackageStore';
import AccountPackageActions from '../../../../../../../../../actions/AccountPackageActions';
import { splitCamelCase } from '../../../../../../../../../utils/String';
import './AccountPackages.css';
import UserStore from '../../../../../../../../../stores/UserStore';

class AccountPackages extends Reflux.Component {
    constructor(props) {
        super(props);
        this.stores = [AccountPackageStore, UserStore];
    }

    componentDidMount() {
        AccountPackageActions.load(); 
        // UserActions.getUserOptions()
    }

    renderPack(name, pack, containerClass = "status-row-sub", progressColor = "green") {
        if (pack == null) pack = { total: 0, disponibile: 0 };

        const progressClass = "status-progress-" + progressColor;
        const animationClass = "status-progress-animation status-progress-animation-" + progressColor;
        const { total, disponibile } = pack;

        return (
            <div key={name} className={containerClass}>
                <div className="row"> 
                    <span className="package-name pull-left">{splitCamelCase(name)}</span>
                    <span className="package-category pull-right">{disponibile} / {total}</span>
                </div>
                <div className={progressClass}>
                    <div className="status-progress-bar" style={{ width: total ? (disponibile / total * 100) + '%' : 0 }}>
                        <div className={animationClass} />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { pachet, userOptions } = this.state;
        const { packNames} = this.props

        let financiarDOM = null;
        let legalDOM = null;
        let administrativDOM = null;
        let cipDOM = null;
        let grmDOM = null;
        let licitatiiDOM = null
        let monitorizareDOM = null;
        let monitorizareDosareDOM = null;
        let monitorizarePersoaneDOM = null;
        let bercDOM = null;

        if (pachet) {
            if (pachet.rapoarte && pachet.rapoarte.crediteDisponibile && packNames) {
                const packages = pachet.rapoarte.crediteDisponibile;

                financiarDOM = this.renderPack(`${packNames.Financiar}`, packages.find(element => element.nume === 'Financiar'));
                legalDOM = this.renderPack(`${packNames.Legal}`, packages.find(element => element.nume === 'Legal'));
                administrativDOM = this.renderPack(`${packNames.Administrativ}`, packages.find(element => element.nume === 'Administrativ'));
                cipDOM = userOptions && !userOptions.ascundeCIP ? this.renderPack(`${packNames.CIP}`, packages.find(element => element.nume === 'CIP')) : null;
                grmDOM = this.renderPack(`${packNames.GRM}`, packages.find(element => element.nume === 'GarantiiMobiliare'));
                licitatiiDOM = userOptions && !userOptions.ascundeLicitatii ? this.renderPack(`${packNames.Licitatii}`, packages.find(element => element.nume === 'Licitatii')) : null;
                bercDOM = userOptions && !userOptions.ascundeBERC ? this.renderPack(`${packNames.BERC}`, packages.find(element => element.nume === 'BERC')) : null;
            }

            if (pachet.monitorizare && pachet.monitorizare.crediteDisponibile && packNames) {
                const monitorizare = pachet.monitorizare.crediteDisponibile.find(element => element.nume === 'Monitorizare');
                const monitorizareDosare = pachet.monitorizare.crediteDisponibile.find(element => element.nume === 'MonitorizareDosare');
                const monitorizarePersoane = pachet.monitorizare.crediteDisponibile.find(element => element.nume === 'MonitorizarePersoane');
                let count = 1
                if(userOptions && userOptions.dosareInstanta === true) count++
                if(userOptions && userOptions.persoaneBPI === true) count++
                let className = `status-row-sub leng${count}`
                monitorizareDOM = this.renderPack(`${packNames.Monitorizare}`, monitorizare, className, "orange");
                monitorizareDosareDOM = userOptions && userOptions.dosareInstanta ? this.renderPack(`${packNames.MonitorizareDosare}`, monitorizareDosare, className, "orange") : null;
                monitorizarePersoaneDOM = userOptions && userOptions.persoaneBPI ? this.renderPack(`${packNames.MonitorizarePersoane}`, monitorizarePersoane, className, "orange") : null;
            }
        }
        
        return (
            // <div {...this.props}>
            <div className="account-state-status col-md-12 col-md-offset-0 col-lg-10 col-lg-offset-1">
                {pachet
                    ?
                    <div className="row">
                        <div className="row status-row">
                            {financiarDOM}
                            {legalDOM}
                            {administrativDOM}
                            {cipDOM}
                            {grmDOM}
                            {bercDOM}
                            {licitatiiDOM}
                            {monitorizareDOM}
                            {monitorizareDosareDOM}
                            {monitorizarePersoaneDOM}
                        </div>
                        <div className="clearfix" />
                    </div>
                    :
                    <div className="text-center">
                        <Link to="/data/packages">
                            <button className="btn bg-orange">Cumpara un Pachet</button>
                        </Link>
                    </div>
                }
            </div>
        );
    }
}

export default AccountPackages;