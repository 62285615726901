import { createActions } from 'reflux';
import axios from 'axios';

import settings from '../settings';
import { notify } from 'react-notify-toast';

const FileActions = createActions({
    download: { asyncResult: true },
    downloadReport: { asyncResult: true },
});

FileActions.download.listen((url, filename, type, callback = () => undefined) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY'), language: localStorage.getItem("Language") }, settings.INTERNAL_BACKEND_CONFIG.headers);
    var loading = ""
    var error = ""
    var noFile = ""
    var language = localStorage.getItem("Language")
    if (language === "Ro" || language === null) {
        loading = "Va rugam, asteptati. Datele se incarca."
        error = "A aparut o eroare la descarcarea fisierului."
        noFile = "Date/fisier inexistent"
    }
    if (language === "En") {
       loading = "Please wait. The data is loading."
       error = "An error has occurred while downloading the file."
       noFile = "Data/file not found"
    }

    document.body.style.cursor = "progress";
    notify.show(loading, 'warning', -1);
    axios.get(
        url,
        {
            responseType: 'arraybuffer',
            headers: headers,
        }
    ).then((response) => {
        notify.hide();
        const acceptedContents = ["application/pdf", "application/vnd.ms-excel", "text/html", "application/zip", "application/xlsx", "text/csv"]
        const fType = type ? type : response.headers["content-type"]
        if(!acceptedContents.some(el => fType.includes(el))) return
        const CD = response.headers['content-disposition']
        const fName = CD && CD.substring(CD.indexOf('=') + 1).replaceAll("\"", "").trim()
        filename = filename ? filename : fName
        const blob = new Blob([response.data], { type: fType });

        if (response.status === 204) {
            notify.show(noFile, 'error', 5000);
            return;
        }

        if (typeof window.navigator.msSaveBlob === 'function') {
            // Edge doesn't use this, but I'll leave it here
            window.navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();

            setTimeout(function () {
                document.body.removeChild(link);
            }, 100);
        }

        notify.show('Succes', 'success', 5000);
        FileActions.download.completed();
    }).catch((err) => {
        notify.hide();
        FileActions.download.failed(err);
        notify.show(error, 'error', 5000);
    }).finally(() => {
        window.document.body.style.cursor = "default";
        callback();
    });
});


FileActions.downloadReport.listen((url, filename, type, callback = () => undefined, raportData) => {
    const ftype = type ? type : 'application/pdf';
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY'), language: localStorage.getItem("Language") }, settings.INTERNAL_BACKEND_CONFIG.headers);

    var loading = ""
    // var error = ""
    var noFile = ""
    var language = localStorage.getItem("Language")
    if (language === "Ro" || language === null) {
        loading = "Va rugam, asteptati. Datele se incarca."
        // error = "A aparut o eroare la descarcarea fisierului."
        noFile = "Date/fisier inexistent"
    }
    if (language === "En") {
       loading = "Please wait. The data is loading."
        // error = "An error has ocured while downloading the file."
       noFile = "Data/file not found"
    }

    document.body.style.cursor = "progress";
    notify.show(loading, 'warning', -1);
    axios.post(
        url,
        {
            raportData: raportData
        },
        {
            responseType: 'arraybuffer',
            headers: headers,
            maxContentLength: Infinity,
            maxBodyLength: Infinity
        }
    ).then((response) => {
        notify.hide();
        if(response.headers["content-type"] !== "application/pdf") return
        const CD = response.headers['content-disposition']
        const fName = CD && CD.substring(CD.indexOf('=') + 1).replaceAll("\"", "").trim()
        filename = filename ? filename : fName
        const blob = new Blob([response.data], { type: ftype });

        if (response.status === 204) {
            notify.show(noFile, 'error', 5000);
            return;
        }

        if (typeof window.navigator.msSaveBlob === 'function') {
            // Edge doesn't use this, but I'll leave it here
            window.navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();

            setTimeout(function () {
                document.body.removeChild(link);
            }, 100);
        }

        notify.show('Succes', 'success', 5000);
        FileActions.download.completed();
    }).catch((error) => {
        notify.hide();
        FileActions.download.failed(error);
        console.log(error)
        notify.show(error, 'error', 5000);
    }).finally(() => {
        window.document.body.style.cursor = "default";
        callback();
    });
});


export default FileActions;
