import Reflux from 'reflux';
import settings from '../settings';
import axios from 'axios';

const UserActions = Reflux.createActions({
    unauthenticate: {},

    login: { asyncResult: true },
    logout: { asyncResult: true },

    externalLogin: { asyncResult: true },

    createDetails: { asyncResult: true }, 
    loadDetails: { asyncResult: true },
    updateDetails: { asyncResult: true },


    loadStatus: { asyncResult: true },
    loadBasic: {},
    loadInvoicingAddresses: { asyncResult: true },
    createInvoicingAddress: { asyncResult: true },
    updateInvoicingAddress: { asyncResult: true },
    setActiveInvoicingAddress: { asyncResult: true }, 
    deleteInvoicingAddress: { asyncResult: true },
    loadInvoices: { asyncResult: true },
    loadReportsMarketing: { asyncResult: true },
    loadReportsCompanies: { asyncResult: true },
    loadReportsCompaniesOld: { asyncResult: true },
    loadAdvanced: {},

    changePassword: { asyncResult: true },
    passwordMessage: {},

    createContract: { asyncResult: true },
    getFiscalCodeData: { asyncResult: true },

    getAcquisitionsCount: { asyncResult: true },

    getUserType: {asyncResult: true},
    hasDRCAccess: {asyncResult: true},
    addDRCCommand: {asyncResult: true},
    clearDRCMessage: {},

    saveAssociateDiagram: {},

    getUserOptions: {asyncResult: true},
    emailUnsubscribe: {asyncResult: true},
    changeUser: {asyncResult: true},
    logNewUser: {asyncResult: true},
    updateReferrer: {asyncResult: true},
    sessionLogin: {asyncResult: true},
});


/**
 * Call to log the user into the app.
 */
UserActions.login.listen((username, password, callback) => {
    const headers = settings.INTERNAL_BACKEND_CONFIG.headers
    const url = settings.URL_BASE + settings.URL_PATHS_API.LOGIN
    const data = {
        email: username,
        parola: password,
    };
    axios.post( 
        url, data,
        {
            headers: headers, 
        }
    ).then(({ data }) => {
        const { user_key } = data;
        UserActions.login.completed(user_key, callback);
        UserActions.getUserOptions()
    }).catch((error) => {
        UserActions.login.failed(error.response);
    });
});

UserActions.externalLogin.listen((callback) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.EXTERNAL_LOGIN
    // app.post(
    // 	settings.INTERNAL_URLS.EXTERNAL_LOGIN
    axios.post(
        url, {},
        {
            headers: headers,
        }
    ).then((response) => {
        UserActions.externalLogin.completed(response, callback);
    }).catch((error) => {
        UserActions.externalLogin.failed(error);
    });
});


/**
 * Call to log the user out of the app.
 */
UserActions.logout.listen(() => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.LOGOUT
    // app.get(
    //     settings.INTERNAL_URLS.LOGOUT).then((response) => {
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        UserActions.logout.completed();
    }).catch((error) => {
        UserActions.logout.failed(error);
    });
});

/**
 * Call to register a user to the app.
 */
UserActions.createDetails.listen((name, company, companyCode, email, phone, password, referrerId) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.CREATE_USER_ACCOUNT
    const data = {
        nume: name,
        firma: company,
        cif_firma: companyCode,
        email: email,
        telefon: phone,
        parola: password,
        referrerId: referrerId,
    };
    // app.post(
    //     settings.INTERNAL_URLS.CREATE_USER_ACCOUNT,
    //     data,
    axios.post(
        url, data,
        {
            headers: headers, 
        }
    ).then((response) => {
        UserActions.createDetails.completed(email, password);
    }).catch((error) => {
        UserActions.createDetails.failed(error.response);
    });
});

/**
 * Call to retrieve the active user's account details.
 */
UserActions.loadDetails.listen(() => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.READ_USER_ACCOUNT
    // app.get(settings.INTERNAL_URLS.READ_USER_ACCOUNT
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        UserActions.loadDetails.completed(data);
    }).catch((error) => {
        UserActions.loadDetails.failed(error);
    });
});

/**
 * Call to modify the active user's accound details.
 */
UserActions.updateDetails.listen((data) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.UPDATE_USER_ACCOUNT
    // app.put(
    //     settings.INTERNAL_URLS.UPDATE_USER_ACCOUNT,
    //     data
    axios.put(
        url, data,
        {
            headers: headers,
        }
    ).then((response) => {
        UserActions.updateDetails.completed();
    }).catch((error) => {
        UserActions.updateDetails.failed(error);
    });
});

/**
 * Call to retrieve the active user's package status.
 */
UserActions.loadStatus.listen(() => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.READ_USER_ACCOUNT_STATUS
    // app.get(settings.INTERNAL_URLS.READ_USER_ACCOUNT_STATUS
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        UserActions.loadStatus.completed(data);
    }).catch((error) => {
        UserActions.loadStatus.failed(error);
    });
});


/**
 * Call to retrieve the active user's package status.
 */
UserActions.getAcquisitionsCount.listen(() => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.READ_USER_ACQUISTIONS_COUNT
    // app.get(settings.INTERNAL_URLS.READ_USER_ACQUISTIONS_COUNT
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        UserActions.getAcquisitionsCount.completed(data);
    }).catch((error) => {
        UserActions.getAcquisitionsCount.failed(error);
    });
});

/**
 * Call to retrieve the active user's saved addresses for invoicing.
 */
UserActions.loadInvoicingAddresses.listen((startDate, endDate) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.READ_USER_ACCOUNT_INVOICING_ADDRESSES
    // app.get(settings.INTERNAL_URLS.READ_USER_ACCOUNT_INVOICING_ADDRESSES
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        UserActions.loadInvoicingAddresses.completed(data);
    }).catch((error) => {
        UserActions.loadInvoicingAddresses.failed(error);
    });
});

/**
 * Call to create a new invoicing address for the active user.
 */
UserActions.createInvoicingAddress.listen((data) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.CREATE_USER_ACCOUNT_INVOICING_ADDRESS
    // app.post(
    //     settings.INTERNAL_URLS.CREATE_USER_ACCOUNT_INVOICING_ADDRESS,
    //     data
    axios.post(
        url, data,
        {
            headers: headers,
        }
    ).then((response) => {
        UserActions.createInvoicingAddress.completed();
    }).catch((error) => {
        UserActions.createInvoicingAddress.failed(error);
    });
});

/**
 * Call to update an invoicing address of the active user by its id.
 */
UserActions.updateInvoicingAddress.listen((addressId, data) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.UPDATE_USER_ACCOUNT_INVOICING_ADDRESS + '/' + addressId
    // app.put(
    //     settings.INTERNAL_URLS.UPDATE_USER_ACCOUNT_INVOICING_ADDRESS + '/' + addressId,
    //     data
    axios.put(
        url, data,
        {
            headers: headers,
        }
    ).then((response) => {
        UserActions.updateInvoicingAddress.completed();
    }).catch((error) => {
        UserActions.updateInvoicingAddress.failed(error);
    });
});

/**
 * Call to update an invoicing address of the active user by its id.
 */
UserActions.setActiveInvoicingAddress.listen((addressId) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.SET_ACTIVE_USER_ACCOUNT_INVOICING_ADDRESS + '/' + addressId + '/activeaza'
    // app.put(
    //     settings.INTERNAL_URLS.SET_ACTIVE_USER_ACCOUNT_INVOICING_ADDRESS + '/' + addressId,
    //     {}
    axios.put(
        url, {},
        {
            headers: headers,
        }
    ).then((response) => {
        UserActions.setActiveInvoicingAddress.completed();
    }).catch((error) => {
        UserActions.setActiveInvoicingAddress.failed(error);
    });
});

/**
 * Call to delete an invoicing address of the active user by its id.
 */
UserActions.deleteInvoicingAddress.listen((addressId) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.DELETE_USER_ACCOUNT_INVOICING_ADDRESS + '/' + addressId
    // app.delete(
    //     settings.INTERNAL_URLS.DELETE_USER_ACCOUNT_INVOICING_ADDRESS + '/' + addressId
    axios.delete(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        UserActions.deleteInvoicingAddress.completed();
    }).catch((error) => {
        UserActions.deleteInvoicingAddress.failed(error);
    });
});

/**
 * Call to retrieve the active user's issued invoices.
 */
UserActions.loadInvoices.listen((startDate, endDate) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.READ_USER_ACCOUNT_INVOICES
        + '?dataInceput=' + (startDate ? startDate.format('YYYY-MM-DD') : '')
        + '&dataSfarsit=' + (endDate ? endDate.format('YYYY-MM-DD') : '')
    // app.get(
    //     settings.INTERNAL_URLS.READ_USER_ACCOUNT_INVOICES
    //     + '?startDate=' + (startDate ? startDate.format('YYYY-MM-DD') : '')
    //     + '&endDate=' + (endDate ? endDate.format('YYYY-MM-DD') : '')
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        UserActions.loadInvoices.completed(data);
    }).catch((error) => {
        UserActions.loadInvoices.failed(error);
    });
});

/**
 * Call to retrieve the active user's generated reports.
 */
UserActions.loadReportsMarketing.listen((startDate, endDate) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.READ_USER_ACCOUNT_REPORTS_MARKETING
        + '?dataInceput=' + (startDate ? startDate.format('YYYY-MM-DD') : '')
        + '&dataSfarsit=' + (endDate ? endDate.format('YYYY-MM-DD') : '')
    // app.get(
    //     settings.INTERNAL_URLS.READ_USER_ACCOUNT_REPORTS_MARKETING
    //     + '?startDate=' + (startDate ? startDate.format('YYYY-MM-DD') : '')
    //     + '&endDate=' + (endDate ? endDate.format('YYYY-MM-DD') : '')
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        UserActions.loadReportsMarketing.completed(data);
    }).catch((error) => {
        UserActions.loadReportsMarketing.failed(error);
    });
});

/**
 * Call to retrieve the active user's generated reports.
 */
UserActions.loadReportsCompanies.listen((startDate, endDate, denumireFirma, type) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.READ_USER_ACCOUNT_REPORTS_COMPANIES
        // + '?dataInceput=' + (startDate ? startDate.format('YYYY-MM-DD') : '')
        // + '&dataSfarsit=' + (endDate ? endDate.format('YYYY-MM-DD') : '')
        + '?dataInceput=' + startDate
        + '&dataSfarsit=' + endDate
        + '&denumireFirma=' + (denumireFirma ? denumireFirma : '')
        + '&types=' + (type ? JSON.stringify(type) : '')
    // app.get(
    //     settings.INTERNAL_URLS.READ_USER_ACCOUNT_REPORTS_COMPANIES
    //     + '?startDate=' + (startDate ? startDate.format('YYYY-MM-DD') : '')
    //     + '&endDate=' + (endDate ? endDate.format('YYYY-MM-DD') : '')
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        UserActions.loadReportsCompanies.completed(data);
    }).catch((error) => {
        UserActions.loadReportsCompanies.failed(error);
    });
});

UserActions.loadReportsCompaniesOld.listen(() => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.READ_USER_ACCOUNT_REPORTS_COMPANIES_OLD
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        UserActions.loadReportsCompaniesOld.completed(data);
    }).catch((error) => {
        UserActions.loadReportsCompaniesOld.failed(error);
    });
});

/**
 * Call to change the active user's password.
 */
UserActions.changePassword.listen((data) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.CHANGE_PASSWORD
    // app.put(
    //     settings.INTERNAL_URLS.CHANGE_PASSWORD,
    //     data
    axios.put(
        url, data,
        {
            headers: headers,
        }
    ).then((response) => {
        UserActions.changePassword.completed(data); 
    }).catch((error) => {
        UserActions.changePassword.failed(error.response);
    });
});

/**
 * Create a contract for the user.
 */
UserActions.createContract.listen((data) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.CREATE_USER_CONTRACT
    // app.post(
    //     settings.INTERNAL_URLS.CREATE_USER_CONTRACT,
    //     data
    axios.post(
        url, data,
        {
            headers: headers,
        }
    ).then((response) => {
        UserActions.createContract.completed();
    }).catch((error) => {
        UserActions.createContract.failed(error);
    });
});

UserActions.getFiscalCodeData.listen((code) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.READ_FISCAL_CODE_DATA + `?codFiscal=${code}`
    // app.get(settings.INTERNAL_URLS.READ_FISCAL_CODE_DATA + `?fiscalCode=${code}`
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        UserActions.getFiscalCodeData.completed(data);
    }).catch((error) => {
        UserActions.getFiscalCodeData.failed(error);
    });
});

UserActions.getUserType.listen(() => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_USER_TYPE
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const {data} = response
        UserActions.getUserType.completed(data)
    }).catch((error) => {
        UserActions.getUserType.failed(error)
    })
})

UserActions.hasDRCAccess.listen(() => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.HAS_DRC_ACCESS
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const {data} = response
        UserActions.hasDRCAccess.completed(data)
    }).catch((error) => {
        UserActions.hasDRCAccess.failed(error)
    })
})

UserActions.addDRCCommand.listen((codFiscal, callback) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.COMANDA_DRC_DATE_NOI
        + '/' + codFiscal
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const {data} = response
        UserActions.addDRCCommand.completed(data)
    }).catch((error) => {
        UserActions.addDRCCommand.failed(error)
    }).finally(() => {
        window.document.body.style.cursor = "default";
        callback()
    })
})

UserActions.getUserOptions.listen(() => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_USER_OPTIONS 
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const {data} = response
        UserActions.getUserOptions.completed(data)
    }).catch((error) => {
        UserActions.getUserOptions.failed(error)
    })
})

UserActions.emailUnsubscribe.listen((email) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.EMAIL_UNSUBSCRIBE + '/' + email
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const {data} = response
        UserActions.emailUnsubscribe.completed(data)
    }).catch((error) => {
        UserActions.emailUnsubscribe.failed(error)
    })
})

UserActions.changeUser.listen(email => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.CHANGE_USER + '/' + btoa(email)
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const {data} = response
        UserActions.changeUser.completed(data.user_key)
    }).catch((error) => {
        UserActions.changeUser.failed(error.response.data)
    })
})

UserActions.logNewUser.listen(email => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.LOG_NEW_USER + '/' + btoa(email)
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const {data} = response
        UserActions.logNewUser.completed(data.user_key)
    }).catch((error) => {
        UserActions.logNewUser.failed(error.response.data)
    })
})

UserActions.updateReferrer.listen(referrerId => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.UPDATE_REFERRER + '/' + referrerId
    axios.put(
        url,{},
        {
            headers: headers,
        }
    ).then(() => {
        UserActions.updateReferrer.completed()
    })
})

UserActions.sessionLogin.listen(sessionId => {
    const headers = settings.INTERNAL_BACKEND_CONFIG.headers;
    const url = settings.URL_BASE + settings.URL_PATHS_API.SESSION_LOGIN + '/' + sessionId
    axios.post(
        url,{},
        {
            headers: headers,
        }
    ).then((response) => {
        const {data} = response
        UserActions.sessionLogin.completed(data.user_key)
    })
})


export default UserActions;