import React from 'react';
import Reflux from 'reflux';

import MonitoringControl from './MonitoringControl/MonitoringControl';
import CompanyPortfolioControl from './CompanyPortfolioControl/CompanyPortfolioControl';
import AssociatePortfolioControl from './AssociatePortfolioControl/AssociateProtfolioControl';
// import MessagingControl from './MessagingControl/MessagingControl';
import ComplexInterrogationControl from './ComplexInterrogationControl/ComplexInterrogationControl';
// import MarketingControl from './MarketingControl/MarketingControl';
import AccountControl from './AccountControl/AccountControl';
import TutorialControl from './TutorialControl/TutorialControl';

import AccountStatusActions from '../../../../actions/AccountStatusActions';

import AccountStatusStore from '../../../../stores/AccountStatusStore';
import NotificationsStore from '../../../../stores/NotificationsStore';
import UserStore from '../../../../stores/UserStore';

import './Controls.css';
import DosareInstantaControl from './DosareInstantaControl/DosareInstantaControl';
import BPIPersoane from './PersonMonitoring/PersonMonitoring';
import IstoricRapoarteControl from './IstoricRapoarteControl/IstoricRapoarteControl';

class Controls extends Reflux.Component {
    constructor() {
        super();
        this.stores = [UserStore, AccountStatusStore, NotificationsStore];
    }

    componentDidMount() {
        var language = localStorage.getItem("Language")
        if(language === "Ro" || language === null){
			import('../../../Languages/language_ro').then(lang => {
				this.setState({language: lang.default.Language})
			})
		}
		if(language === "En"){
			import('../../../Languages/language_en').then(lang => {
				this.setState({language: lang.default.Language})
			})
		}
        const { authenticated } = this.state;
        if (authenticated) {
            // UserActions.getUserOptions()
            AccountStatusActions.load();
        }
    }

    render() {
        const { status, /*messaging,*/ monitoring, language, userOptions } = this.state;

        // there is some logic down the line that actually checks specifically for null, so i'll leave it like this
        let monitorizare = null;
        let portofoliuCompanii = null;
        let portofoliuAsociati = null;

        if (status) {
            monitorizare = status.monitorizare;

            if (status.portofoliu) {
                portofoliuCompanii = status.portofoliu.companii;
                portofoliuAsociati = status.portofoliu.asociati;
            }
        }

        return (
            <div className="controls row">
                <div className="col-md-8 col-lg-12 col-md-offset-2 col-lg-offset-0 flexDisplay">
                    <MonitoringControl monitoring={monitorizare} notification={monitoring} text={language && language.Controls.Monitorizare}
                        firmaSg={language && language.Controls.FirmaSg} firmaPl={language && language.Controls.FirmaPl}
                        dosareSg={language && language.Controls.DosareSg} dosarePl={language && language.Controls.DosarePl}
                    />
                    <CompanyPortfolioControl portfolio={portofoliuCompanii} text={language && language.Controls.Companii}
                        companieSg={language && language.Controls.CompanieSg} companiePl={language && language.Controls.CompaniePl}/>
                    <AssociatePortfolioControl portfolio={portofoliuAsociati} text={language && language.Controls.Asociati}
                         asociatiSg={language && language.Controls.AsociatSg} asociatiPl={language && language.Controls.AsociatPl}/>
                    {/* <MessagingControl notification={messaging} /> */}
                    <ComplexInterrogationControl text={language && language.Controls.Interogari} subtitle={language && language.Controls.Multiple}/>
                    {userOptions && userOptions.dosareInstanta && <DosareInstantaControl monitoring={monitorizare} text={language && language.Controls}/>} 
                    {userOptions && userOptions.persoaneBPI && <BPIPersoane monitoring={monitorizare} text={language && language.Controls}/>}
                    {/* <MarketingControl /> */}
                    <AccountControl text={language && language.Controls.Cont} subtitle={language && language.Controls.Personalizare}/>                   
                    <TutorialControl text={language && language.Controls.Tutorial} subtitle={language && language.Controls.Faq}/>
                    <IstoricRapoarteControl />
                </div>
            </div>
        );
    }
}

export default Controls;