import { Store } from 'reflux';

import StatisticsActions from '../actions/StatisticsActions';

class StatisticsStore extends Store {
    constructor() {
        super();
        this.listenables = StatisticsActions;
        this.state = {
            data: null,
        };
    }
    
    onLoadCompleted(data) {
        this.setState({
            data: data,
        });
    }
}

export default StatisticsStore;
