import React, {useEffect, useState} from 'react'
import roFlag from './Flags/romaniaRound.png'
import ukFlag from './Flags/ukround.png'
import ImageDropdown from '../../../Reusable/ImageDropdown/ImageDropdown';
import './LanguageSelector.css'

const LanguageSelector = () => {
    const [option, setOption] = useState(null)
    const options = [{src: roFlag, text: 'Ro'}, {src: ukFlag, text: 'En'}]

    useEffect(() => {
        var language = localStorage.getItem("Language")
        switch(language){
            case "Ro":
                setOption({src: roFlag, text: 'Ro'})
                break;
            case "En":
                setOption({src: ukFlag, text: 'En'})
                break;
            default:
                setOption({src: roFlag, text: 'Ro'})
                break;
        }
    }, [])

    const changeLanguageHandler = (option) => {
        localStorage.setItem("Language", option.text)
        setOption(option)
        window.location.reload()
    }

    return (
        <div className='languageSelector'>
            <ImageDropdown options={options} selectedOption={option} placeholder={roFlag} handleOptionChange={(option) => changeLanguageHandler(option)} /> 
        </div>
    )
}

export default LanguageSelector