import { createActions } from 'reflux';

import settings from '../settings';
const NotificationsActions = createActions({
    load: {},
    stopLoad: {},
    loadMessaging: { asyncResult: true },
    loadMonitoring: { asyncResult: true },
});

NotificationsActions.loadMessaging.listen(() => {
    const userKey = localStorage.getItem(settings.LOCAL_STORAGE.USER_KEY);
    if (userKey) {
        NotificationsActions.loadMessaging.completed({ noutati: null });
        // app.get(
        //     settings.INTERNAL_URLS.READ_MESSAGING_NOTIFICATIONS
        // ).then((response) => {
        //     const { data } = response;
        //     NotificationsActions.loadMessaging.completed(data);
        // }).catch((error) => {
        //     NotificationsActions.loadMessaging.failed(error);
        // });
    }
});

NotificationsActions.loadMonitoring.listen(() => {
    const userKey = localStorage.getItem(settings.LOCAL_STORAGE.USER_KEY);
    if (userKey) {
        NotificationsActions.loadMonitoring.completed({ noutati: null });
        // app.get(
        //     settings.INTERNAL_URLS.READ_MONITORING_NOTIFICATIONS,
        // ).then((response) => {
        //     const { data } = response;
        //     NotificationsActions.loadMonitoring.completed(data);
        // }).catch((error) => {
        //     NotificationsActions.loadMonitoring.failed(error);
        // });
    }
});

export default NotificationsActions;