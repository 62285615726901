import { createActions } from 'reflux';
import settings from '../settings';
import axios from 'axios';

const DossiersActions = createActions({
    // load: { asyncResult: true },
    getDossiers: {asyncResult: true},
    getMonitoredDossiers: {asyncResult: true},
    searchDossier: {asyncResult: true},
    clear: {},
});

DossiersActions.getDossiers.listen((nrDosar, parte) => {
    const headers = Object.assign({user_key: localStorage.getItem('DATE_FIRME_USER_KEY')}, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_DOSSIERS + '/' + nrDosar + '/' + parte
    axios.get(
        url,
        {
            headers: headers, 
        }
    ).then((response) => {
        DossiersActions.getDossiers.completed(response.data)
    }).catch((error) => {
        DossiersActions.getDossiers.failed()
    });
})

DossiersActions.getMonitoredDossiers.listen(() => {
    const headers = Object.assign({user_key: localStorage.getItem('DATE_FIRME_USER_KEY')}, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.GET_MONITORED_DOSSIERS
    axios.get(
        url,
        {
            headers: headers, 
        }
    ).then((response) => {
        DossiersActions.getMonitoredDossiers.completed(response.data)
    }).catch((error) => {
        console.log(error);
    });
})

DossiersActions.searchDossier.listen((nrDosar, parte) => {
    const headers = Object.assign({user_key: localStorage.getItem('DATE_FIRME_USER_KEY')}, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.SEARCH_MONITORED_DOSSIER + '/' + nrDosar + '/' + parte
    axios.get(
        url,
        {
            headers: headers, 
        }
    ).then((response) => {
        DossiersActions.searchDossier.completed(response.data)
    }).catch((error) => {
        console.log(error);
    });
})

export default DossiersActions;
