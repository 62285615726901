import React from 'react';
import Reflux from 'reflux';
import { Link } from 'react-router-dom';
import CrumbStore from '../../../../stores/CrumbStore';
import SearchBar from "../../Home/Search/SearchBar/SearchBar";
import { Helmet } from "react-helmet";

import './Crumb.css';

class Crumb extends Reflux.Component {
    constructor() {
        super();
        this.store = CrumbStore;
    }

    componentDidMount() {
        var language = localStorage.getItem("Language")
        if (language === "Ro" || language === null) {
            import('../../../Languages/language_ro').then(lang => {
                this.setState({ language: lang.default.Language })
            })
        }
        if (language === "En") {
            import('../../../Languages/language_en').then(lang => {
                this.setState({ language: lang.default.Language })
            })
        }
    }

    componentWillUnmount() {
        document.title = 'Date Firme - Informatii despre companii, administratori si dosare legale';
        super.componentWillUnmount();
    }

    render() {
        const { list, detail, language } = this.state;
        const { history } = this.props;

        const name = detail ? detail.name : list ? list.name : 'Informatii despre firme';

        document.title = `Date Firme - ${name}`;
        var canonical = '/';
        if (list && list.path && list.path.split('/')[2].substring(0, 8)) {
            canonical = '/data/messages';
        } else if (list && list.path && list.path.split('/')[4].split('?')[0] === 'search') {
            canonical = list.path.split('search')[0] + 'search';

        } else if (detail) {
            if (detail.path === '/data/packages') {
                canonical = '/data/packages';
            } else if (detail.path.substring(0, 30) === '/data/list/companies/marketing') {
                canonical = '/data/list/companies/marketing';
            }
        } else {
            canonical = '/';
        }

        return (
            <div className="crumb row">
                <Helmet>
                    <title>Date Firme - {name}</title>
                    <meta
                        name="description"
                        content={`Date firme: Acceseaza rapid informatii despre firme si dosare din Romania. Date Firme - ${name}`}
                    />
                    <link rel="canonical" href={canonical} />
                    <meta charSet="utf-8" />
                    <meta
                        name="keywords"
                        content={`Date, Firme, ${name}`}
                    />
                </Helmet>
                <ul className="crumb-list breadcrumb">
                    <li><Link to="/"><span className="crumb-home" /> </Link></li>
                    {list ? <li><Link to={list.path}>{list.name} </Link></li> : null}
                    {detail ? <li><Link to={detail.path}>{detail.name}</Link></li> : null}
                </ul>
                <div className="crumb-search">
                    <SearchBar history={history} placeholder={language && language.Search.Placeholder} buttonTxt={language && language.Search.Button} />
                </div>
            </div>
        );
    }
}

export default Crumb;