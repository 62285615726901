import React, { useState } from 'react'
import './ImageDropdown.css'

const ImageDropdown = ({ options, selectedOption, handleOptionChange, placeholder, styleCss }) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <div className={`imageDropdown ${isVisible ? 'visible' : ''} ${styleCss}`}>
      <div className="imageDropdown-header" onClick={() => setIsVisible(!isVisible)}>
        <img src={(selectedOption && selectedOption.src) || placeholder} alt='flagImg' />
        {/* <img src={downArrow} alt='dropdown'/> */}
      </div>
      <ul className="imageDropdown-options">
        {options.map((option, ix) => (
          <li key={ix} onClick={() => { handleOptionChange(option); setIsVisible(!isVisible) }} className={option === selectedOption ? 'selected' : ''}>
            <span>
              {/* {option.text} */}
              <img src={option.src} alt='flagImg' />
            </span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ImageDropdown