import React from 'react'
import './SimpleButton.css'

const SimpleButton = ({text, onClick, styleCss}) => {
  return (
    <button onClick={onClick} className={`simpleButton ${styleCss}`}>
        {text}
    </button>
  )
}

export default SimpleButton