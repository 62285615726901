import React from 'react'
import ModalsActions from '../../../actions/ModalsActions'
import './DosarInstantaModal.css'

const DosarInstantaModal = props => {
    const {dossier} = props

    const stopPropagation = e => e.stopPropagation();
    const closeModal = () => ModalsActions.hideModal()

    return (
        <div className="modal-dialog jst-modal" onClick={stopPropagation}>
            <div className="modal-content modals-modal">
                <div className="row">
                    <button type="button" className="modals-close-button close" onClick={closeModal}>&times;</button>
                </div>
                <div className="row">
                    <div className="col-xs-10 col-xs-offset-1">
                        <h1><u>{dossier.instanta}</u></h1>
                        <h3><b>Numar unic dosar:</b> {dossier.numar}</h3>
                        <h3><b>Inregistrat in data de:</b> {dossier.data.substring(0,10)}</h3>
                        <h3><b>Obiect dosar:</b> {dossier.obiect}</h3>
                        <h3><b>Materie juridica:</b> {dossier.materie}</h3>
                        <h3><b>Stadiu procesual:</b> {dossier.stadiu}</h3>
                        <h3><b>Parti:</b></h3>
                        <div>
                            {dossier.parti.map(x => <h4 style={x.Nume === dossier.nume ? {color: 'red'} : {}}>{x.Nume}: {x.Rol};</h4>)}
                        </div>
                        <h3><b>Termene de judecata:</b></h3>
                        <div>
                            {dossier.termene.map(x => <h4>-{x.DataTermen.substring(0,10)}: {x.Detalii}: {x.Solutie}</h4>)}
                        </div>
                    </div>
                </div>
                <div className="clearfix" />
                <br />
            </div>
        </div>
    )
}

export default DosarInstantaModal