import React from 'react';
import Reflux from 'reflux';
import { stringify } from 'qs';

import SearchStore from '../../../../../stores/SearchStore';
import SearchActions from '../../../../../actions/SearchActions';

import './SearchBar.css';

class SearchBar extends Reflux.Component {
    constructor(props) {
        super(props);
        this.state = { query: '' };
        this.store = SearchStore;
    }

    handleSearchFormSubmit = event => {
        event.preventDefault();

        const { history } = this.props;
        const { query } = this.state;

	    if (query) {
            var terms = query.toString().trim();
            terms = terms.replace(" ", '%')
            var termsUpper = terms.toUpperCase()
            if(termsUpper.includes("S.R.L")){
                terms = termsUpper.replace("S.R.L", "SRL")
            }else if(termsUpper.includes("S.A")){
                terms = termsUpper.replace("S.A", "SA")
            }
            
            if (terms && terms.length > 0) {
	            const params = {
	                terms: terms,
	                page: 1,
	            };
                // if(isNaN(terms)){
                    SearchActions.setFilters(terms); 
                // }else{
                    // SearchActions.setFilters('');
                // }	        
	            history.push('/data/list/companies/search?' + stringify(params));
	        }
        }

	    return false;
	}

    handleChange = event => {
        this.setState({ query: event.target.value });
    }

    render () {
        const { query } = this.state;
        const {placeholder, buttonTxt} = this.props

        return (
            <div className="search-inputs col-lg-6 col-md-6 col-sm-8 col-xs-12 col-lg-offset-3 col-md-offset-3 col-sm-offset-2">
                <form className="input-group" onSubmit={this.handleSearchFormSubmit}>
                    <input className="search-text form-control"
                        value={query}
                        onChange={this.handleChange}
                        type="text"
                        placeholder={placeholder}
                        required
                    />
                    <span className="input-group-btn">
                        <button className="btn-search bg-la-salle-green btn searchButton" type="submit" alt="cautare firme">{buttonTxt}</button>
                    </span>
                </form>
            </div>
        )
    }
}

export default SearchBar;