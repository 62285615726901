import React from 'react'
import { Link } from 'react-router-dom';
import './IstoricRapoarteControl.css'

const IstoricRapoarteControl = () => {
  return (
    <div className="istoric-control control col-xs-12 col-sm-4 col-lg-2 col-xs-offset-0 col-sm-offset-4 col-lg-offset-0">
        <Link to="/data/details/account#account-reports-old">
            <div className="control-inner">
                <div className="control-content">
                    <div className="control-graphic istoric-control-graphic" />
                    <div className="control-text">
                        <h4>Istoric Rapoarte</h4>
                        <p>Site Vechi</p>
                    </div>
                </div>
            </div>
        </Link>
    </div>
  )
}

export default IstoricRapoarteControl
