import { Store } from 'reflux';
import SearchActions from '../actions/SearchActions';
import UtilActions from '../actions/UtilActions';
import ListSuggestionsActions from '../actions/ListSuggestionsActions';

class SearchStore extends Store {
    constructor() {
        super();
        this.lastFilters = {};
        this.listenables = [ SearchActions, UtilActions ];
        this.state = {
            terms: '',
            companies: {
                filters: {
                    page: 1,
                },
                loading: false,
                data: null,
            },
            results: {},
            resultsA: {},
            associates: {
                filters: {
                    page: 1,
                },
                loading: false,
                data: null,
            },
            fiscalCodeSearch: {
                companies: null,
                error: null
            },
            multipleInterrogate: {
                error: null,
                loading: false,
                data: null
            },

            loadingSuggestions: 0,
            suggestions: [],
        };
    }

    clearCompanies() {
        this.setState({
            companies: {
                filters: {
                    page: 1,
                },
                loading: false,
                data: null,
            },
            results: {},
        });
    }

    clearAssociates() {
        this.setState({
            resultsA: {},
            associates: {
                filters: {
                    page: 1,
                },
                loading: false,
                data: null,
            },
        });
    }

    clearData() {
        this.setState({
            companies: {
                filters: {
                    page: 1,
                },
                loading: false,
                data: null,
            },
            results: {},
            resultsA: {},
            associates: {
                filters: {
                    page: 1,
                },
                loading: false,
                data: null,
            },

            loadingSuggestions: 0,
            suggestions: [],
        });
    }

    // COMPANIES
    onFilterCompanies(newFilters) {
        const { filters } = this.state.companies;

        if (JSON.stringify(filters) !== JSON.stringify(newFilters)) {
            if (!newFilters.page) {
                newFilters.page = 1;
            }
            SearchActions.loadCompanies(newFilters);
        }
    }

    onRefreshCompanies() {
        const { filters } = this.state.companies;

        if (filters.page) {
            SearchActions.loadCompanies(filters);
        }
    }

    onLoadCompanies(filters) {
        const { companies } = this.state;

        if (!filters.terms || !filters.terms.length) {
            return false;
        }

        if (JSON.stringify(companies.filters) !== JSON.stringify(filters)) {
            companies.filters = filters;
        } else {
            return false;
        }

        companies.loading = true;
        this.setState({
            companies: companies,
        });
    }

    onLoadCompaniesCompleted(data, filter) {
        const { companies, associates } = this.state;

        if((associates.data === null || associates.data.persoane.length === 0) && (data.firme.length === 1)){
            if(localStorage.getItem('DATE_FIRME_USER_KEY') != null){
                window.location = '/data/details/company/' + data.firme[0].id 
            }else{
                window.location = '/firma/' + data.firme[0].codFiscal + '/' + data.firme[0].denumire.replaceAll(" ", "-")
            }
            return
        }

        companies.data = data;
        companies.filters = filter;
        companies.loading = false;
        if (data && data.firme && data.firme.length) {
            ListSuggestionsActions.load(data.firme[0]);
        }

        const rezJudete = {};
        companies.data.sumar.judete.forEach((judet) => {
            rezJudete[judet.id]=judet.numarEntitati;
        });
        const rezIndustrii = {}; 
        companies.data.sumar.industrii.forEach((categorie) => {
            categorie.subcategorie.forEach((ind) => {
                rezIndustrii[ind.id]=ind.numarEntitati;
            });
        });

        this.setState({
            companies: companies,
            results: {
                industries: rezIndustrii,
                counties: rezJudete
            }
        });
    }

    onLoadCompaniesFailed() {
        const { companies } = this.state;
        companies.loading = false;
        this.setState({ 
            companies: companies,
        });
    }

    onLoadCompaniesByFiscalCode() {
        this.setState({
            fiscalCodeSearch: {
                companies: null,
                error: null
            }
        });
    }

    onLoadCompaniesByFiscalCodeCompleted(data) {
        this.setState({
            fiscalCodeSearch: {
                companies: data,
                error: null
            }
        });
    }

    onLoadCompaniesByFiscalCodeFailed(error) {
        this.setState({
            fiscalCodeSearch: {
                companies: null,
                error: error && error.data ? error.data : null
            }
        });
    }

    onIssueMultipleRaport() {
        this.setState({
            multipleInterrogate: {
                reports: null,
                loading: true,
                error: null
            }
        })
    }

    onIssueMultipleRaportCompleted(){
        this.setState({
            multipleInterrogate: {
                reports: null,
                loading: true,
                error: null
            }
        })
    }

    onIssueMultipleRaportFailed(error){
        this.setState({
            multipleInterrogate: {
                reports: null,
                loading: false,
                error: error
            }
        })
    }

    onLoadSuggestions() {
        this.setState({
            loadingSuggestions: this.state.loadingSuggestions + 1,
        });
    }

    onLoadSuggestionsCompleted(data) {
        this.setState({
            suggestions: data,
            loadingSuggestions: this.state.loadingSuggestions - 1,
        });
    }

    onLoadSuggestionsFailed() {
        this.setState({
            suggestions: [],
            loadingSuggestions: this.state.loadingSuggestions - 1
        });
    }

    onClearSuggestions() {
        this.setState({
            loadingSuggestions: 0,
            suggestions: [],
        })
    }

    // ASSOCIATES
    onFilterAssociates(newFilters) {
        const { filters } = this.state.associates;

        if (JSON.stringify(filters) !== JSON.stringify(newFilters)) {
            if (!newFilters.page) {
                newFilters.page = 1;
            }
            SearchActions.loadAssociates(newFilters);
        }
    }

    onLoadAssociates = filters => {
        if (!filters.terms || !filters.terms.length) {
            return false;
        }

        const { associates } = this.state;
        associates.filters = filters; 
        associates.loading = true;
        this.setState({
            associates: associates,
        });
    }

    onLoadAssociatesCompleted(data, filter) {
        const { associates } = this.state;
        associates.data = data;
        associates.filters = filter;
        associates.loading = false;

        const rezJudete = {};
        associates.data.sumar.judete.forEach((judet) => {
            rezJudete[judet.id]=judet.numarEntitati;
        });

        this.setState({
            associates: associates,
            resultsA: {
                counties: rezJudete
            }
        });
    }

    onResetFilters(filterType) {
        if (filterType === 'companies') {
            const { companies } = this.state;
            companies.filters = [];
            this.setState({
                companies: companies,
            });
        } else {
            const { associates } = this.state;
            associates.filters = [];
            this.setState({
                associates: associates,
            });
        }
    }

    onLoadAssociatesFailed() {
        const { associates } = this.state;
        associates.loading = false;
        associates.data = null
        this.setState({
            associates: associates,
            //associates: []
        });
    }

    onSetFilters(filter) {
        SearchActions.loadAssociates({terms: filter});
    }

    onClearData = filter => {
        if (filter === 'companies') {
            this.clearCompanies();
        } else if (filter === 'associates') {
            this.clearAssociates();
        } else {
            this.clearData(); 
            SearchActions.loadAssociates({terms: filter});
        }
    }

    onSet() {
        SearchActions.refreshCompanies();
    }

    onReset() {
        SearchActions.refreshCompanies();
    }
}

export default SearchStore;
