import { Store } from 'reflux';

import CrumbActions from '../actions/CrumbActions';

class CrumbStore extends Store {
    constructor() {
        
        super();
        this.listenables = CrumbActions;
        this.state = {
            list: null,
            detail: null,
        };
    }

    onAddList(name, path) {
        this.setState({
            list: {
                name: name,
                path: path,
            },
            detail: null,
        });
    }

    onAddDetail(name, path) {
        this.setState({
            detail: {
                name: name,
                path: path,
            },
        });
    }

    onAddStandaloneDetail(name, path) {
        this.setState({
            list: null,
            detail: {
                name: name,
                path: path,
            },
        });
    }
}

export default CrumbStore;
