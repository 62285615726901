import { Store } from 'reflux';
import AdminActions from '../actions/AdminActions'

class AdminStore extends Store {
    constructor(){
        super()
        this.listenables = AdminActions
        this.state = {
            users: null,
            usersCount: null,
            action: '',
            credite: null,
            achizitii: null,
            rapoarte: null,
            emailsThreshold: null,
            statusFin: null,
            statusCip: null,
            apiStatus: null,
            apiLoading: false,
            apiResponse: null,
        }
    }

    onSetAction() {
        this.setState({action: ''})
    }

    onGetUsersCompleted(data) {
        this.setState({users: data})
    }

    onSearchUserCompleted(data) {
        this.setState({users: data.Result, usersCount: data.Count})
    }

    onUpdateUserCompleted(data) {
        let updateIx = this.state.users.findIndex(user => user.userId === data.userId)
        let usersUpdated = this.state.users
        usersUpdated[updateIx] = data
        this.setState({users: usersUpdated})
    }

    onGetCrediteUserCompleted(data) {
        this.setState({credite: data})
    }

    onAddCrediteUserCompleted(data) {
        let creditIx = this.state.credite.findIndex(credit => credit.TipRaport === data.TipRaport)
        let creditNew = this.state.credite.find(credit => credit.TipRaport === data.TipRaport)
        creditNew.Cumparate = creditNew.Cumparate + data.NrCredite
        creditNew.Ramase = creditNew.Ramase + data.NrCredite
        let crediteNew = this.state.credite
        crediteNew[creditIx] = creditNew
        this.setState({credite: crediteNew})
    }

    onGetAchizitiiUserCompleted(data) {
        this.setState({achizitii: data})
    }

    onGetConsumUserCompleted(data) {
        this.setState({rapoarte: data})
    }

    onGetEmailsThresholdCompleted(data) {
        this.setState({emailsThreshold: data})
    }

    onGetStatusFinCompleted(data) {
        this.setState({statusFin: data})
    }

    onGetCipStatusCompleted(data) {
        this.setState({statusCip: data})
    }

    onGetApiStatusCompleted(data) {
        this.setState({apiStatus: data})
    }

    onVerifyApi() {
        this.setState({apiLoading: true})
    }

    onVerifyApiCompleted(data) {
        this.setState({apiResponse: data, apiLoading: false})
    }

    onResetApi() {
        this.setState({apiResponse: null})
    }
}

export default AdminStore