import Reflux from 'reflux';
import settings from '../settings';
import axios from 'axios';

const CompanyActions = Reflux.createActions({
    load: { asyncResult: true },
    loadFreeDetails: {asyncResult: true},
    getBilantExtins: {asyncResult: true},
    reset: {},
    waitGraph: {},
    notWaitGraph: {},
    getDocumentRadiere: {asyncResult: true},
});

CompanyActions.load.listen((companyId) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.READ_COMPANY_WITH_ID + '/' + companyId
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        //not reposition if financial rep is not interrogated
        // if (window.location.hash !== '' && data.financiar.raport.stare) {       
		// 	CompanyActions.waitGraph()
		// 	setTimeout(function () {
        //         //added '' to remove warning
		// 		window.location.href = window.location.href + ''	
		// 		CompanyActions.notWaitGraph()
		// 	}, 5000);
		// }
        CompanyActions.load.completed(data);
    }).catch((error) => {
        CompanyActions.load.failed(error);
    });
});

CompanyActions.loadFreeDetails.listen((codFiscal) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.READ_COMPANY_WITH_CUI_FREE + '/' + codFiscal
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        CompanyActions.loadFreeDetails.completed(data);
    }).catch((error) => {
        CompanyActions.loadFreeDetails.failed(error);
    });
})

// CompanyActions.getBilantExtins.listen(codFiscal => {
//     const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
//     const url = settings.URL_BASE + settings.URL_PATHS_API.GET_BILANT_EXTINS + '/' + codFiscal
//     axios.get(
//         url,
//         {
//             headers: headers,
//         }
//     ).then((response) => {
//         const { data } = response;
//         CompanyActions.getBilantExtins.completed(data);
//     }).catch((error) => {
//         CompanyActions.getBilantExtins.failed(error);
//     });
// })

// CompanyActions.getDocumentRadiere.listen(codFiscal => {
//     const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
//     const url = settings.URL_BASE + settings.URL_PATHS_API.GET_DOCUMENT_RADIERE + '/' + codFiscal
//     axios.get(
//         url,
//         {
//             headers: headers,
//         }
//     ).then((response) => {
//         const { data } = response;
//         CompanyActions.loadFreeDetails.completed(data);
//     }).catch((error) => {
//         CompanyActions.loadFreeDetails.failed(error);
//     });
// })

export default CompanyActions;
