import React from 'react';
import Reflux from 'reflux';
import CrumbActions from '../../../../../actions/CrumbActions';
import InterrogationActions from '../../../../../actions/InterrogationActions';
import SearchStore from '../../../../../stores/SearchStore';
import AccountPackageStore from '../../../../../stores//AccountPackageStore';
import InterrogationStore from '../../../../../stores/InterrogationStore';
import Sidebar from './Sidebar/Sidebar';
import Categories from './Categories/Categories';
import History from './History/History';
import InfoTooltip from '../../../../Tooltips/InfoTooltip/InfoTooltip';
import './ComplexInterrogations.css';
import UserStore from '../../../../../stores/UserStore';
import AccountPackageActions from '../../../../../actions/AccountPackageActions';

class ComplexInterrogations extends Reflux.Component {
  constructor(props) {
    super(props);
    this.stores = [SearchStore, AccountPackageStore, InterrogationStore, UserStore];
    this.state = {
      query: '',
      codes: [],
      categories: {
        administrativ: {
          name: 'Raport Administrativ',
          value: false
        },
        financiar: {
          name: 'Raport Financiar',
          value: false
        },
        legal: {
          name: 'Raport Legal',
          value: false
        },
        berc: {
          name: "Raport Buletinul Electronic al Registrului Comertului",
          value: false
        },
        incidente: {
          name: 'Raport Incidente de Plata',
          value: false
        },
        publicitate: {
          name: 'Raport Registrul Naţional de Publicitate Mobiliară',
          value: false
        },
        licitatii: {
          name: 'Raport Licitatii',
          value: false
        },
        toate: {
          name: 'Toate Optiunile',
          value: false
        },
        stare: {
          name: 'Stare',
          value: false
        }
      },
      lockInputs: false, // initial implementation: this might come from stores, not sure yet -- it might not even exist
      error: null,
      companies: null,
      levelHartaActionari: 2,
      isLoading: false,
    };
  }

  componentDidMount() {
    var language = localStorage.getItem("Language")
    var title = ""
    if (language === "Ro" || language === null) {
      title = "Interogari Speciale"
      import('../../../../Languages/language_ro').then(lang => {
        this.setState({ language: lang.default.Language, lang: "Ro" })
      })
    }
    if (language === "En") {
      title = "Special Interrogations"
      import('../../../../Languages/language_en').then(lang => {
        this.setState({ language: lang.default.Language, lang: language })
      })
    }

    const { pathname } = this.props.location;
    CrumbActions.addStandaloneDetail(title, pathname);
    InterrogationActions.loadInterrogationHistory()
    AccountPackageActions.load()
    // UserActions.getUserOptions()
  }

  componentDidUpdate() {
    const istoricInterrogations = this.state.istoricInterrogations
    if (istoricInterrogations && istoricInterrogations.length !== 0 && istoricInterrogations[0].StatusId === 0) {
      setTimeout(() => { window.location.reload(false); }, 3000)
    }
  }

  handleCheckbox = e => {
    const checkbox = e.target;
    this.setState(state => {
      const s = Object.assign({}, state)
      if(s.categories["stare"].value === true && !(checkbox.name === "stare")) return   //cannot select others while stare is selected  

      if(checkbox.name === "stare"){     //deselect all other options
        s.categories["administrativ"].value = false
        s.categories["financiar"].value = false
        s.categories["legal"].value = false
        s.categories["incidente"].value = false
        s.categories["publicitate"].value = false
        s.categories["licitatii"].value = false
        s.categories["berc"].value = false
        s.categories["toate"].value = false
      }

      if (checkbox.name === 'toate') {
        s.categories["administrativ"].value = checkbox.checked
        s.categories["financiar"].value = checkbox.checked
        s.categories["legal"].value = checkbox.checked
        s.categories["incidente"].value = !this.state.userOptions.ascundeCIP && checkbox.checked
        s.categories["publicitate"].value = checkbox.checked
        s.categories["licitatii"].value = checkbox.checked
        s.categories["berc"].value = checkbox.checked
      }

      s.categories[checkbox.name].value = checkbox.checked;   //current option
      return s;
    });
  }

  showCompanies = () => this.state.codes.length > 0;
  dismissError = () => this.setState({ error: null });
  handleQueryChange = e => this.setState({ query: e.target.value });

  filterCodesInput(array) {
    const good = {};
    const repeated = {};
    const invalid = {};

    array.forEach(str => {
      const num = Number(str);
      if ((Math.floor(Math.log10(Math.abs(num))) + 1) > 10) {

      }
      const trimmed = str.trim();

      if (trimmed.length) {
        if (Number.isInteger(num) && num > 9 && ((Math.floor(Math.log10(Math.abs(num))) + 1) < 10)) {
          if (good[num] === undefined) {
            good[num] = num;
          } else {
            repeated[trimmed] = `"${trimmed}"`;
          }
        } else {
          invalid[trimmed] = `"${trimmed}"`;
        }
      }
    });
    return [Object.values(good), Object.values(invalid), Object.values(repeated)];
  }

  hasEnoughCredits = (pachet, codes) => {
    if(this.state.categories["stare"].value){
      return ["Success", 0, true]
    }
    
    const nbCodes = codes.length
    var credite = pachet.rapoarte.crediteDisponibile
    credite = this.generateCrediteIfMissing(credite)
    let message = ""
    let success = true
    let codesNb = []
    let minOneChecked = false
    for (let i = 0; i < credite.length; i++) {
      let tip = credite[i].nume.substring(1)
      if (tip === "ERC") { tip = "Registrului Comertului" }
      if (tip === "IP") { tip = "Incidente" }
      if (tip === "arantiiMobiliare") { tip = "Publicitate" }
      for (var item in this.state.categories) {
        if (this.state.categories[item].name.includes(tip) && this.state.categories[item].value) {
          minOneChecked = true
          if (!(credite[i].disponibile >= nbCodes)) {
            message = message + this.state.language.ComplexInterrogations.Msg1 + (nbCodes - credite[i].disponibile) + this.state.language.ComplexInterrogations.Msg2 + credite[i].nume + '\n'
            success = false
            break
          }
          else {
            codesNb.push(i + 1)
            break
          }
        }
      }
    }
    if (success) {
      message = "Success"
    }
    return [message, codesNb, minOneChecked]
  }

  generateCrediteIfMissing = (credite) => {
    let crediteNew = []
    let rapoarteList = ["Administrativ", "Financiar", "Legal", "CIP", "GarantiiMobiliare", "Licitatii", "BERC"]
    for (let i = 0; i < rapoarteList.length; i++) {
      let credit = credite.find(x => x.nume === rapoarteList[i])
      if (credit != null) {
        crediteNew.push(credit)
      } else {
        crediteNew.push({ nume: rapoarteList[i], disponibile: 0, total: 0 })
      }
    }
    return crediteNew
  }

  handleSubmit = (pachet) => {
    const { query } = this.state;
    const splitQuery = query.split(',');
    const [codes, invalid, repeated] = this.filterCodesInput(splitQuery);
    const shouldQuery = query.length && codes.length > 0;

    const [enoughCreditsMsg, codesNb, minOneChecked] = this.hasEnoughCredits(pachet, codes)
    const hasEnoughCredits = (enoughCreditsMsg === "Success") ? true : false

    this.setState({
      codes: codes,
      lockInputs: false,
      error: (query.trim().length === 0)
        ? <span>{this.state.language.ComplexInterrogations.CuiMsg1}</span>
        : (!hasEnoughCredits)
          ? <span>{enoughCreditsMsg}</span>
          : !minOneChecked
            ? <span>{this.state.language.ComplexInterrogations.CuiMsg2}</span>
            : query.length && (invalid.length || repeated.length)
              ? <span>
                {invalid.length
                  ? <span>{this.state.language.ComplexInterrogations.CuiMsg3}<strong>{invalid.join(', ')}</strong>.</span>
                  : null
                } {repeated.length
                  ? <span>{this.state.language.ComplexInterrogations.CuiMsg4}<strong>{repeated.join(', ')}</strong>.</span>
                  : null
                }
              </span>
              : null
    });
    
    if (shouldQuery && hasEnoughCredits && minOneChecked) {
      const codesInfo = { codes: codes, codesNb: codesNb.toString() }
      InterrogationActions.issueMultipleRaport(codesInfo, this.state.levelHartaActionari)
      //window.location.reload(false);
      this.setState({ isLoading: true })
      setTimeout(() => { InterrogationActions.loadInterrogationHistory() }, 2000);
      //this.setState({isLoading: false})    
    }
  }

  handleDateChange = (event, period) => {
    var date = event.target.value
    if (period === "start") {
      this.setState({ startDate: date })
    } else if (period === "end") {
      this.setState({ endDate: date })
    }
  }

  handleLevelHartaChange = level => {
    this.setState({ levelHartaActionari: level })
  }

  render() {
    const { query, categories, error, fiscalCodeSearch, lockInputs, pachet, isLoading } = this.state;
    const { istoricInterrogations, loadingHistory, language, lang, userOptions } = this.state
    const errorMessage = fiscalCodeSearch.error || error;

    const doNotLock = fiscalCodeSearch.error || (fiscalCodeSearch.companies != null && fiscalCodeSearch.companies.length === 0);
    const actuallyLockInputs = doNotLock ? false : lockInputs;

    if (typeof (language) === 'undefined') return null

    // Reuses css from Details
    return (
      <div className="row complex-interrogations-wrapper">
        {/* sidebar */}
        <Sidebar currentInterrogationHandler={this.showCompanies} text={language.ComplexInterrogations} />
        {/* content */}
        <div className="details-content col-xs-12 col-sm-9 col-md-9 col-lg-10">
          <div className="complex-interrogations" id="complex-interrogarions">
            <div className="col-xs-12 details-category-title">
              <h3>{language.ComplexInterrogations.Title}</h3>
              <hr />
              <h4>{language.ComplexInterrogations.Options}</h4>
            </div>
            <Categories text={language.ComplexInterrogations} lang={lang} categories={categories} disabled={actuallyLockInputs} checkboxHandler={this.handleCheckbox} datePicker={this.handleDateChange} levelHartaChange={this.handleLevelHartaChange} hideCip={userOptions && userOptions.ascundeCIP} hideBerc={userOptions && userOptions.ascundeBERC} />
            <hr />
            <div id="complex-interrogarions-current" className="row">
              <p>
                <strong>{language.ComplexInterrogations.List}</strong>
                <InfoTooltip html={
                  <ul>
                    <li>{language.ComplexInterrogations.Info} (",").</li>
                  </ul>
                } />
              </p>
              <input
                type="text"
                className="modals-input form-control"
                value={query}
                onChange={this.handleQueryChange}
                disabled={actuallyLockInputs}
              />
              <br />
              <input
                type="button"
                className="btn btn-success"
                value={language.ComplexInterrogations.Generate}
                onClick={() => this.handleSubmit(pachet)}
                disabled={actuallyLockInputs}
              />
              <label className={isLoading ? "" : "hidden"}>  Loading...</label>
            </div>
            {errorMessage
              ? <div className="row">
                <hr />
                <div className="alert alert-danger" role="alert">
                  <button type="button" className="close" onClick={this.dismissError}>&times;</button>
                  {errorMessage}
                </div>
              </div>
              : null
            }
          </div>
          <div id="complex-interrogarions-history">
            <History istoric={istoricInterrogations} isLoading={loadingHistory} text={language.ComplexInterrogations} language={lang} hideCIP={userOptions && userOptions.ascundeCIP} hideBERC={userOptions && userOptions.ascundeBERC} />
          </div>
        </div>
      </div>
    );
  }
}

export default ComplexInterrogations;
