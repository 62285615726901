import Reflux from 'reflux';
import NotificationsActions from '../actions/NotificationsActions';
import UtilActions from '../actions/UtilActions';
// import { setTimeout } from 'timers';

class NotificationsStore extends Reflux.Store {
    constructor() {
        super();
        this.listenables = [ NotificationsActions, UtilActions ];
        this.state = {
            loading: false,
            messaging: 0,
            monitoring: 0,
        };
    }

    onReset() {
        NotificationsActions.stopLoad();
    }

    onLoad() {
        this.setState({
            loading: true,
        });
        this.timedLoad();
    }

    timedLoad = () => {
        NotificationsActions.loadMessaging();
        NotificationsActions.loadMonitoring();

        // const { loading } = this.state;
        // // if (loading) {
        // //     setTimeout(this.timedLoad, 60000);
        // // }
    }

    onStopLoad() {
        this.setState({
            loading: false,
            messaging: 0,
            monitoring: 0,
        });
    }

    onLoadMessaging() { }

    onLoadMessagingCompleted(data) {
        this.setState({
            messaging: data.noutati,
        });
    }

    onLoadMessagingFailed(error) {
        //console.log(error);
        this.setState({
            loading: false,
        });
    }

    onLoadMonitoring() { }

    onLoadMonitoringCompleted(data) {
        this.setState({
            monitoring: data.noutati,
        });
    }

    onLoadMonitoringFailed(error) {
        //console.log(error);
        this.setState({
            loading: false,
        });
    }
}

export default NotificationsStore;
