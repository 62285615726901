import Reflux from 'reflux';
import AccountStatusActions from '../actions/AccountStatusActions';
import UtilActions from '../actions/UtilActions';

class AccountStatusStore extends Reflux.Store {
    constructor() {
        super();
        this.listenables = [ AccountStatusActions, UtilActions ];
        this.state = {
            loading: false,
            status: null,
        };
    }

    onReset() {
        this.setState({
            loading: false,
            status: null,
        });
    }

    onLoad() {
        this.setState({
            loading: true,
        });
    }

    onLoadCompleted(status) {
        this.setState({
            loading: false,
            status: status.status,
        });
    }

    onLoadFailed() {
        this.setState({
            loading: false,
        });
    }
}

export default AccountStatusStore;
