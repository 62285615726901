import { createActions } from 'reflux';
import settings from '../settings';
import axios from 'axios';

const ListSuggestionsActions = createActions({
    load: {},
    loadCompanies: { asyncResult: true },
    loadAssociates: { asyncResult: true },
    reset: {}
});

ListSuggestionsActions.loadCompanies.listen((subIndustrieId) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.READ_SUGGESTED_COMPANIES_TOP_FISCAL_VALUE_CAEN
        + '?termeni='
        + '&judete='
        + '&industrii=' + subIndustrieId
        + '&order=4'
        + '&offset=0'
        + '&limit=5'
        + '&summary=False'
    // app.get(
    // settings.INTERNAL_URLS.READ_SUGGESTED_COMPANIES_TOP_FISCAL_VALUE_CAEN + '/' + subIndustrieId
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        ListSuggestionsActions.loadCompanies.completed(data);
    }).catch((error) => {
        ListSuggestionsActions.loadCompanies.failed(error);
    });
});

ListSuggestionsActions.loadAssociates.listen((companyId) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const url = settings.URL_BASE + settings.URL_PATHS_API.SEARCH_ASSOCIATES    //    .READ_SUGGESTED_ASSOCIATES
        + '?termeni='
        + '&IdCompanie=' + companyId
        + '&judete='
        + '&order=5'
        + '&offset=0'
        + '&limit=5'
        + '&summary=False'
    // app.get(
    //     settings.INTERNAL_URLS.READ_SUGGESTED_ASSOCIATES + '/' + companyId
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        ListSuggestionsActions.loadAssociates.completed(data);
    }).catch((error) => {
        ListSuggestionsActions.loadAssociates.failed(error);
    });
});

export default ListSuggestionsActions;
