import Reflux from 'reflux';

import LastViewedActions from '../actions/LastViewedActions';
import UtilActions from '../actions/UtilActions';

class LastViewedStore extends Reflux.Store {
    constructor() {
        super();
        this.listenables = [ LastViewedActions, UtilActions ];
        this.state = {
            loading: false,
            companies: [],
        };
    }

    onReset() {
        this.setState({ 
            loading: false,
            companies: [],
        });
    }

    onLoad() {
        this.setState({
            loading: true,
        });
    }

    onLoadCompleted(companies) {
        this.setState({
            loading: false,
            companies: companies,
        });
    }

    onLoadFailed() {
        this.setState({
            loading: false,
        });
    }

    onRefreshCompanies() {
        LastViewedActions.load();
    }
}

export default LastViewedStore;
