import Reflux from 'reflux';
import settings from '../settings';
import axios from 'axios';

var oldFilters = {};

const SearchActions = Reflux.createActions({
    setFilters: {},
    resetFilters: {},
    clearData: {},
    filterCompanies: {},
    filterAssociates: {},
    refreshCompanies: {},
    loadCompanies: { asyncResult: true },
    loadCompaniesByFiscalCode: { asyncResult: true },
    loadAssociates: { asyncResult: true },
    loadSuggestions: { asyncResult: true },
    clearSuggestions: {},
});

SearchActions.loadSuggestions.listen((query) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    if (query == null || !query.length) {
        SearchActions.loadSuggestions.completed([]);
        return;
    }

    const url = settings.URL_BASE + settings.URL_PATHS_API.READ_SEARCH_SUGGESTIONS + `?query=${query}`
    // app.get(
    //     settings.INTERNAL_URLS.READ_SEARCH_SUGGESTIONS + `?query=${query}`
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        SearchActions.loadSuggestions.completed(response.data);
    }).catch((error) => {
        SearchActions.loadSuggestions.failed(error);
    }); 
});

SearchActions.clearData.listen(() => { oldFilters = {}; });

SearchActions.loadCompanies.listen((filters) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    var { terms, counties, industries, page, order } = filters;
    // var {employeesLeast, employeesMost} = filters;
    if (!terms || !terms.length)
        return false;
    terms = terms.replace('\'', '%')
    terms = terms.replace('-', '%')
    terms = terms.normalize("NFD").replace(/[\u0300-\u036f]/g, "")     //remove diacritics
    terms = btoa(terms)
    const processedTerms = terms ? terms.replace(/ /g, '+') : '';
    if (!page) {
        page = 1;
    }
    let currentPage = page > settings.UTILS.SEARCH_MAX_NO_OF_PAGES ? settings.UTILS.SEARCH_MAX_NO_OF_PAGES : page;
    const offset = (currentPage - 1) * settings.UTILS.LIMIT;
    const limit = settings.UTILS.LIMIT;
    const url = settings.URL_BASE + settings.URL_PATHS_API.SEARCH_COMAPNIES
        + '?termeni=' + (processedTerms ? processedTerms : '')
        + '&judete=' + (counties ? counties : '')
        + '&industrii=' + (industries ? industries : '')
        + '&offset=' + offset
        + '&limit=' + limit
        + '&order=' + (order ? order : '')
        + '&summary=True'

    if (JSON.stringify(filters) !== JSON.stringify(oldFilters)) {
        oldFilters = filters;
    } else {
        return false;
    }

    if (!terms || !terms.length) {
        return false;
    }

    // app.get(
    //     settings.INTERNAL_URLS.SEARCH_COMPANIES
    //     + '?terms=' + (terms ? btoa(terms) : '')
    //     + '&counties=' + (counties ? counties : '')
    //     + '&industries=' + (industries ? industries : '')
    //     + '&employeesLeast=' + (employeesLeast ? employeesLeast : '')
    //     + '&employeesMost=' + (employeesMost ? employeesMost : '')
    //     + '&page=' + (page ? page : '')
    //     + '&order=' + (order ? order : ''),
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        if (Object.keys(filters).length !== 1) {       //remove of undefined named companies appearance at first interogation after login
            SearchActions.loadCompanies.completed(data, filters); 
        }
    }).catch((error) => {
        SearchActions.loadCompanies.failed(error);
    });
});

SearchActions.loadCompaniesByFiscalCode.listen((codesInfo) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    const { codes, codesNb } = codesInfo
    const url = settings.URL_BASE + settings.URL_PATHS_API.SEARCH_COMAPNIES_BY_FISCAL_CODE
        + '?termeni=' + (codes ? btoa(codes) : '')
        + '&reportNb=' + (codesNb ? codesNb : '')
    // app.get(
    //     settings.INTERNAL_URLS.SEARCH_COMAPNIES_BY_FISCAL_CODE
    //     + '?codes=' + (codes ? btoa(codes) : '')
    //     + '&checked=' + (codesNb ? codesNb : ''),
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then(response => {
        const { data } = response;
        SearchActions.loadCompaniesByFiscalCode.completed(data);
    }).catch(error => {
        SearchActions.loadCompaniesByFiscalCode.failed(error.response);
    });
})


SearchActions.loadAssociates.listen((filters) => {
    const headers = Object.assign({ user_key: localStorage.getItem('DATE_FIRME_USER_KEY') }, settings.INTERNAL_BACKEND_CONFIG.headers);
    var { terms, company, counties, page, order } = filters;
    if (Number.isInteger(Number(terms))) {
        SearchActions.loadAssociates.failed(null);
        return;
    }
    if (!terms || !terms.length)
        return false;
    terms = terms.replace(' ', '%')
    terms = terms.replace('-', '%')
    terms = terms && terms.normalize("NFD").replace(/[\u0300-\u036f]/g, "")     //remove diacritics
    terms = btoa(terms)
    const processedTerms = terms ? terms.replace(/ /g, '+') : '';
    if (!page) {
        page = 1;
    }
    let currentPage = page > settings.UTILS.SEARCH_MAX_NO_OF_PAGES ? settings.UTILS.SEARCH_MAX_NO_OF_PAGES : page;
    const offset = (currentPage - 1) * settings.UTILS.LIMIT;
    const limit = settings.UTILS.LIMIT;
    const url = settings.URL_BASE + settings.URL_PATHS_API.SEARCH_ASSOCIATES
        + '?termeni=' + (processedTerms ? processedTerms : '')
        + '&idCompanie=' + (company ? company : '')
        + '&judete=' + (counties ? counties : '')
        + '&offset=' + offset
        + '&limit=' + limit
        + '&order=' + (order ? order : '')
        + '&summary=True'

    // app.get(
    //     settings.INTERNAL_URLS.SEARCH_ASSOCIATES
    //     + '?terms=' + (terms ? btoa(terms) : '')
    //     + '&company=' + (company ? company : '')
    //     + '&counties=' + (counties ? counties : '')
    //     + '&page=' + (page ? page : '')
    //     + '&order=' + (order ? order : ''),
    axios.get(
        url,
        {
            headers: headers,
        }
    ).then((response) => {
        const { data } = response;
        SearchActions.loadAssociates.completed(data, filters);
    }).catch((error) => {
        SearchActions.loadAssociates.failed(error);
    });
});

SearchActions.setFilters.listen((filter) => {
    oldFilters = {};
    SearchActions.loadAssociates({ terms: filter });
});

export default SearchActions;
