import React from 'react';
import Reflux from 'reflux';
import { Link } from 'react-router-dom';

import settings from '../../../../settings';

import UserStore from '../../../../stores/UserStore';

import LogoutButton from './LogoutButton/LogoutButton';
import UserButton from './UserButton/UserButton';
import RegisterButton from './RegisterButton/RegisterButton';
import LoginButton from './LoginButton/LoginButton';
import CartButton from './CartButton/CartButton';

import './NavigationButtons.css';
import LanguageSelector from '../../Title/LanguageSelector/LanguageSelector';
import ChangeUserButton from './ChangeUserButton/ChangeUserButton';
import UserActions from '../../../../actions/UserActions';
import AdminButton from './AdminButton/AdminButton';

class NavigationButtons extends Reflux.Component {
    constructor(props) {
        super(props);
        this.store = UserStore;
    }

    componentDidMount = () => {
        var language = localStorage.getItem("Language")
        if(language === "Ro" || language === null){
			import('../../../Languages/language_ro').then(lang => {
				this.setState({language: lang.default.Language})
			})
		}
		if(language === "En"){
			import('../../../Languages/language_en').then(lang => {
				this.setState({language: lang.default.Language})
			})
		}
        if(this.props.authenticated){
            UserActions.getUserOptions()
        }
    }

    handleExternalLogin = () => {
        const { user } = this.state;
        window.open(`${settings.EXTERNAL_REDIRECT}/Default.aspx?sid=${user.data.sessionId}`);
    }

    render()
    {
        const { user, authenticated, messaging, open } = this.props;
        const{language, userOptions} = this.state
        const showAdmin = user.data && user.data.isAdmin
        const showChangeUser = showAdmin || (userOptions && userOptions.schimbaUser)

        return (
            <div>
            <div className={'navigation-buttons btn-toolbar' + (open ? ' open' : '')}>
                {authenticated
                    ? [<LogoutButton key={0} text={language && language.NavigationButtons.LogOut}/>, showChangeUser && <ChangeUserButton key={4}/>, showAdmin && <AdminButton key={5}/>, <CartButton key={1} text={language && language.NavigationButtons.Cos}/>, <UserButton key={2} user={user}/>]
                    : [<RegisterButton key={0}  text={language && language.NavigationButtons.Register}/>, <LoginButton key={1}  text={language && language.NavigationButtons.LogIn}/>]
                }
                <Link to="/data/contact"><button className="btn btn-sm btn-no-bg pull-right">{language && language.NavigationButtons.Contact}</button></Link>
                <Link to="/data/packages"><button className="btn btn-sm btn-no-bg pull-right">{language && language.NavigationButtons.Pachete}</button></Link>
                {/* <Link to="/data/list/companies/marketing"><button className="btn btn-sm btn-no-bg pull-right">Date Marketing</button></Link> */}
                {/* {authenticated
                    ? <Link to="/data/messages?page=1"><button className="btn btn-sm btn-no-bg pull-right">
                        Mesagerie {authenticated && messaging
                            ? <div className="navigation-notification"><div className="notification-circle">{messaging}</div></div>
                            : null}
                        </button>
                    </Link>
                    : null
                } */}
                {authenticated ? <button className="btn btn-sm btn-no-bg pull-right" onClick={this.handleExternalLogin}>{language && language.NavigationButtons.Monitorizare}</button>: null}
                {authenticated ? <Link to="/data/list/companies/portfolio?page=1"><button className="btn btn-sm btn-no-bg pull-right">
					{language && language.NavigationButtons.Companii} {authenticated && messaging
                        ? <div className="navigation-notification"><div className="notification-circle">{messaging}</div></div>
                        : null}
                </button></Link> : null}
                </div>
                {/* <LanguageSelector /> */}
            </div>
        );
    }
}

export default NavigationButtons;
