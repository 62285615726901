import React from 'react';
import Reflux from 'reflux';
import { Link } from 'react-router-dom';
import settings from '../../../settings';
import ModalsActions from '../../../actions/ModalsActions';
import UserStore from '../../../stores/UserStore';

class MonitorModal extends Reflux.Component {
    constructor(props) {
        super(props);
        this.store = UserStore;
    }

    handleModalClick = (event) => {
        event.stopPropagation();
    }

    handleCloseClick = (event) => {
        ModalsActions.hideModal();
    }

    handleAcceptClick = (event) => {
        ModalsActions.hideModal();
    }

    handleSubmit = (event) => {
        event.preventDefault();
    }

    handleExternalLogin = () => {
        const { user } = this.state;
        window.open(`${settings.EXTERNAL_REDIRECT}/Default.aspx?sid=${user.data.sessionId}`);
        ModalsActions.hideModal();
    }

    render() {
        const { text } = this.props

        return (
            <div className="monitor-modal modal-dialog" onClick={this.handleModalClick}>
                <form className="modal-content modals-modal" onSubmit={this.handleSubmit}>
                    <div className="row">
                        <button type="button" className="modals-close-button close" onClick={this.handleCloseClick}>&times;</button>
                    </div>
                    <div className="row">
                        <div className="col-xs-10 col-xs-offset-1">
                            <h3 className="modals-title">{text && text.Nepermis}</h3>
                        </div>
                        <div className="col-xs-10 col-xs-offset-1 basket-message basket-success">
                            {text && text.Achizitionare}
                        </div>
                        <div className="col-xs-10 col-xs-offset-1">
                            <button type="button" className="btn btn-block modals-btn bg-la-salle-green" onClick={this.handleExternalLogin}>{text && text.Da}</button>
                            <button type="button" className="btn btn-block modals-btn bg-cadet-grey" onClick={this.handleAcceptClick}>{text && text.Nu}</button>
                        </div>
                        <div className="clearfix modals-row-bottom" />
                    </div>
                </form>
            </div>
        );
    }
}

export default MonitorModal;
