import React from 'react';
// import { Link } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';

import DemoDetails from '../../../Body/Data/Content/Demo/DemoDetails/DemoDetails'
import DetailsFirms from '../../../Body/Data/Content/Demo/DemoDetails/DetailsFirms/DetailsFirms';
import DetailsMonitoring from '../../../Body/Data/Content/Demo/DemoDetails/DetailsMonitoring/DetailsMonitoring';
import DetailsReports from '../../../Body/Data/Content/Demo/DemoDetails/DetailsReports/DetailsReports';

import { clearHash } from '../../../../utils/Location';

import './About.css';

// according to https://react-bootstrap.github.io/utilities/transitions/
const DEFAULT_COLLAPSE_TIMEOUT = 300;

/** Clears the hash from location without refreshing the page. */

class About extends React.Component {
    constructor() {
        super();
        const hash = window.location.hash.length > 0 ? window.location.hash.split('#')[1] : '';

        this.state = {
            isAccordionOpen: hash.length > 0,
            accordionTitle: hash
        };
    }

    toggleAccordion = (title) => {
        const { accordionTitle } = this.state;

        this.setState({ isAccordionOpen: false });
        window.setTimeout(function () {
            if (title !== accordionTitle) {
                window.location.hash = title;
                this.setState({
                    isAccordionOpen: true,
                    accordionTitle: title
                });
            } else {
                clearHash();
                this.setState({
                    accordionTitle: ''
                });
            }
        }.bind(this), DEFAULT_COLLAPSE_TIMEOUT);
    }

    render() {
        const { isAccordionOpen, accordionTitle } = this.state;
        const {text} = this.props

        this.accordions = {
            monitoring: <DemoDetails title={text && text.Monitorizate} body={<DetailsMonitoring text={text && text.DetailsMonitoring}/>} />,
            firms: <DemoDetails title={text && text.Rapoarte} body={<DetailsFirms text={text && text.DetailsFirms}/>} />,
            reports: <DemoDetails title={text && text.IntegrareDate} body={<DetailsReports text={text && text.DetailsReports}/>} />
        };

        return (
            <div className="about">
                <div className="row">
                    <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12 col-lg-offset-1 col-md-offset-0">
                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <div className="square"
                                onClick={() => this.toggleAccordion('monitoring')}
                            >
                                <div className="square-content container-fluid">
                                    <div className="square-header easy-monitoring-header row" />
                                    <div className="square-body row">
                                        <h3>{text && text.Monitorizate}</h3>
                                        <p>{text && text.MonitorizateInfo}</p>
                                    </div>
                                    <div className="square-footer row">
                                        <span className="btn-square">{text && text.Detalii}</span>
                                    </div>
                                </div>
                            </div>
                            {/* <Link to="/data/demo/report/monitoring"><button className="btn bg-la-salle-green btn-md center-block">Vezi Raportul Demo</button></Link> */}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                            <div className="square"
                                onClick={() => this.toggleAccordion('firms')}
                            >
                                <div className="square-content">
                                    <div className="square-header data-chart-header row" />
                                    <div className="square-body row">
                                        <h3>{text && text.Rapoarte}</h3>
                                        <p>{text && text.RapoarteInfo}</p>
                                    </div>
                                    <div className="square-footer row">
                                        <span className="btn-square">{text && text.Detalii}</span>
                                    </div>
                                </div>
                            </div>
                            {/* <Link to="/data/demo/report/data"><button className="btn bg-la-salle-green btn-md center-block">Vezi Raportul Demo</button></Link> */}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-sm-offset-3 col-md-offset-0 col-xs-12">
                            <div className="square"
                                onClick={() => this.toggleAccordion('reports')}
                            >
                                <div className="square-content">
                                    <div className="square-header actuality-header row" />
                                    <div className="square-body row">
                                        <h3>{text && text.IntegrareDate}</h3>
                                        <p>{text && text.IntegrareInfo}</p>
                                    </div>
                                    <div className="square-footer row">
                                        <span className="btn-square">{text && text.Detalii}</span>
                                    </div>
                                </div>
                            </div>
                            {/* <Link to="/data/demo/report/new"><button className="btn bg-la-salle-green btn-md center-block">Vezi Raportul Demo</button></Link> */}
                        </div>
                    </div>
                </div>

                <Collapse
                    in={isAccordionOpen}
                    onEntering={el => el.scrollIntoView({behavior: "smooth"})}
                >
                    <div className="row">{this.accordions[accordionTitle]}</div>
                </Collapse>
            </div>
        );
    }
}

export default About;
