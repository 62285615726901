import React from 'react';
import Reflux from 'reflux';
import { Link } from 'react-router-dom';

import CartStore from '../../../../../stores/CartStore';

import './CartButton.css';

/**
 * The button that initiates the login modal.
 */
class CartButton extends Reflux.Component {
    constructor(props) {
        super(props);
        this.store = CartStore;
    }

    render() {
        const items = this.state.contents.length;
        return (
            <Link to="/data/cart"><button className="btn btn-sm btn-no-bg pull-right">
                <span className="user-cart pull-left" /> <span className="hidden-md">{this.props.text}</span>
                {items 
                    ? <div className="navigation-notification"><div className="count-circle">{items}</div></div> 
                    : null}
            </button></Link>
        );
    }
}

export default CartButton;