import React from 'react';
import Reflux from 'reflux';
import { Link } from 'react-router-dom';

import settings from '../../../../../settings';

import './MonitoringControl.css';
import UserStore from '../../../../../stores/UserStore';

class MonitoringControl extends Reflux.Component {
    constructor(props) {
        super(props);
        this.store = UserStore;
    }
	
	handleExternalLogin = (event) => {
	    /*UserActions.externalLogin(function () {
	        window.location.href = settings.INTERNAL_URLS.EXTERNAL_REDIRECT;
		});*/

	    const { user } = this.state;
			const url = `${settings.EXTERNAL_REDIRECT}/Default.aspx?sid=${user.data.sessionId}`
	    window.open(`${settings.EXTERNAL_REDIRECT}/Default.aspx?sid=${user.data.sessionId}`);
	}

	render()
	{
	    const { monitoring, notification } = this.props;
	    return (
	        <div className="monitoring-control control col-xs-12 col-sm-4 col-lg-2 col-lg-offset-2">
	            <Link to="/" onClick={this.handleExternalLogin}><div className="control-inner">
	                <div className="control-content">
	                    <div className="control-graphic monitoring-graphic">
	                        {notification
	                            ? <div className="controls-notification"><div className="notification-circle">{notification}</div></div>
	                            : null}
	                    </div>
	                    <div className="control-text">
	                        <h4>{this.props.text}</h4>
	                        <p>{monitoring ? monitoring.companii + (monitoring.companii === 1 ? ` ${this.props.firmaSg} ` : ` ${this.props.firmaPl} `) + monitoring.dosar + (monitoring.dosar === 1 ? ` ${this.props.dosareSg}` : ` ${this.props.dosarePl}`) : ''}</p>
	                    </div>
	                </div>
	            </div></Link>
	        </div>
	    );
	}
}

export default MonitoringControl;