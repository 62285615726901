import React from 'react';
import { Link } from 'react-router-dom';

const DosareInstantaControl = (props) => {
    const {monitoring, text} = props

    return (
        <div className="messaging-control control col-xs-12 col-sm-4 col-lg-2">
            <div className="control-inner">
                <Link to="data/dosareInstanta">
                    <div className="control-content">
                        <div className="control-graphic dossier-control-graphic"></div>
                        <div className="control-text">
                            <h4>{text && text.DosareInstanta}</h4>
                            <p>{monitoring && (monitoring.dosar === 1 ? `1 ${text && text.DosareMSg}` : `${monitoring.dosar} ${text && text.DosareMPl}`)}</p>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default DosareInstantaControl