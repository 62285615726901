import React, {useState} from 'react'
import './FloatingLableInput.css'

const FloatingLableInput = ({label, value, onChange, name, placeholder, disabled, type='text', styles=''}) => {
    const [isFocused, setIsFocused] = useState(false)

    const handleFocus = () => {
        setIsFocused(true);
      };
    
      const handleBlur = () => {
        setIsFocused(false);
      };  

    return (
        <div className={`floating-label-input ${styles} ${isFocused || value !== '' ? "focused" : ""}`}>
            <input
                type={type}
                placeholder={isFocused ? placeholder : ""}
                value={value !== null ? value : ''}
                name={name}
                onChange={onChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={disabled}
            />
            <label>{label}</label>
        </div>
    )
}

export default FloatingLableInput