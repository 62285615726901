import React from 'react';
import { Link } from 'react-router-dom';

import './UserButton.css';

/**
 * The button that initiates the login modal.
 */
const UserButton = (props) => {
    const { loading, data } = props.user;
    return (
        <Link to="/data/details/account"><button className="btn btn-sm btn-no-bg pull-right">
            <span className="user-profile pull-left" />{loading ? <span className="hidden-md">...</span> : <span className="hidden-md">{data.nume}</span>}
        </button></Link>
    );
};

export default UserButton;